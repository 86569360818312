
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid, Box, Button, FormLabel } from '@mui/material'
import { jaJP } from '@mui/x-data-grid'
import Header from '../../components/Header'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from './../../contexts/AuthContext'
import { useFetch, usePut } from './../../api/ApiRequests'
import TitleBar from '../../components/uiParts/TitleBar'
import { CustomDataGrid } from '../../components/dataGrid/CustomDataGrid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { darken } from '@mui/material/styles';

const BuyerList = () => {

    const { authInfo, setAuthInfo } = useAuth()
    const [searchCount, setSearchCount] = useState(0)
    const [targetList, setTargetList] = useState('')
    const [buyerList, setBuyerList] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [dataGridHeight, setDataGridHeight] = useState(window.innerHeight - 180)

    const effectRan = useRef(false)

    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId,
        userName: userName
    }
    //-----------------------------------------------
    // API - PUTメソッドのテンプレート
    //-----------------------------------------------
    const { putData } = usePut({
        userId: userId,
        userName: userName
    })
    //-----------------------------------------------
    // API - セラーマスタ
    //-----------------------------------------------
    const { data: sellerData, loading: seller_Loading, fetchData: fetchSellerData } = useFetch(getData)

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - ロード時の設定
    //-----------------------------------------------
    useEffect(() => {
        if (effectRan.current === false) {
            effectRan.current = true

            setSearchCount(searchCount + 1)

            const handleResize = () => {
                setDataGridHeight(window.innerHeight - 180)
            }
            window.addEventListener('resize', handleResize)
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [])
    //-----------------------------------------------
    // useEffect - 検索イベント
    //-----------------------------------------------
    useEffect(() => {
        fetchSellerData(`/mst/buyer/?deleteFlag=0`)
    }, [searchCount])
    //-----------------------------------------------
    // useEffect - バイヤーリスト
    //-----------------------------------------------
    useEffect(() => {
        if (sellerData) {

            setBuyerList([]) // 既存データを削除

            // 重複データをチェック
            const uniqueData = sellerData.reduce((acc, curr) => {
                if (!acc.some(item => item.id === curr.id)) {
                    acc.push(curr)
                }
                return acc
            }, [])
            // 検索条件に合致したデータを表示
            //const filteredData = uniqueData.filter(item => 
            //    item.code.includes(code) &&
            //    item.name.includes(name)
            //)
            setBuyerList(prevList => [...prevList, ...uniqueData])
        }
    }, [sellerData])

    //-----------------------------------------------
    // useEffect - dataGirdの選択
    //-----------------------------------------------
    useEffect(() => {
        if (targetList) {
            setOpenDialog(true) // ダイアログを表示
        }
    }, [targetList])

    //===============================================
    // eventHandler
    //===============================================
    //-----------------------------------------------
    // eventHandler - 承認・申請却下ボタン
    //-----------------------------------------------
    const handleClick_Register = (statusCode) => {

        const id = targetList.id
        const registerData = {
            ...targetList, statusCode
        }
        // 既存データの更新
        if (id) {
            const submitDataToPut = async () => {
                try {
                    await putData(`/mst/buyer/${id}/`, registerData)
                    setSearchCount(searchCount + 1)
                    setOpenDialog(false)
                    // 後処理
                } catch (error) {
                    console.error("Error occurred:", error)
                }
            }
            submitDataToPut()
        }
    }
    //-----------------------------------------------
    // eventHandler - ダイアログを非表示
    //-----------------------------------------------
    const handleClick_dialog_close = () => {
        setOpenDialog(false)
    }
    //===============================================
    // dataGird
    //===============================================
    //-----------------------------------------------
    // dataGrid - テーブルの見出し
    //-----------------------------------------------
    const columns = [
        { field: 'entryDate', headerName: '登録日', width: 120, columnSeparatorWidth: 1, },
        { field: 'statusCode', headerName: 'ステータス', width: 90, columnSeparatorWidth: 1, valueGetter: (params) => statusOptions[params.value] },
        { field: 'eMail', headerName: 'メールアドレス', width: 250, columnSeparatorWidth: 1, },
        { field: 'name', headerName: '会社名', width: 200, columnSeparatorWidth: 1, },
        { field: 'busho', headerName: '部署', width: 150, columnSeparatorWidth: 1, },
        { field: 'tantosha', headerName: '担当者', width: 200, columnSeparatorWidth: 1, },
        { field: 'address', headerName: '住所', width: 250, columnSeparatorWidth: 1, },
        { field: 'tel', headerName: '電話番号', width: 150, columnSeparatorWidth: 1, },
        { field: 'mobile', headerName: '携帯番号', width: 150, columnSeparatorWidth: 1, },
    ]
    const statusOptions = {
        0: '未承認',
        1: '承認済み',
        9: '申請却下',
    }
    //-----------------------------------------------
    // dataGrid - 行の選択
    //-----------------------------------------------
    const handleClick_dataGrid = (event) => {
        const updatedRow = {
            ...event.row,
        }
        setTargetList(updatedRow)
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '1em',
    }
    const dialog_button_close = {
        fontSize: '16px', height: '40px', width: '320px', color: '#fff', backgroundColor: '#808080'
    }
    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo="on" backButton="on" backPage="/home" />
            <div style={homeContainer}>
                <Frame classname="frame" title="バイヤーリスト">
                    <CustomDataGrid
                        getRowClassName={(params) => {
                            if (params.row.statusCode === 9) {
                                return "rejected";
                            } else if (params.row.statusCode === 1) {
                                return "approved";
                            } else {
                                return "unapproved";
                            }
                        }}
                        editMode="row"
                        rows={buyerList}
                        columns={columns}
                        sx={{
                            height: dataGridHeight, fontSize: 14, borderColor: 'primary.light',
                            '& .rejected': {
                                backgroundColor: ' #BFBFBF !important',
                                '&:hover': {
                                    backgroundColor: `${darken('#BFBFBF', 0.05)} !important`,
                                },
                            },
                            '& .approved': {
                                backgroundColor: '#fff !important',
                                '&:hover': {
                                    backgroundColor: `${darken('#fff', 0.05)} !important`,
                                },
                            },
                            '& .unapproved': {
                                backgroundColor: '#FFB6C1 !important',
                                '&:hover': {
                                    backgroundColor: `${darken('#FFB6C1', 0.05)} !important`,
                                },
                            },
                        }}
                        density="compact"
                        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                        showColumnRightBorder
                        showCellRightBorder
                        initialState={{ pagination: { paginationModel: { pageSize: 100 } }, }}
                        pageSizeOptions={[50, 100]}
                        loading={seller_Loading}
                        onCellClick={(event) => handleClick_dataGrid(event)}
                    />
                </Frame>
            </div>
            <Dialog open={openDialog} onClose={handleClick_dialog_close} maxWidth='lg' >
                <DialogTitle>
                    <TitleBar title="バイヤー明細" />
                </DialogTitle>
                <DialogContent style={{ border: 'none' }} sx={{ width: '750px' }}>
                    <div style={{ backgroundColor: '#efefef', padding: '10px' }}>
                        <Box sx={{ flexGrow: 1, height: '300px', overflowY: 'scroll' }}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メールアドレス</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.eMail}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>会社名</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.name}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>部署</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.busho}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>担当者</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.tantosha}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>住所</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.address}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>電話番号</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.tel}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>携帯番号</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.mobile}</FormLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <div style={{ display: 'flex', marginLeft: '10px', marginTop: '15px' }}>
                            <div style={{ marginLeft: '5px' }}>
                                <Button
                                    color="attention"
                                    size="large"
                                    variant="contained"
                                    sx={{ width: '140px', color: '#fff' }}
                                    style={{ color: '#000', backgroundColor: '#fff', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                    onClick={() => handleClick_Register(9)}
                                >{'申請却下'}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    sx={{ width: '140px', marginLeft: '10px' }}
                                    style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                    onClick={() => handleClick_Register(1)}
                                >{'承認する'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '200px' }}>
                        <Button
                            color="tertiary"
                            size="large"
                            variant="contained"
                            sx={dialog_button_close}
                            onClick={() => handleClick_dialog_close()}
                        >{'閉じる'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default BuyerList