import React from 'react'
import { TextField, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form';

const CustomFormOutlinedInput = ({ id, name, placeHolder, inputRef, label, width, value, onChange, type, style, onClick }) => {
    const { control, formState: { errors } } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    style={style}
                    id={id}
                    type={type}
                    variant='outlined'
                    size='small'
                    label={label}
                    placeholder={placeHolder}
                    helperText={errors?.[name]?.message}
                    FormHelperTextProps={{ sx: { width: 200 } }}
                    error={errors?.[name]?.message ? true : false}
                />
            )}
        />
    );
}

export default CustomFormOutlinedInput