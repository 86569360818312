import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, useMediaQuery, useTheme, Button, FormLabel } from '@mui/material'
import { useFetch } from '../../api/ApiRequests';
import { useParams } from 'react-router-dom';
// import { apiURL } from '../../api/ApiConfig';
import { cityList } from '../../Store'
import { usePost } from '../../api/ApiRequests';
import { useAuth } from '../../contexts/AuthContext'

const BuyerSchedulesPage = ({ formData, setformData }) => {
    const { authInfo } = useAuth()
    const { id: sellerId } = useParams();

    const navigate = useNavigate();
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    const effectRan = useRef(false)

    const title = `商談状況`

    const item = cityList.find(item => item.code == formData?.city);
    const cityName = item?.label || ""

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName
    })

    const { data: buyerDetail, loading: buyer_Loading, fetchData: fetchBuyerData } = useFetch({
        userId: userId,
        userName: userName
    })

    const { data: sellerProfile, loading: seller_Loading, fetchData: fetchSellerData } = useFetch({
        userId: userId,
        userName: userName
    })

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        fetchSellerData(`/mst/buyer/timerex/?seller_id=${sellerId}`)
        fetchBuyerData(`/mst/buyer/${authInfo.buyerId}/`)
    }, [])

    const initializedRef = useRef(false);

    useEffect(() => {
        if (!initializedRef.current && buyerDetail) {
            const scriptEl = document.createElement("script");
            scriptEl.id = "timerex-script";
            scriptEl.src = "https://timerex.net/js/embed.js";
            scriptEl.type = 'text/javascript';
            document.body.appendChild(scriptEl);
            scriptEl.onload = () => {
                if (window.TimerexCalendar && !initializedRef.current) {
                    window.TimerexCalendar({
                        guest_company: buyerDetail?.name || "",
                        guest_name: buyerDetail?.tantosha || "",
                        guest_email: buyerDetail?.eMail || "",
                        url_params: {
                            item_id: query.get("item_id")
                        }
                    });
                    initializedRef.current = true; // 初期化済みとする
                }
            };
            return () => {
                document.body.removeChild(scriptEl);
            };
        }
    }, [buyerDetail]);

    //===============================================
    // eventHandler
    //===============================================

    //===============================================
    // CSS
    //===============================================
    const pageContainer = {
        display: "flex", flexDirection: "column", height: "100%",
    }

    const bodyContainer = {
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 10px",
        paddingBottom: "20px",
        backgroundColor: 'white',

    }

    const contentContainer = {
        flexDirection: "column",
        maxWidth: "800px",
        width: "100%",
        overflow: "auto",
    }

    return (
        <div style={pageContainer}>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} backPage="/buyer/items" />
            <div style={{}}>
                <TitleBar title={title} backgroundColor='#fff' />
            </div>
            <div style={bodyContainer}>
                <div style={contentContainer}>
                    <div style={{ width: "100%", backgroundColor: "#D5EDC7", padding: "15px", margin: "30px 0", borderRadius: "10px", textAlign: "start" }}>
                        下記のカレンダーよりご都合のお日にちを選択してください。 <br />
                        御社の情報の入力進んでください。<br />
                        入が完了したら 「上記の内容で日程調整を完了する」 をクリックしてください。<br />
                        ご入力いただいたメールアドレスに日程調整完了のメールが送信されます。
                    </div>
                    {
                        sellerProfile?.timerex_url ?
                            <div
                                style={{ padding: "5px 0" }}
                                id="timerex_calendar"
                                data-url={sellerProfile.timerex_url}
                            ></div> : <></>
                    }

                </div>
                <Button
                    onClick={() => navigate("/buyer/items")}
                    variant="outlined" sx={{ mr: 1 }} size="large"
                    style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '1rem', }}
                >
                    戻&emsp;る
                </Button>
            </div>
            <Footer />
        </div >
    );
};

export default BuyerSchedulesPage;