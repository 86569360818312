import "./App.css";
import AppRouter from "./AppRouter";
import { CssBaseline } from "@mui/material/";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { AuthProvider } from "./contexts/AuthContext";
import { MicorsoftProvider } from "./contexts/MicrosoftContext";
import { BrowserRouter as Router} from 'react-router-dom'

function App() {
  return (
    <>
      <AuthProvider>
        <MicorsoftProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="App">
              <Router>
                <AppRouter />
              </Router>
            </div>
          </ThemeProvider>
        </MicorsoftProvider>
      </AuthProvider>
    </>
  );
}

export default App;
