import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import { useFetch } from "../../api/ApiRequests";
import MenuIcon from "../../components/uiParts/MenuIcon";
import TitleBar from "../../components/uiParts/TitleBar";
import PersonIcon from "@mui/icons-material/Person";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { InstructionModal } from "../../components/buyer/modal/InstructionModal/InstructionModal";

const BuyerHomePage = () => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isModalVisible, setModalVisible] = useState(false);

  const location = useLocation();

  const effectRan = useRef(false);

  const modalData = [{
    title: "バイヤー用(PC版)",
    path: ``
  },
  {
    title: "バイヤー用(スマホ版)",
    path: ``
  }]

  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const title = `バイヤーユーザ：${userName}`;

  // console.log(authInfo)

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userId: userId,
    userName: userName,
  };
  //-----------------------------------------------
  // API - 発注・納品テーブル
  //-----------------------------------------------
  const {
    data: userData,
    loading: user_Loading,
    fetchData: fetchUserData,
  } = useFetch(getData);

  //===============================================
  // useEffect
  //===============================================
  //-----------------------------------------------
  // useEffect - ロード時の設定
  //-----------------------------------------------
  useEffect(() => {
    if (effectRan.current === false) {
      effectRan.current = true;
      fetchUserData(`/mst/user/?username=${userId}&deleteFlag=0`);
    }
  }, []);
  //-----------------------------------------------
  // useEffect - 部署マスタ
  //-----------------------------------------------
  useEffect(() => {
    if (userData) {
      console.log(userData);
    }
  }, [userData]);

  //===============================================
  // eventHandler
  //===============================================
  const handleClickProfile = () => {
    navigate("/buyer/profile");
  };
  const handleClickSellerList = () => {
    navigate("/buyer/sellers");
  };
  const handleClickCategorySearch = () => {
    navigate("/buyer/items");
  };
  const handleClickMeetings = () => {
    navigate("/buyer/meetings");
  };
  const handleClickInstructions = () => {
    setModalVisible(true);
  };
  //===============================================
  // CSS
  //===============================================
  const homeContainer = {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingBottom: "150px", // フッターの高さ分だけ余白を追加
  };
  //===============================================
  // JSXのレンダー
  //===============================================
  return (
    <>
      <Header title={systemName} loginInfo="on" backButton="off" />
      <div style={homeContainer}>
        <div>
          <TitleBar title={title} backgroundColor="white" />
        </div>
        {isModalVisible && (
          <InstructionModal data={modalData} onClickClose={() => setModalVisible(false)} />
        )}
        <div style={{ width: "100%", color: "white", textAlign: "center" }}>
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <Grid
              container
              direction={isSmallScreen ? "column" : "row"}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <MenuIcon
                  header="My Page"
                  title="マイページ"
                  onClick={() => handleClickProfile()}
                >
                  <AccountCircleIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
                <MenuIcon
                  header="Entry Menu"
                  title="セラーリスト"
                  onClick={() => handleClickSellerList()}
                >
                  <PersonIcon style={{ fontSize: "4rem", color: "#007D50" }} />
                </MenuIcon>
                <MenuIcon
                  header="Category Search"
                  title="カテゴリー検索"
                  onClick={() => handleClickCategorySearch()}
                >
                  <ManageSearchIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
                <MenuIcon
                  header="Management Menu"
                  title="商談状況"
                  onClick={() => handleClickMeetings()}
                >
                  <LocalMallIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
                <MenuIcon
                  header="Management Menu"
                  title="操作説明書"
                  onClick={() => handleClickInstructions()}
                >
                  <LocalMallIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};
export default BuyerHomePage;
