
import { useState, useEffect, useRef } from 'react'
import { Grid, Box, Button, FormLabel, Stack } from '@mui/material'
import { jaJP, GridActionsCellItem } from '@mui/x-data-grid'
import Header from '../../components/Header'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from './../../contexts/AuthContext'
import { apiURL } from '../../api/ApiConfig'
import { useFetch, usePut, useDelete } from './../../api/ApiRequests'
import TitleBar from '../../components/uiParts/TitleBar'
import { CustomDataGrid } from '../../components/dataGrid/CustomDataGrid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { itemCategoryList } from '../../Store'
import { darken } from '@mui/material/styles';

const SellerList = () => {

    const { authInfo, setAuthInfo } = useAuth()
    const [searchCount, setSearchCount] = useState(0)
    const [targetList, setTargetList] = useState('')
    const [sellerList, setSellerList] = useState([])
    const [itemList, setItemList] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [dataGridHeight, setDataGridHeight] = useState(window.innerHeight - 180)

    const effectRan = useRef(false)

    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId,
        userName: userName
    }
    //-----------------------------------------------
    // API - PUTメソッドのテンプレート
    //-----------------------------------------------
    const { putData } = usePut({
        userId: userId,
        userName: userName
    })
    //-----------------------------------------------
    // API - DELETEメソッドのテンプレート
    //-----------------------------------------------
    const { response, deleteLoading, error: deleteError, deleteData } = useDelete(getData);
    //-----------------------------------------------
    // API - セラーマスタ
    //-----------------------------------------------
    const { data: sellerData, loading: seller_Loading, fetchData: fetchSellerData } = useFetch(getData)

    //-----------------------------------------------
    // API - 商品マスタ
    //-----------------------------------------------
    const { data: itemData, loading: item_Loading, fetchData: fetchItemData } = useFetch(getData)

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - ロード時の設定
    //-----------------------------------------------
    useEffect(() => {
        if (effectRan.current === false) {
            effectRan.current = true

            setSearchCount(searchCount + 1)

            const handleResize = () => {
                setDataGridHeight(window.innerHeight - 180)
            }
            window.addEventListener('resize', handleResize)
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [])
    //-----------------------------------------------
    // useEffect - 検索イベント
    //-----------------------------------------------
    useEffect(() => {
        fetchSellerData(`/mst/seller/?deleteFlag=0`)
    }, [searchCount])
    //-----------------------------------------------
    // useEffect - セラーリスト
    //-----------------------------------------------
    useEffect(() => {
        if (sellerData) {
            setSellerList([]) // 既存データを削除

            // 重複データをチェック
            const uniqueData = sellerData.reduce((acc, curr) => {
                if (!acc.some(item => item.id === curr.id)) {
                    acc.push(curr)
                }
                return acc
            }, [])
            // 検索条件に合致したデータを表示
            //const filteredData = uniqueData.filter(item =>
            //    item.code.includes(code) &&
            //    item.name.includes(name)
            //)
            setSellerList(prevList => [...prevList, ...uniqueData])
        }
    }, [sellerData])
    //-----------------------------------------------
    // useEffect - 商品リスト
    //-----------------------------------------------
    useEffect(() => {
        if (itemData) {

            // 重複データをチェック
            const uniqueData = itemData.reduce((acc, curr) => {
                if (!acc.some(item => item.id === curr.id)) {
                    acc.push(curr)
                }
                return acc
            }, [])

            // itemCategoryNameを追加
            const dataWithCategoryNames = uniqueData.map(item => {
                const category = itemCategoryList.find(cat => cat.code === item.itemCategory)
                return {
                    ...item,
                    itemCategoryName: category ? category.label : 'Unknown' // categoryが見つからない場合は'Unknown'
                }
            })
            setItemList(dataWithCategoryNames)

            setOpenDialog(true) // ダイアログを表示
        }
    }, [itemData])
    //-----------------------------------------------
    // useEffect - dataGirdの選択
    //-----------------------------------------------
    useEffect(() => {
        if (targetList) {
            fetchItemData(`/mst/pendingItem/?deleteFlag=0&seller=${targetList.id}`)
        }
    }, [targetList])
    //===============================================
    // eventHandler
    //===============================================
    //-----------------------------------------------
    // eventHandler - 承認・申請却下ボタン
    //-----------------------------------------------
    const handleClick_Register = (statusCode) => {
        const id = targetList.id
        const registerData = {
            ...targetList,
            "statusCode": statusCode
        }
        // 既存データの更新
        if (id) {
            const submitDataToPut = async () => {
                try {
                    const res = await putData(`/mst/seller/${id}/`, registerData);
                    if (res) {
                        setSearchCount((serchCount) => serchCount + 1)
                        setOpenDialog(false)
                    }
                    // 後処理
                } catch (error) {
                    console.error("Error occurred:", error)
                }
            }
            submitDataToPut()
        }
    }
    //-----------------------------------------------
    // eventHandler - ダイアログを非表示
    //-----------------------------------------------
    const handleClick_dialog_close = () => {
        setOpenDialog(false)
    }
    //-----------------------------------------------
    // eventHandler - セラー削除ボタン
    //-----------------------------------------------
    const handleClickDeleteSeller = async (e, id) => {
        e.stopPropagation()
        if (window.confirm("セラーを削除します。よろしいですか？")) {
            try {
                await deleteData(`/mst/seller/${id}/`)
                await fetchSellerData(`/mst/seller/?deleteFlag=0`)
                // 後処理
            } catch (error) {
                console.error("Error occurred:", error)
            }
        }
    }
    //===============================================
    // dataGird
    //===============================================
    //-----------------------------------------------
    // dataGird - テーブルの見出し
    //-----------------------------------------------
    const columns = [
        { field: 'entryDate', headerName: '登録日', width: 120, columnSeparatorWidth: 1, },
        { field: 'exhibit', headerName: '開催回', width: 90, columnSeparatorWidth: 1, },
        { field: 'statusCode', headerName: 'ステータス', width: 90, columnSeparatorWidth: 1, valueGetter: (params) => statusOptions[params.value] },
        { field: 'eMail', headerName: 'メールアドレス', width: 250, columnSeparatorWidth: 1, },
        { field: 'name', headerName: '名称', width: 200, columnSeparatorWidth: 1, },
        { field: 'nameKana', headerName: 'カナ名称', width: 200, columnSeparatorWidth: 1, },
        { field: 'tel', headerName: '電話番号', width: 150, columnSeparatorWidth: 1, },
        { field: 'busho', headerName: '部署', width: 150, columnSeparatorWidth: 1, },
        { field: 'city', headerName: '都道府県', width: 100, columnSeparatorWidth: 1, valueGetter: (params) => cityOptions[params.value] },
        { field: 'address', headerName: '市区町村', width: 250, columnSeparatorWidth: 1, },
        {
            field: 'delete',
            headerName: '',
            type: "actions",
            width: 100,
            columnSeparatorWidth: 1,
            sortable: false,
            renderCell: (params) => <Button onClick={(e) => handleClickDeleteSeller(e, params.row.id)}
                variant="contained"
                style={{ backgroundColor: "gray", width: "100%", height: "27px" }} size='small'>削除</Button>
        },
    ]
    const statusOptions = {
        0: '未承認',
        1: '承認済み',
        9: '申請却下',
    }
    const cityOptions = {
        1: '新潟県',
        2: '富山県',
        3: '石川県',
        4: '福井県',
    }

    //-----------------------------------------------
    // dataGrid - 行の選択
    //-----------------------------------------------
    const handleClick_dataGrid = (event) => {
        const cityCode = event.row.city;
        const cityName = cityOptions[cityCode] || 'Unknown'; // cityCodeが存在しない場合のデフォルト値は'Unknown'

        const updatedRow = {
            ...event.row,
            cityName: cityName
        };
        setTargetList(updatedRow)
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '1em',
    }
    const responsiveImage = {
        width: '380px',
    }
    const dialog_button_close = {
        fontSize: '16px', height: '40px', width: '320px', color: '#fff', backgroundColor: '#808080'
    }
    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo="on" backButton="on" backPage="/home" />
            <div style={homeContainer}>
                <Frame classname="frame" title="セラーリスト">
                    <CustomDataGrid
                        getRowClassName={(params) => {
                            if (params.row.statusCode === 9) {
                                return "rejected";
                            } else if (params.row.statusCode === 1) {
                                return "approved";
                            } else {
                                return "unapproved";
                            }
                        }}
                        editMode="row"
                        rows={sellerList}
                        columns={columns}
                        sx={{
                            height: dataGridHeight, fontSize: 14, borderColor: 'primary.light',
                            '& .rejected': {
                                backgroundColor: ' #BFBFBF !important',
                                '&:hover': {
                                    backgroundColor: `${darken('#BFBFBF', 0.05)} !important`,
                                },
                            },
                            '& .approved': {
                                backgroundColor: '#fff !important',
                                '&:hover': {
                                    backgroundColor: `${darken('#fff', 0.05)} !important`,
                                },
                            },
                            '& .unapproved': {
                                backgroundColor: '#FFB6C1 !important',
                                '&:hover': {
                                    backgroundColor: `${darken('#FFB6C1', 0.05)} !important`,
                                },
                            },
                        }}
                        density="compact"
                        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                        showColumnRightBorder
                        showCellRightBorder
                        initialState={{ pagination: { paginationModel: { pageSize: 100 } }, }}
                        pageSizeOptions={[50, 100]}
                        loading={seller_Loading}
                        onCellClick={(event) => handleClick_dataGrid(event)}
                    />
                </Frame>
            </div>
            <Dialog open={openDialog} onClose={handleClick_dialog_close} maxWidth='lg' >
                <DialogTitle>
                    <TitleBar title="セラー明細" />
                </DialogTitle>
                <DialogContent style={{ border: 'none' }} sx={{ width: '750px' }}>
                    <div style={{ backgroundColor: '#efefef', padding: '10px' }}>
                        <Box sx={{ flexGrow: 1, height: '400px', overflowY: 'scroll' }}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} spacing={2} mt={0}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>開催回</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.exhibit} 回</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メールアドレス</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.eMail}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.name}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名（フリガナ）</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.nameKana}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>電話番号</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.tel}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>部署</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.busho}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>担当者</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.tantosha}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>都道府県</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.cityName}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>所在地</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.address}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者区分</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField01 ? <span style={{ marginLeft: '1em' }}>農業</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField02 ? <span style={{ marginLeft: '1em' }}>水産業</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField03 ? <span style={{ marginLeft: '1em' }}>小売業</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField04 ? <span style={{ marginLeft: '1em' }}>食品加工</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField05 ? <span style={{ marginLeft: '1em' }}>製造業</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField06 ? <span style={{ marginLeft: '1em' }}>卸売業</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField07 ? <span style={{ marginLeft: '1em' }}>飲食業</span> : ''}
                                        </FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>
                                            {targetList.checkField08 ? <span style={{ marginLeft: '1em' }}>その他</span> : ''}
                                        </FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他（自由記入）</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.textField01}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"column"}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>取得認証</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField09 ? <span style={{ marginLeft: '1em' }}>有機JAS</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField10 ? <span style={{ marginLeft: '1em' }}>JGAP</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField11 ? <span style={{ marginLeft: '1em' }}>ASIAGAP</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField12 ? <span style={{ marginLeft: '1em' }}>lobal GAP</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField13 ? <span style={{ marginLeft: '1em' }}>ISO 9001</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField14 ? <span style={{ marginLeft: '1em' }}>ISO 14001</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField15 ? <span style={{ marginLeft: '1em' }}>ISO 22000</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField16 ? <span style={{ marginLeft: '1em' }}>FSSC 22000</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField17 ? <span style={{ marginLeft: '1em' }}>US・HACCP</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField18 ? <span style={{ marginLeft: '1em' }}>EU・HACCP</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField19 ? <span style={{ marginLeft: '1em' }}>取得認証なし</span> : ''}</FormLabel>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{targetList.checkField20 ? <span style={{ marginLeft: '1em' }}>その他</span> : ''}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} >
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他（取得記述）</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.textField02}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>認定・受賞歴など</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.textField03}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>ホームページ</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.textField05}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>X(Twitter)</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.textField06}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>Instagram</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{targetList.textField07}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} spacing={2}>
                                    <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                        <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メイン画像</FormLabel>
                                    </Grid>
                                    <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                        <img src={`${apiURL}media/uploads/${targetList.imageField01}`} alt="Example" style={responsiveImage} />
                                    </Grid>
                                </Grid>
                                {
                                    itemList?.map((item, index) => (
                                        <Stack key={index}>
                                            <Grid container direction={"row"} spacing={2} mt={3}>
                                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}-商品名</FormLabel>
                                                </Grid>
                                                <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{item.name}</FormLabel>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"row"} spacing={2}>
                                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}-商品名（フリガナ）</FormLabel>
                                                </Grid>
                                                <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{item.nameKana}</FormLabel>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"row"} spacing={2}>
                                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}-商品カテゴリ</FormLabel>
                                                </Grid>
                                                <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{item.itemCategoryName}</FormLabel>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"row"} spacing={2}>
                                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}-キャッチフレーズ</FormLabel>
                                                </Grid>
                                                <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{item.textField01}</FormLabel>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"row"} spacing={2}>
                                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}-商品説明</FormLabel>
                                                </Grid>
                                                <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{item.textField02}</FormLabel>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction={"row"} spacing={2}>
                                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}-商品画像</FormLabel>
                                                </Grid>
                                                <Grid item xs={'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                                    <img src={`${apiURL}media/uploads/${item.imageField01}`} alt="Example" style={responsiveImage} />
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    ))
                                }
                            </Grid>
                        </Box>
                        <div style={{ display: 'flex', marginLeft: '10px', marginTop: '15px' }}>
                            <div style={{ marginLeft: '5px' }}>
                                <Button
                                    color="attention"
                                    size="large"
                                    variant="contained"
                                    sx={{ width: '140px', color: '#fff' }}
                                    style={{ color: '#000', backgroundColor: '#fff', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                    onClick={() => handleClick_Register(9)}
                                >{'申請却下'}
                                </Button>

                            </div>
                            <div>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    sx={{ width: '140px', marginLeft: '10px' }}
                                    style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                    onClick={() => handleClick_Register(1)}
                                >{'承認する'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px', marginLeft: '200px' }}>
                        <Button
                            color="tertiary"
                            size="large"
                            variant="contained"
                            sx={dialog_button_close}
                            onClick={() => handleClick_dialog_close()}
                        >{'閉じる'}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default SellerList
