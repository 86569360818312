
import { useState, useEffect, useRef } from 'react'
import { jaJP } from '@mui/x-data-grid'
import Header from '../../components/Header'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from '../../contexts/AuthContext'
import { useFetch } from '../../api/ApiRequests'
import { CustomDataGrid } from '../../components/dataGrid/CustomDataGrid'

const UserList = () => {

    const { authInfo } = useAuth()
    const [ userList, setUserList] = useState([])
    const [ dataGridHeight, setDataGridHeight ] = useState(window.innerHeight - 180)
    
    const effectRan = useRef(false)
  
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME
    
    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    
    //===============================================
    // API
    //===============================================
	//-----------------------------------------------
    // API - GETメソッドのテンプレート
	//-----------------------------------------------
    const getData = {
        userId   : userId,
        userName : userName
    }
	//-----------------------------------------------
    // API - セラーマスタ
	//-----------------------------------------------
    const { data: userData, loading: userLoading, fetchData: fetchUserData } = useFetch(getData)
    
    //===============================================
    // useEffect
    //===============================================
	//-----------------------------------------------
    // useEffect - ロード時の設定
	//-----------------------------------------------
    useEffect(() => {
        if (effectRan.current === false) {
            effectRan.current = true
            
            const handleResize = () => {
              setDataGridHeight(window.innerHeight - 180)
            }
            window.addEventListener('resize', handleResize)
            return () => {
              window.removeEventListener('resize', handleResize)
            }
        }
    },[])
		//-----------------------------------------------
		// useEffect - ユーザー情報の取得
		//-----------------------------------------------
    useEffect(() => {
       	fetchUserData(`/mst/user?deleteFlag=0&authority=1`)
    },[])

		useEffect(() => {
			if (userData) {
				setUserList(userData);
			}
		},[userData])
    
    //===============================================
    // dataGird
    //===============================================
    //-----------------------------------------------
    // dataGrid - テーブルの見出し
    //-----------------------------------------------
		const roleOptions = {
			"1": '管理者',
			"2": '出展者',
			"3": 'バイヤー',
		}
    const columns = [
			{ field: 'entryDate', headerName: '最終ログイン', width: 180, columnSeparatorWidth: 1,valueFormatter: (params) => {
        if (!params.value) return '';
				const date = new Date(params.value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    	} },
			{ field: 'authority', headerName: '権限', width: 90, columnSeparatorWidth: 1, valueGetter: (params) => roleOptions[params.value]},
			{ field: 'email', headerName: 'メールアドレス', width: 250, columnSeparatorWidth: 1 },
			{ field: 'username', headerName: '名称', width: 200, columnSeparatorWidth: 1 },
			{field: 'remark', headerName: '備考', flex: 1, columnSeparatorWidth: 1},
		];
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '1em',
    }
	//===============================================
	// JSXのレンダー
	//===============================================
    return (
        <>
            <Header title={ systemName } loginInfo="on" backButton="on" backPage="/home" />
                <div style={homeContainer}>
                    <Frame classname="frame" title="ユーザーリスト">
                        <CustomDataGrid
                            getRowClassName={(params) =>
                              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            editMode                = "row"
                            rows                    = { userList }
                            columns                 = { columns }
                            sx                      = { {height:dataGridHeight,fontSize:14,borderColor: 'primary.light',} }
                            density                 = "compact"
                            localeText              = { jaJP.components.MuiDataGrid.defaultProps.localeText }
                            showColumnRightBorder
                            showCellRightBorder
                            initialState            = {{pagination: { paginationModel: { pageSize: 100 } },}}
                            pageSizeOptions         = {[50, 100, 150, 200]}
                            loading                 = { userLoading }
                        />
                    </Frame>
                </div>
        </>
    )
}
export default UserList