import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// TODO: envに置き換え
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENTID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECTURL,
  },
};

export function MicorsoftProvider({ children }) {
  const msalInstance = new PublicClientApplication(msalConfig);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
