import { createTheme } from '@mui/material/styles';
/** 基本のテーマ */
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#007D50',
    },
    secondary: {
      main: '#02A99A',
    },
    tertiary: {
      main: '#808080',
    },
    fourthly: {
      main: '#222B35',
    },
    fifthly: {
      main: '#ff7f50',
    },
    attention: {
      main: '#FF474A',
    },
  },

  typography: {
    fontFamily: [
      'Meiryo UI',
      'Meiryo',
      'Yu Gothic',
      'YuGothic',
      '-apple-system',
      'Hiragino Sans',
      'Noto Sans CJK JP',
      'Arial',
      'Roboto',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
