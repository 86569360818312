
import './../../styles/components/uiParts/Frame.css'
import { memo } from 'react'
import PropTypes from 'prop-types'

/** タイトルつきの枠 */
const Frame = memo(({ title, children }) => {
  return (
    <div className="frame">
      <div className="frame_container">
        <div className="frame_header">
          <span>{title}</span>
        </div>
        <div className="frame_body">{children}</div>
      </div>
    </div>
  );
});

Frame.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Frame;
