import { memo, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Login from './pages/Login'
import Home from './pages/Home'
import SellerHome from './pages/seller/Home'

import Authen from './pages/Authen'
import SiteTop from './pages/entry/SiteTop'
import MainForm from './pages/entry/MainForm'
import MainConfirm from './pages/entry/MainConfirm'
import TeamsOfUse from './pages/entry/TeamsOfUse'
import Policy from './pages/entry/Policy'
import ItemForm from './pages/entry/ItemForm'
import ItemConfirm from './pages/entry/ItemConfirm'
import BuyerForm from './pages/entry/BuyerForm'
import BuyerConfirm from './pages/entry/BuyerConfirm'
import Complete from './pages/entry/Complete'
import SellerList from './pages/admin/SellerList'
import BuyerList from './pages/admin/BuyerList'

import SellerMypage from './pages/seller/MyPage';
import SellerEditMypage from './pages/seller/EditMyPage';

import SellerItemPage from './pages/seller/ItemPage';
import SellerEditItemPage from './pages/seller/EditItempage';
import SellerNewItemPage from './pages/seller/NewItempage';
import SellerConfirmNewItemPage from './pages/seller/ConfirmNewItemPage';
import SellerDoneNewItemPage from './pages/seller/DoneNewItemPage';
import SellerItemPreviewPage from './pages/seller/ItemPreviewPage';

import BuyerHomePage from './pages/buyer/Home';
import BuyerProfilePage from './pages/buyer/Profile';

import BuyersListPage from './pages/seller/BuyersListPage';
import NegotitationPage from './pages/seller/NegotitationPage';
import BuyerMeetingsPage from './pages/buyer/Meetings';
import BuyerSellerListPage from './pages/buyer/SellerList';
import BuyerSchedulesPage from './pages/buyer/Schedules';
import BuyerSellerDetailPage from './pages/buyer/SellerDetail';
import SellerMeetingsPage from './pages/seller/Meetings';
import BuyerItemListPage from './pages/buyer/Items';
import BuyerItemDetailPage from './pages/buyer/ItemDetail';
import SellerDoneEditItemPage from './pages/seller/DoneEditItem';
import SellerDoneEditProfilePage from './pages/seller/DoneEditProfile';
import SellerDoneNegotiationPage from './pages/seller/DoneNegotiation';
import UserList from './pages/admin/UserList';
import { useAuth } from './contexts/AuthContext';
import ResetPassword from './pages/buyer/ResetPassword';
import MeetingList from './pages/admin/MeetingList';
import SellerMeetingReportPage from './pages/seller/MeetingReport';
import SellerDoneReportPage from './pages/seller/DoneReport';

const AppRouter = memo(() => {
  const [formData, setFormData] = useState({});
  const { authInfo } = useAuth()
  const navigate = useNavigate();
  const location = useLocation();

  const adminPages = ["/home", "/sellerList", "/buyerList", "/userList", "/meetings"]

  useEffect(() => {
    const authority = authInfo?.authority
    if (authority === "2") {
      if (adminPages.includes(location.pathname) || location.pathname.includes("/buyer/")) {
        navigate("/seller")
      }
    } else if (authority === "3") {
      // バイヤー
      if (adminPages.includes(location.pathname) || location.pathname.includes("/seller/")) {
        navigate("/buyer")
      }
    }
  }, [location])

  return (
    <Routes>
      {/* 未ログインユーザ */}
      <Route path="/" element={<SiteTop />} />
      <Route path="/authen" element={<Authen />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/teamsOfUse" element={<TeamsOfUse />} />
      <Route path="/mainForm" element={<MainForm formData={formData} setFormData={setFormData} />} />
      <Route path="/mainConfirm" element={<MainConfirm formData={formData} setFormData={setFormData} />} />
      <Route path="/itemForm" element={<ItemForm formData={formData} setFormData={setFormData} />} />
      <Route path="/itemConfirm" element={<ItemConfirm formData={formData} setFormData={setFormData} />} />
      <Route path="/buyerForm" element={<BuyerForm formData={formData} setFormData={setFormData} />} />
      <Route path="/buyerConfirm" element={<BuyerConfirm formData={formData} setFormData={setFormData} />} />
      <Route path="/complete" element={<Complete />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home />} />
      {/* admin */}
      <Route path="/sellerList" element={<SellerList />} />
      <Route path="/buyerList" element={<BuyerList />} />
      <Route path="/userList" element={<UserList />} />
      <Route path="/meetings" element={<MeetingList />} />
      {/* 出展者 */}
      <Route path="/seller" element={<SellerHome formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/profile" element={<SellerMypage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/profile/edit" element={<SellerEditMypage formData={formData} setFormData={setFormData} />} />
      {/* <Route path="/seller/profile/confirm" element={<SellerConfirmEditMypage formData={formData} setFormData={setFormData} />} /> */}
      <Route path="/seller/profile/done" element={<SellerDoneEditProfilePage />} />
      <Route path="/seller/items" element={<SellerItemPage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/items/new" element={<SellerNewItemPage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/items/new/confirm" element={<SellerConfirmNewItemPage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/items/new/done" element={<SellerDoneNewItemPage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/items/:id/edit" element={<SellerEditItemPage formData={formData} setFormData={setFormData} />} />
      {/* <Route path="/seller/items/:id/confirm" element={<SellerConfirmEditItemPage formData={formData} setFormData={setFormData} />} /> */}
      <Route path="/seller/items/:id/done" element={<SellerDoneEditItemPage />} />
      <Route path="/seller/items/:id/preview" element={<SellerItemPreviewPage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/meetings" element={<SellerMeetingsPage />} />
      {/* <Route path="/seller/meetings/:id/report" element={<SellerMeetingReportPage formData={formData} setFormData={setFormData} />} />
      <Route path="/seller/meetings/:id/done" element={<SellerDoneReportPage />} /> */}
      {/* <Route path="/seller/buyers" element={<BuyersListPage />} /> */}
      <Route path="/seller/:id/contact" element={<NegotitationPage />} />
      <Route path="/seller/:id/contact/done" element={<SellerDoneNegotiationPage />} />
      {/* バイヤー */}
      <Route path="/buyer" element={<BuyerHomePage />} />
      <Route path="/buyer/profile" element={<BuyerProfilePage />} />
      <Route path="/buyer/meetings" element={<BuyerMeetingsPage />} />
      <Route path="/buyer/sellers" element={<BuyerSellerListPage />} />
      <Route path="/buyer/sellers/:id" element={<BuyerSellerDetailPage />} />
      <Route path="/buyer/sellers/:id/contact" element={<BuyerSchedulesPage />} />
      <Route path="/buyer/items" element={<BuyerItemListPage />} />
      <Route path="/buyer/items/:id" element={<BuyerItemDetailPage />} />
      <Route path="/buyer/password" element={<ResetPassword />} />
      <Route
        path="*"
        element={
          <main>
            <p>一致するものがありません</p>
          </main>
        }
      />
    </Routes>
  );
});

export default AppRouter;