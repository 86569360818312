/** API接続のベースURL **/
export const facilityData = {
  facilityId : 1,
  facilityCode : 1001,
  facilityName : 'DealNet',
  systemName   : 'DealNet',
    logoUrl      : '/logo.svg'
}
/** 都道府県 **/
export const cityList = [
  {code:1,label:'新潟県'},
  {code:2,label:'富山県'},
  {code:3,label:'石川県'},
  {code:4,label:'福井県'}
]
/** 商品カテゴリ **/
export const itemCategoryList = [
  {code:1,label:'肉'},
  {code:2,label:'魚介'},
  {code:3,label:'青果'},
  {code:4,label:'酒類'},
  {code:5,label:'飲料'},
  {code:6,label:'菓子'},
  {code:7,label:'加工品'},
  {code:8,label:'その他'},
  /*{code:5,label:'工芸品'}*/
]
/** 出展回 9月以降は2回目のみ受け付け **/
export const exhibitListNew = [
  {code:2,label:'第2回目'},
]
/** 出展回 **/
export const exhibitList = [
  {code:1,label:'第1回目'},
  {code:2,label:'第2回目'},
]

/** 業種 **/
export const industryList = [
	{"checkField01": "農業"},
	{"checkField02": "水産業"},
	{"checkField03": "小売業"},
	{"checkField04": "食品加工"},
	{"checkField05": "製造業"},
	{"checkField06": "卸売業"},
	{"checkField07": "飲食"},
	{"checkField08": "その他"},
]

/** 認証取得 **/
export const certificationList = [
	{"checkField09": "有機JAS"},
	{"checkField10": "JGAP"},
	{"checkField11": "ASIAGAP"},
	{"checkField12": "GlobalGAP"},
	{"checkField13": "ISO9001"},
	{"checkField14": "ISO14001"},
	{"checkField15": "ISO22000"},
	{"checkField16": "FSSC22000"},
	{"checkField17": "US HACCAP"},
	{"checkField18": "EU HACCAP"},
	{"checkField19": "なし"},
	{"checkField20": "その他"},
]