import React from 'react'
import { FormControl, InputLabel, OutlinedInput } from '@mui/material'

const CustomOutlinedInput = ({ id, inputRef, label, width, value, onChange, type, style, onClick }) => {
  return (
    <FormControl className="formcontrol" variant="outlined" size="small" sx={{ width: width || 'auto' }} style={style}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput id={id} inputRef={ inputRef } label={label} size="small" type={type} value={value} onClick={onClick} onChange={onChange} autoComplete="off" style={{backgroundColor:'#fff'}} />
    </FormControl>
  );
}

export default CustomOutlinedInput