import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from '../components/Footer'
import {
  Button,
  FormControl,
  Grid,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import Header from "../components/Header";
import { useAuth } from "./../contexts/AuthContext";
import CustomOutlinedInput from "../components/input/CustomOutlinedInput";
import { authData, apiURL } from "../api/ApiConfig";
import { useFetch } from "./../api/ApiRequests";
import { useMsal } from "@azure/msal-react";

const Login = () => {
  const { instance } = useMsal();
  const [isAuthen, setIsAuthen] = useState(false);
  const [isStartSession, setIsStartSession] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  axios.defaults.baseURL = apiURL;

  const { setAuthInfo } = useAuth()

  const effectRan = useRef(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  //-----------------------------------------------
  // Storeデータの取得
  //-----------------------------------------------
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userName: username,
  };
  //-----------------------------------------------
  // API - ユーザマスタ
  //-----------------------------------------------
  const {
    data: userData,
    loading: user_Loading,
    fetchData: fetchUserData,
  } = useFetch(getData);

  //===============================================
  // useEffect
  //===============================================
  //-----------------------------------------------
  // useEffect - ロード時の設定
  //-----------------------------------------------

  //===============================================
  // eventHandler
  //===============================================
  //-----------------------------------------------
  // eventHandler - ログイン認証
  //-----------------------------------------------
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const response = await axios.post("/api/token/", {
        email: username,
        password: password,
      });
      const me = await axios.post(
        "/mst/me/",
        {},
        {
          headers: {
            Authorization: `JWT ${response.data.access}`,
          },
        }
      );
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("token", response.data.access);
      sessionStorage.setItem("loginDateTime", new Date());
      sessionStorage.setItem("userId", me.data.user_id);
      sessionStorage.setItem("sellerId", me.data.seller_id);
      sessionStorage.setItem("buyerId", me.data.buyer_id);
      sessionStorage.setItem("userName", me.data.user_name);
      sessionStorage.setItem("authority", me.data.authority);
      setAuthInfo({
        isAuthenticated: true,
        token: response.data.access,
        loginDateTime: new Date(),
        userId: me.data.user_id,
        sellerId: me.data.seller_id,
        buyerId: me.data.buyer_id,
        userName: me.data.user_name,
        authority: me.data.authority,
      })
      const authority = me.data.authority;
      const url =
        authority === "1" ? "/home" : authority === "2" ? "/seller" : "/buyer";
      navigate(`${url}${(authority === "3" && me.data.required_password_change) ? "/password" : ""}`);
      setIsAuthen(true);
    } catch (e) {
      // TODO: エラーハンドリング
      console.error(e);
    }
  };
  //-----------------------------------------------
  // eventHandler - マイクロソフトログイン
  //-----------------------------------------------
  async function onClickMicrosoftLogin() {
    try {
      const firebaseResponse = await instance.loginPopup({
        scopes: ["User.Read"],
      });
      const response = await axios.post(
        "/api/firebase/token/",
        {},
        {
          headers: {
            Authorization: `Bearer ${firebaseResponse.account.idToken}`,
          },
        }
      );
      const me = await axios.post(
        "/mst/me/",
        {},
        {
          headers: {
            Authorization: `JWT ${response.data.access}`,
          },
        }
      );
      if (response) {
        sessionStorage.setItem("isAuthenticated", true);
        sessionStorage.setItem("token", response.data.access);
        sessionStorage.setItem("loginDateTime", new Date());
        sessionStorage.setItem("userId", me.data.user_id);
        sessionStorage.setItem("sellerId", me.data.seller_id);
        sessionStorage.setItem("buyerId", me.data.buyer_id);
        sessionStorage.setItem("userName", me.data.user_name);
        sessionStorage.setItem("authority", me.data.authority);
        setAuthInfo({
          isAuthenticated: true,
          token: response.data.access,
          loginDateTime: new Date(),
          userId: me.data.user_id,
          sellerId: me.data.seller_id,
          buyerId: me.data.buyer_id,
          userName: me.data.user_name,
          authority: me.data.authority,
        })
        const authority = me.data.authority;
        const url =
          authority === "1"
            ? "/home"
            : authority === "2"
              ? "/seller"
              : "/buyer";
        window.location.href = url;
        setIsAuthen(true);
      }
    } catch (e) {
      // TODO: エラーハンドリング
      console.error(e);
    }
  }

  //-----------------------------------------------
  // ユーザマスタを取得
  //-----------------------------------------------
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      fetchUserData(`/mst/user/?email=${username}`);
    }
  }, [isStartSession]);

  const microsoftLoginWrapper = {
    border: "1px solid black",
    borderRadius: "6px",
    padding: "0 0 10px 0",
    margin: "20px 10px 0 10px"
  };

  const loginFormWrapper = {
    border: "1px solid black",
    borderRadius: "6px",
    margin: "10px 10px 0 10px",
  };

  const microsoftLoginEnvelope = {
    color: "white",
    fontSize: "1.2rem",
    fontWeight: "bold",
    backgroundColor: "#0071BC",
    borderRadius: "5px 5px 0 0"
  }

  const loginEnvelope = {
    color: "white",
    fontSize: "1.2rem",
    fontWeight: "bold",
    backgroundColor: "#4BBC06",
    borderRadius: "5px 5px 0 0"
  }

  //===============================================
  // JSXのレンダー
  //===============================================
  return (
    <>
      <Header title={systemName} loginInfo="off" backButton="off" />
      <div className="login" style={{ overflow: "hidden" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ width: "100%", maxWidth: "600px" }}>
            <div className="login_div_2">北陸4県応援企画</div>
            <div className="login_div_2">食のオンライン商談会2024</div>
            <div className="login_div_2">参加者ログイン</div>
            <div style={microsoftLoginWrapper}>
              <div style={microsoftLoginEnvelope}>
                出展者ログイン
              </div>
              <Button
                onClick={onClickMicrosoftLogin}
                startIcon={<InfoIcon />}
                variant="contained"
                style={{
                  width: "20rem",
                  fontSize: "18px",
                  padding: "10px 50px",
                  marginTop: "30px",
                  backgroundColor: "#0071BC",
                  textTransform: "none",
                }}
              >
                Microsoft でログイン
              </Button>
              <div style={{ paddingTop: "10px", fontSize: isSmallScreen ? "14px" : "16px" }}>
                ※事務局より発行された『Microsoftアカウント』でログインしてください。
              </div>
              <div style={{ padding: "30px 15px 0 10px", fontSize: isSmallScreen ? "11px" : "16px", textAlign: "start" }}>
                パスワードをお忘れの方は、<a href="mailto:web-support@jfc-hokuriku4-online.com" target="_blank">こちらにご連絡ください</a> <br />
                パスワード管理はセキュリティー保護のため、再設定ををさせていただく必要があります。 <br />
                パスワードの再設定には時間がかかる場合がございますので大切に保管してください。
              </div>
            </div>
            <div style={loginFormWrapper}>
              <div style={loginEnvelope}>
                バイヤーログイン
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <CustomOutlinedInput
                    type="text"
                    id="login_userId"
                    label="メールアドレス"
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ marginTop: "30px", width: "20rem", fontSize: "22px" }}
                  />
                </div>
                <div>
                  <CustomOutlinedInput
                    type="password"
                    id="login_password"
                    label="パスワード"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ marginTop: "30px", width: "20rem", fontSize: "22px" }}
                  />
                </div>
                <div>
                  <FormControl className="formcontrol" variant="outlined">
                    <Button
                      type="submit"
                      color="secondary"
                      size="large"
                      style={{
                        fontSize: "20px",
                        width: "20rem",
                        margin: "30px 0",
                        color: "#fff",
                        backgroundColor: "#4BBC06",
                      }}
                      variant="contained"
                      startIcon={<InfoIcon />}
                    >
                      ログイン
                    </Button>
                  </FormControl>
                </div>
              </form>
            </div>
            <div style={{ paddingTop: "30px", fontSize: isSmallScreen ? "14px" : "16px" }}>
              ※登録した「メールアドレス」・「パスワード」を忘れた時は、{isSmallScreen && <br />}
              <a href="mailto:web-support@jfc-hokuriku4-online.com" target="_blank">こちらへお問い合わせください。</a>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};
export default Login;
