import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import {
  Grid,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  FormLabel,
  Stack,
  TextField,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useFetch } from "../../api/ApiRequests";
import { apiURL } from "../../api/ApiConfig";
import { certificationList, cityList } from "../../Store";
import { usePost } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";

const SellerMyPage = ({ formData, setFormData }) => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const { data: sellerData, fetchData: fetchSellerData } = useFetch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  const certificationCheckFields = (data) => {
    const checkFields = {};
    for (let i = 9; i <= 20; i++) {
      const key = `checkField${String(i).padStart(2, '0')}`;
      if (key in data) {
        checkFields[key] = data[key];
      }
    }
    return checkFields;
  };

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;

  const effectRan = useRef(false);

  const title = `出展者ユーザ：${userName}`;

  //===============================================
  // API
  //===============================================

  useEffect(() => {
    if (!authInfo.sellerId) return;
    fetchSellerData(`/mst/seller/${authInfo.sellerId}`);
  }, [authInfo]);
  //-----------------------------------------------
  // API - POSTメソッドのテンプレート
  //-----------------------------------------------
  const { postData } = usePost({
    userId: userId,
    userName: userName,
  });

  //===============================================
  // useEffect
  //===============================================
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // getData()
  }, []);

  //===============================================
  // eventHandler
  //===============================================
  const handleClick_Edit = () => {
    setFormData(sellerData);
    navigate("/seller/profile/edit");
  };

  //===============================================
  // CSS
  //===============================================
  const homeContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F1",
    padding: "0 10px",
  };
  const homeInnerContainer = {
    flexDirection: "column",
    maxWidth: "800px",
    width: "100%",
    overflow: "hidden",
  };
  const responsiveImage = {
    width: "300px",
    margin: "0 auto"
  };
  const formContainer = {
    marginTop: "30px",
    paddingTop: "15px",
    paddingBottom: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
  };
  if (!sellerData) return <></>;
  const item = cityList.find((item) => item.code == sellerData.city);
  const cityName = item?.label || "";

  function statusCodeNumberToStiring(code) {
    switch (code) {
      case 0:
        return "未承認";
      case 1:
        return "承認済み";
      case 9:
        return "非承認";
    }
  }
  return (
    <>
      <Header
        title={systemName}
        loginInfo={isSmallScreen ? "off" : "on"}
        backButton={isSmallScreen ? "off" : "on"}
      />
      <div style={{}}>
        <TitleBar title={title} />
      </div>
      <div style={homeContainer}>
        <div style={homeInnerContainer}>
          <div
            style={{
              textAlign: "start",
              fontWeight: "bold",
              margin: "4px 0",
            }}
          >
            商品ページ状態：{statusCodeNumberToStiring(sellerData.statusCode)}
          </div>
          <div
            style={{
              backgroundColor: "#D5EDC7",
              padding: "15px",
              borderRadius: "10px",
              textAlign: "start",
            }}
          >
            編集する場合は「編集」ボタンをクリックして任意の項目を編集し、「承認申請」ボタンをクリックしてください。
            事務局により承認されるとサイトに反映されます。
          </div>
          <div className="form-container" style={formContainer}>
            <div style={{ position: "relative" }}>
              <Button
                onClick={() => handleClick_Edit()}
                variant="contained"
                sx={{ mr: 1 }}
                size="small"
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: "12px",
                  color: "#fff",
                  backgroundColor: "#4BBC06",
                  fontSize: "20px",
                  width: "150px",
                  margin: "0.5rem",
                }}
              >
                編&emsp;集
              </Button>
              <div
                style={{
                  textAlign: isSmallScreen ? "start" : "center",
                  fontSize: "1.5em",
                  margin: "0.5em",
                  fontWeight: "bold",
                  borderBottom: "1px solid",
                }}
              >
                基本情報
              </div>
            </div>
            {isSmallScreen ? (
              <Grid
                container
                direction="column"
                padding="0 10px"
                justifyContent="center"
                alignItems="start"
                required="on"
                marginBottom={"20px"}
                width="100%"
              >
                <Grid item xs={12} sx={{ width: "100%" }}>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",
                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        出展希望回
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{
                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.exhibit}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",
                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        メールアドレス
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.eMail}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        出展者名
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.name}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        出展者名(フリガナ)
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.nameKana}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        電話番号
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.tel}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        部署
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.busho}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        担当者
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.tantosha}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        都道府県
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {cityName}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        所在地
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.address}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        出展者業種
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField01 ? (
                          <span style={{ marginLeft: "1em" }}>農業</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField02 ? (
                          <span style={{ marginLeft: "1em" }}>水産業</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField03 ? (
                          <span style={{ marginLeft: "1em" }}>小売業</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField04 ? (
                          <span style={{ marginLeft: "1em" }}>食品加工</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField05 ? (
                          <span style={{ marginLeft: "1em" }}>製造業</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField06 ? (
                          <span style={{ marginLeft: "1em" }}>卸売業</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField07 ? (
                          <span style={{ marginLeft: "1em" }}>飲食業</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField08 ? (
                          <span style={{ marginLeft: "1em" }}>その他</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        その他(自由記入)
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.textField02}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        取得認証
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField09 ? (
                          <span style={{ marginLeft: "1em" }}>有機JAS</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField10 ? (
                          <span style={{ marginLeft: "1em" }}>JGAP</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField11 ? (
                          <span style={{ marginLeft: "1em" }}>ASIAGAP</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField12 ? (
                          <span style={{ marginLeft: "1em" }}>lobal GAP</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField13 ? (
                          <span style={{ marginLeft: "1em" }}>ISO 9001</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField14 ? (
                          <span style={{ marginLeft: "1em" }}>ISO 14001</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField15 ? (
                          <span style={{ marginLeft: "1em" }}>ISO 22000</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField16 ? (
                          <span style={{ marginLeft: "1em" }}>FSSC 22000</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField17 ? (
                          <span style={{ marginLeft: "1em" }}>US・HACCP</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField18 ? (
                          <span style={{ marginLeft: "1em" }}>EU・HACCP</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField19 ? (
                          <span style={{ marginLeft: "1em" }}>
                            取得認証なし
                          </span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                      <FormLabel
                        sx={{ whiteSpace: "nowrap", fontSize: "1em", marginLeft: "1em" }}
                      >
                        {sellerData.checkField20 ? (
                          <span style={{ marginLeft: "1em" }}>その他</span>
                        ) : (
                          ""
                        )}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        その他(取得記述)
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.textField02}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        認定・受賞歴など
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.textField03}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        ホームページ
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.textField05}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        X（Twitter）
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.textField06}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    borderBottom="1px dashed #0009"
                  >
                    <Grid item xs={2} sx={{ textAlign: "left", mt: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        Instagram
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, textAlign: "left" }}>
                      <FormLabel
                        sx={{

                          fontSize: "1em",
                          marginLeft: "1em",
                        }}
                      >
                        {sellerData.textField07}
                      </FormLabel>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" textAlign="center">
                    <Grid item xs={2} sx={{ textAlign: "left", my: 1.5 }}>
                      <FormLabel
                        sx={{
                          color: "#4bbc06",

                          fontWeight: "bold",
                          fontSize: "1em",
                        }}
                      >
                        メイン画像
                      </FormLabel>
                    </Grid>
                    <Grid item>
                      <img src={`${apiURL}media/uploads/${sellerData.imageField01}`} alt="Example" style={responsiveImage} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Stack spacing={2} sx={{ my: 4 }}>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展希望回</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.exhibit}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メールアドレス</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.eMail}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.name}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名(フリガナ)</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.nameKana}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>電話番号</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.tel}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>部署</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.busho}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>担当者</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.tantosha}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>都道府県</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{cityName}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>所在地</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.address}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者業種</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>
                    {sellerData.checkField01 ? <span style={{ marginright: "1em" }}>農業</span> : ""}
                    {sellerData.checkField02 ? <span style={{ marginRight: "1em" }}>水産業</span> : ""}
                    {sellerData.checkField03 ? <span style={{ marginRight: "1em" }}>小売業</span> : ""}
                    {sellerData.checkField04 ? <span style={{ marginRight: "1em" }}>食品加工</span> : ""}
                    {sellerData.checkField05 ? <span style={{ marginRight: "1em" }}>製造業</span> : ""}
                    {sellerData.checkField06 ? <span style={{ marginRight: "1em" }}>卸売業</span> : ""}
                    {sellerData.checkField07 ? <span style={{ marginRight: "1em" }}>飲食業</span> : ""}
                    {sellerData.checkField08 ? <span style={{ marginRight: "1em" }}>その他</span> : ""}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他(自由記入)</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.textField01}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>取得認証</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>
                    {(() => {
                      const checkFields = certificationCheckFields(sellerData);
                      return certificationList.filter(industry => {
                        const key = Object.keys(industry)[0];
                        return checkFields[key];
                      }).map(industry => Object.values(industry)[0]).join(',\n');
                    })()}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他(取得記述):</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.textField02}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>認定・受賞歴など</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.textField03}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>ホームページ</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.textField05}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>X（Twitter）</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.textField06}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>Instagram</Typography>
                  <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{sellerData.textField07}</Typography>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                  <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メイン画像</Typography>
                  <Box sx={{ width: "100%", maxWidth: '380px' }}>
                    <img src={`${apiURL}media/uploads/${sellerData.imageField01}`}
                      alt="Example"
                      style={responsiveImage} />
                  </Box>
                </Stack>
              </Stack>
            )}
          </div>
        </div>
        <Button
          onClick={() => navigate(-1)}
          variant="outlined"
          sx={{ mr: 1 }}
          size="large"
          style={{
            color: "#4BBC06",
            fontSize: "20px",
            width: "300px",
            margin: "1rem",
          }}
        >
          戻　る
        </Button>
      </div>
      <Footer />
    </>
  );
};

export default SellerMyPage;
