import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, Box, useMediaQuery, useTheme, Button, FormLabel } from '@mui/material'
import { usePost } from './../../api/ApiRequests';
import { useAuth } from './../../contexts/AuthContext'

const BuyerConfirm = ({ formData, setFormData }) => {
    console.log(formData)
    const { authInfo,    setAuthInfo    } = useAuth()
    const navigate = useNavigate()

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
    // initialize - セッション情報を取得
	//-----------------------------------------------
    const userId    = authInfo.userId
    const userName  = authInfo.userName
    const authority = authInfo.authority
    
    const effectRan = useRef(false)

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    //===============================================
    // API
    //===============================================
	//-----------------------------------------------
    // API - POSTメソッドのテンプレート
	//-----------------------------------------------
    const { postData } = usePost({
        userId   : userId,
        userName : userName
    })
    //===============================================
    // eventHandler
    //===============================================
	//-----------------------------------------------
    // eventHandler - 登録処理を実行する
	//-----------------------------------------------
    const handleConfirm = async () => {
        try {
            const onSuccess = (data) => {
                send_mail()
            }
            await postData('/mst/buyer/', formData, onSuccess);
        } catch (error) {
            console.error(error);
        }
    }
	//-----------------------------------------------
    // eventHandler - フォームに戻る
	//-----------------------------------------------
    const handleClick_entry = () => {
        navigate('/buyerForm')
    }
	//-----------------------------------------------
    // eventHandler - 受付メールを送信する
	//-----------------------------------------------
    const send_mail = () => {

        const title = '【北陸4県応援企画・食のオンライン商談会2024】参加のお申込ありがとうございます'
        let mailText = `ご担当者様\n\n`
        mailText = mailText + `北陸4県応援企画・食のオンライン商談会2024へのお申込ありがとうございました。\n`
        mailText = mailText + `事務局の審査が完了しましたら、事務局からご連絡いたします。\n\n`
        mailText = mailText + `※送信元のメールアドレスは送信専用となっております。\n`
        mailText = mailText + `返信いただいても、ご回答できませんのであらかじめご了承願います。\n\n`
        
        const sendData = {
            subject        : title,
            message        : mailText,
            recipient_list : [formData.eMail],
            cc_list        : []
        }
        const submitDataToPost = async () => {
            try {
                await postData('/mst/send_email/', sendData)
            } catch (error) {
                console.error('Error in POST request:', error)
            }
        }
        submitDataToPost()
        // 後処理
        try {
            navigate('/complete')
        } catch (error) {
            console.error('Error in update function:', error);
        }
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'left',
        paddingBottom: '250px' // フッターの高さ分だけ余白を追加
    }
    const formContainer = {
        display: 'flex',
        justifyContent: 'left',
    }
    return (
        <>
            <Header title={ systemName }  loginInfo="off" backButton="off"  />
            <div style={{paddingLeft:'10px', paddingRight:'10px'}}>
                <TitleBar title='バイヤー参加者登録フォーム 入力情報の確認' backgroundColor='#F0F0F1' />
            </div>
            <div style={homeContainer}>
                <div style={{fontSize:'1.5em', margin:'0.5em', fontWeight:'bold', borderBottom:'1px solid'}}>基本情報</div>
                <div className="form-container" style={formContainer}>
                    <Grid 
                        container 
                        direction="column" 
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        required='on'
                    >
                        <Grid item xs={12}>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>メールアドレス</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.eMail}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>会社名</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.name}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>部署</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.busho}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>担当者</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.tantosha}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>住所</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.address}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>電話番号</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.tel}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>携帯番号</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.mobile}</FormLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
                    <Grid item xs={12} sx={{mt:5}}>
                        <Button
                            type="button"
                            variant="contained" sx={{mr:1}}
                                        size      = "large"
                            style={{color:'#fff', backgroundColor:'#ff6347', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                            onClick={handleClick_entry}
                            >
                            入力をやり直す
                        </Button>
                        <Button
                        type="submit"
                            variant="contained" sx={{mr:1}}
                                        size      = "large"
                            style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                            onClick={handleConfirm}
                            >
                            決定して申請を終了する
                        </Button>
                    </Grid>
            </div>
            <Footer />
        </>
  );
};

export default BuyerConfirm;