
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'
import { useForm, Controller, FormProvider  } from 'react-hook-form';
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Stack, TextField, FormHelperText } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useAuth } from './../../contexts/AuthContext'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import TitleBar from '../../components/uiParts/TitleBar'
import EntryCustomInput from '../../components/entryForm/EntryCustomInput'
import EntryCustomField from '../../components/entryForm/EntryCustomField'
import EntryCustomSelect from '../../components/entryForm/EntryCustomSelect'
import { usePost } from './../../api/ApiRequests'
import { addDays, format } from 'date-fns'
import { itemCategoryList } from '../../Store'

const ItemForm = ({ setFormData, formData  }) => {

    const { authInfo,    setAuthInfo    } = useAuth()
    
    const navigate = useNavigate()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search           = useLocation().search
    const query            = new URLSearchParams(search)
    const sellerId        = query.get('sellerId')
    
	//===============================================
	// initialize
	//===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
    // initialize - セッション情報を取得
	//-----------------------------------------------
    const userId    = authInfo.userId
    const userName  = authInfo.userName
    const authority = authInfo.authority
    
    const effectRan = useRef(false)

    console.log("sellerId",sellerId)

    //===============================================
    // フォーム
    //===============================================
	//-----------------------------------------------
    // フォーム - スキーマ定義
	//-----------------------------------------------
    const schema = yup.object().shape({
        name: yup.string().required('商品名を入力してください'),
        nameKana: yup.string().required('商品名（フリガナ）を入力してください'),
        itemCategory: yup.string().required('商品カテゴリを選択してください'),
        imageField01: yup.string().required('メイン画像を指定してください'),
    })
	//-----------------------------------------------
    // フォーム - 登録データのテンプレート
	//-----------------------------------------------
    const registerData ={
        seller: sellerId,
        entryDate: today,
        deleteFlag: 0,
        name: '',
        nameKana: '',
        itemCategory: '',
        textField01: '',
        textField02: '',
        textField03: '',
        imageField01: '',
        imageField02: '',
        imageField03: '',
        itemCategoryList: itemCategoryList
    }
    const [defaultValues, setDefaultValues] = useState(registerData)
    const useFormMethods = useForm({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { control, handleSubmit, formState: { errors }, setValue } = useFormMethods

    //===============================================
    // API
    //===============================================
	//-----------------------------------------------
    // API - POSTメソッドのテンプレート
	//-----------------------------------------------
    const { postData } = usePost({
        userId   : userId,
        userName : userName
    })
    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    //===============================================
    // eventHandler
    //===============================================
	//-----------------------------------------------
    // eventHandler - 画像ファイルのアップロード
	//-----------------------------------------------
    const handleSubmit_upload = async (e) => {
        console.log(e)
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const onSuccess = (data) => {
                const url = data.file;
                const extractedPart = url.split('media/uploads/')[1];
                console.log('Extracted Part:', extractedPart); // ここで確認のためにログ出力
                // 必要に応じて状態を更新
                setValue('imageField01', extractedPart); // 例: React Hook Formなどを使用している場合
            }
            await postData('/mst/uploads/', formData, onSuccess);
        } catch (error) {
            console.error(error);
        }
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '250px' // フッターの高さ分だけ余白を追加
    }
    const onSubmit = data => {
        console.log("data", data);
        setFormData(data);
        navigate('/itemConfirm?sellerId='+sellerId);
    }
    const formContainer = {
        display: 'flex',
        justifyContent: 'center',
    }
	//===============================================
	// JSXのレンダー
	//===============================================
    return (
    <>
        <Header title={ systemName } loginInfo="off" backButton="off"  />
        <TitleBar title='商品情報登録フォーム' />
    
        <div style={homeContainer}>
            <FormProvider {...useFormMethods}>
            <div className="form-container" style={formContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{fontSize:'1.5em', margin:'0.5em', fontWeight:'bold', borderBottom:'1px solid'}}>商品情報</div>
                <Grid 
                    container 
                    direction="column" 
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    required='on'
                >
                    <Grid item xs={12}>
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                            <EntryCustomInput
                                id='name' name='name'
                                label='商品名' labelWidth='250px' required='on' placeHolder="例：こしひかり"
                            />
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                            <EntryCustomInput
                                id='nameKana' name='nameKana'
                                label='商品名（フリガナ）' labelWidth='250px' required='on' placeHolder="例：コシヒカリ"
                            />
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                            <EntryCustomSelect
                                id='itemCategory' name='itemCategory'
                                label='商品カテゴリ' labelWidth='250px' required='on' placeHolder="選択してください"
                            />
                        </Grid>
                        {errors.itemCategory && 
                            <>
                                <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                    <Grid item sx={{width:'250px', textAlign:'left', mt:1}}></Grid>
                                    <Grid item xs={isSmallScreen ? 12 : 'auto'}>
                                        <div style={{color:'#d32f2f', fontSize:'0.8em'}}>{errors.itemCategory.message}</div>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                            <EntryCustomField
                                id='textField01' name='textField01'
                                label='キャッチフレーズ' labelWidth='250px' required='off' placeHolder="100文字以内"
                            />
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                            <EntryCustomField
                                id='textField02' name='textField02'
                                label='商品説明' labelWidth='250px' required='off' placeHolder="100文字以内"
                            />
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} sx={{mt:1}}>
                            <Grid item sx={{width:'250px', textAlign:'left', mt:1}}>
                                <FormLabel sx={{ whiteSpace: 'nowrap', border:'1px solid red', color:'red', padding:'5px' }}>必須</FormLabel>
                                <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold' }}>商品画像</FormLabel>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{mt: 2}}>
                                <Controller
                                    name='imageField01'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id='imageField01'
                                            variant='outlined'
                                            size='small'
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                height: 35,
                                                width:'300px'
                                                },
                                            }}
                                            placeholder='ファイル名'
                                            helperText={errors?.imageField01?.message}
                                            FormHelperTextProps={{ sx: { width: 200 } }}
                                            error={errors?.imageField01?.message ? true : false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText>サイト上・ガイドブックに掲載されます。</FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} sx={{mt:0}}>
                            <Grid item sx={{width:'250px', textAlign:'left', mt:1}}></Grid>
                            <Grid item xs={isSmallScreen ? 12 : 'auto'}>
                                <Stack>
                                    <Stack direction='row' alignItems='flex-start'>
                                        <Grid sx={{ mr: 1 }}>
                                        <input type="file" accept="image/jpeg, image/png, image/gif" onChange={(e) => handleSubmit_upload(e)} />
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{mt:5}}>
                    <Button
                        type="submit" variant="contained" sx={{mr:1}} size="large"
                        style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                        >
                        入力情報の確認
                    </Button>
                </Grid>
                </form>
            </div>
            </FormProvider>
        </div>
        <Footer />
    </>
    )
}
export default ItemForm