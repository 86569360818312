
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'
import { Grid, useMediaQuery, useTheme, Button} from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from './../../contexts/AuthContext'
import TitleBar from '../../components/uiParts/TitleBar';

const TeamsOfUse = () => {

    const { authInfo } = useAuth()
    
    const navigate = useNavigate()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const target = query.get('target')
    
	//===============================================
	// initialize
	//===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
    // initialize - セッション情報を取得
	//-----------------------------------------------
    const userId    = authInfo.userId
    const userName  = authInfo.userName
    const authority = authInfo.authority
    
    const effectRan = useRef(false)

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    //===============================================
    // API
    //===============================================
    
  
    //===============================================
    // useEffect
    //===============================================
    

    //===============================================
    // eventHandler
    //===============================================
    const handleClick_entry = () => {
        navigate('/authen?target=' + target)
    }
    
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'center',
    }
    const teamsOfUse_main = {
        border: '1px solid #436629',
        height: 'calc(100vh - 320px)',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding:'2px'
    }
    const teamsOfUse_title = {
        textAlign: 'left',
        color:'black',
        fontWeight:'bold',
    }
    const teamsOfUse_body = {
        textAlign: 'left',
        marginLeft: '1em',
        color:'black',
      whiteSpace: 'pre-line'
    }
	//===============================================
	// JSXのレンダー
	//===============================================
    return (
    <>
        <Header title={ systemName }  loginInfo="off" backButton="off"  />
        <TitleBar title='参加規約' backgroundColor='white'/>
    
        <div style={homeContainer}>
            <div style={{ width: '100%', color: 'white', textAlign: 'center', }}>
                <Frame title="北陸４県応援企画・食のオンライン商談会 参加規約">
                    <div className="teamsOfUse">
                        <div style={ teamsOfUse_main }>
                            <div>
                                <p style={ teamsOfUse_title }>（参加規約の適用）</p>
                                <p style={ teamsOfUse_body }>第１条　本規約は、株式会社日本政策金融公庫（以下「日本公庫」といいます。）が主催する「北陸４県応援企画 食のオンライン商談会」（以下「本会」といいます。）における、日本公庫と本会の出展申込者、出展者及びバイヤー（以下「参加者」といいます。）との一切の関係に適用されます。</p>
                                <p style={ teamsOfUse_body }>２　参加者は、本規約に同意したうえで、本規約の規定を遵守して本会に申込・参加するものとします。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（反社会的勢力の排除）</p>
                                <p style={ teamsOfUse_body }>第２条　参加者は、現在、暴力団、暴力団員、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下「暴力団員等」といいます。）のいずれにも該当しないこと及び次の各号のいずれにも該当しないことを表明し、かつ、将来にわたって該当しないことを確約するものとします。</p>
                                <p style={ teamsOfUse_body }>（１）暴力団員等が経営を支配していると認められる関係を有すること。</p>
                                <p style={ teamsOfUse_body }>（２）暴力団員等が経営に実質的に関与していると認められる関係を有すること。</p>
                                <p style={ teamsOfUse_body }>（３）自己、自社若しくは第三者（他の参加者を含みます。以下同じ。）の不正の利益を図る目的又は第三者に損害を与える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること。</p>
                                <p style={ teamsOfUse_body }>（４）暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること。</p>
                                <p style={ teamsOfUse_body }>（５）役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_body }>２　参加者は、自己又は第三者を利用して次の各号のいずれかに該当する行為を行わないことを確約するものとします。</p>
                                <p style={ teamsOfUse_body }>（１）暴力的な要求行為</p>
                                <p style={ teamsOfUse_body }>（２）法的な責任を超えた不当な要求行為</p>
                                <p style={ teamsOfUse_body }>（３）脅迫的な言動をし、又は暴力を用いる行為</p>
                                <p style={ teamsOfUse_body }>（４）風説を流布し、偽計を用い若しくは威力を用いて日本公庫及び他の参加者の信用を毀損し、又は日本公庫及び他の参加者の業務を妨害する行為</p>
                                <p style={ teamsOfUse_body }>（５）その他前各号に準ずる行為</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（本会への出展申込）</p>
                                <p style={ teamsOfUse_body }>第３条　本会に出展申込できるのは、次の各号のいずれの条件も満たす事業者とします。</p>
                                <p style={ teamsOfUse_body }>（１）出展申込時点において、日本公庫に事業資金の融資残高を有すること。</p>
                                <p style={ teamsOfUse_body }>（２）新潟県、富山県、石川県若しくは福井県のいずれかに、本店又は支店を有すること。</p>
                                <p style={ teamsOfUse_body }>（３）農林水産業を営んでいる又は飲食料品の製造若しくは販売を行っていること。</p>
                                <p style={ teamsOfUse_body }>２　出展及びバイヤーの申込方法は、次の各号によります。</p>
                                <p style={ teamsOfUse_body }>（１）出展及びバイヤーの申込は、本会の公式ウェブサイトから手続きをする必要があります。</p>
                                <p style={ teamsOfUse_body }>（２）出展申込の受付は、原則、先着順とします。</p>
                                <p style={ teamsOfUse_body }>（３）出展申込の期限は次表のとおりとします。ただし、申込期限は申込状況により変更となる場合があります。</p>
                                <p style={ teamsOfUse_body }>　　　第１回令和６年８月30日（金）</p>
                                <p style={ teamsOfUse_body }>　　　第２回令和６年９月30日（月）</p>
                                <p style={ teamsOfUse_body }>（４）申込期限後の申込及び参加する開催回の変更は、原則、受付しません。</p>
                                <p style={ teamsOfUse_body }>３　日本公庫は所定の審査を行い、本会の出展者及びバイヤーを決定します。</p>
                                <p style={ teamsOfUse_body }>４　参加者について、次のいずれかに該当すると日本公庫が判断した場合は、出展をお断りします。</p>
                                <p style={ teamsOfUse_body }>（１）本規約の定めに反した場合</p>
                                <p style={ teamsOfUse_body }>（２）出展品が本会に不適切なものである場合</p>
                                <p style={ teamsOfUse_body }>（３）参加者及び出展内容が開催趣旨にそぐわない場合</p>
                                <p style={ teamsOfUse_body }>（４）暴力団員等又は第２条第１項各号のいずれかに該当することが判明した場合</p>
                                <p style={ teamsOfUse_body }>（５）第２条第１項に基づく表明及び確約に関して虚偽の申告をしたことが判明した場合</p>
                                <p style={ teamsOfUse_body }>（６）その他、出展が認められないと日本公庫が判断した場合</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（参加費用）</p>
                                <p style={ teamsOfUse_body }>第４条　本会への参加費用は無料とします。ただし、出展者がバイヤーにサンプル品を提供する費用などの商談に伴い生じる費用等については、出展者及びバイヤー間で協議のうえ、出展者又はバイヤーのいずれか若しくは両者が負担するものとします。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（本会の事務局）</p>
                                <p style={ teamsOfUse_body }>第５条　日本公庫は本会の運営をエグジビションテクノロジーズ株式会社に業務委託し、本会の事務局をエグジビションテクノロジーズ株式会社とします。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（参加者の情報の取扱い）</p>
                                <p style={ teamsOfUse_body }>第６条　日本公庫は、参加者が登録した情報を厳正に管理し、参加者のプライバシー保護に十分注意するとともに、次の各号のいずれかに該当する場合を除き、これを第三者に開示し、又は利用させないものとします。</p>
                                <p style={ teamsOfUse_body }>（１）本会の円滑な運営のために、日本公庫が本会の運営等を目的として業務委託する者（以下「業務委託先」といいます。）又は本会に参加するバイヤーに必要な範囲内で提供する場合</p>
                                <p style={ teamsOfUse_body }>（２）あらかじめ参加者の同意が得られた場合</p>
                                <p style={ teamsOfUse_body }>（３）日本公庫の法的義務を履行するために必要な場合</p>
                                <p style={ teamsOfUse_body }>（４）裁判所、検察庁、警察署その他の司法・行政機関から法令に基づいて開示を求められた場合</p>
                                <p style={ teamsOfUse_body }>（５）個別の参加者を識別できない状態で提供する場合</p>
                                <p style={ teamsOfUse_body }>２　参加者は、日本公庫又は業務委託先が次の各号の目的のために、情報を利用することをあらかじめ承諾するものとします。</p>
                                <p style={ teamsOfUse_body }>（１）本会の実施・運営</p>
                                <p style={ teamsOfUse_body }>（２）アンケートの実施等による調査・研究及び参考情報の提供</p>
                                <p style={ teamsOfUse_body }>（３）その他本会の内容を向上させるために必要な行為</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（参加者の責任及び禁止事項）</p>
                                <p style={ teamsOfUse_body }>第７条　参加者が本会に関し本規約に違反する行為又は不正若しくは違法な行為によって日本公庫及び業務委託先に対して損害を与えた場合、参加者は、日本公庫及び業務委託先に対して、その一切の損害を賠償するものとします。</p>
                                <p style={ teamsOfUse_body }>２　参加者が本会において第三者に対して損害を与えた場合、参加者は、自己の責任と負担をもって解決し、日本公庫及び業務委託先に何らの損害等を被らせないものとします。</p>
                                <p style={ teamsOfUse_body }>３　出展者及びバイヤーは、本会の公式ウェブサイトで使用するパスワードの管理及び使用（管理不十分、使用上の過誤及び第三者による不正使用を含みます。）について責任を負うものとします。</p>
                                <p style={ teamsOfUse_body }>４　出展者及びバイヤーは、本会の公式ウェブサイトで使用するパスワードの盗難又は第三者による不正使用の事実が判明した場合は、直ちに事務局に連絡し、事務局の指示に従うものとします。</p>
                                <p style={ teamsOfUse_body }>５　参加者は、本会への参加に当たって次の各号のいずれかに該当する行為又は該当すると日本公庫が判断する行為をしてはならないものとします。 </p>
                                <p style={ teamsOfUse_body }>（１）本規約及び日本公庫所定の方法により提示された注意事項等に反する行為</p>
                                <p style={ teamsOfUse_body }>（２）公序良俗に反する行為又はそのおそれのある行為</p>
                                <p style={ teamsOfUse_body }>（３）犯罪的行為若しくは犯罪的行為に結びつく行為又はそれらのおそれのある行為</p>
                                <p style={ teamsOfUse_body }>（４）法令に反する行為又はそのおそれのある行為</p>
                                <p style={ teamsOfUse_body }>（５）日本公庫若しくは第三者の知的財産権、名誉権、プライバシー権、その他法令上若しくは契約上の権利を侵害する行為又は侵害するおそれのある行為</p>
                                <p style={ teamsOfUse_body }>（６）日本公庫又は第三者に不利益を与える行為</p>
                                <p style={ teamsOfUse_body }>（７）本会の公式ウェブサイトに虚偽の情報を登録する行為</p>
                                <p style={ teamsOfUse_body }>（８）第三者になりすまし本会に参加する行為</p>
                                <p style={ teamsOfUse_body }>（９）本会に参加する権利の第三者への譲渡、使用許可又は質入れ等の行為</p>
                                <p style={ teamsOfUse_body }>（10）有害なコンピュータプログラム等を送信する行為</p>
                                <p style={ teamsOfUse_body }>（11）故意、過失を問わず、本会の円滑な運営に支障を与える一切の行為</p>
                                <p style={ teamsOfUse_body }>（12）本会の公式ウェブサイトに不正にアクセスし、又は不正なアクセスを試みる行為</p>
                                <p style={ teamsOfUse_body }>（13）第２条第２項各号のいずれかに該当する行為</p>
                                <p style={ teamsOfUse_body }>（14）本会の公式ウェブサイトで使用するパスワードを第三者に使用させる行為又はそれに準じる行為</p>
                                <p style={ teamsOfUse_body }>（15）その他日本公庫が不適当と判断する行為</p>
                                <p style={ teamsOfUse_body }>６　日本公庫は、参加者が前項の行為を行った場合又は行うおそれがあると判断した場合、必要な措置を講じることができるものとします。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（本会の延期又は中止）</p>
                                <p style={ teamsOfUse_body }>第８条　天災・人災等の災害や、不可抗力その他やむを得ない事由により本会の開催が困難と判断された場合、日本公庫は本会の延期又は中止を決定します。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（免責事項）</p>
                                <p style={ teamsOfUse_body }>第９条　日本公庫は、本会の公式ウェブサイト等の有用性、動作、品質等についていかなる保証もしません。</p>
                                <p style={ teamsOfUse_body }>２　日本公庫は、本会における商談機会の有無、商談内容、商談結果等についていかなる保証もしません。</p>
                                <p style={ teamsOfUse_body }>３　日本公庫は、次の各号に掲げる損害又は不利益その他本会の運営に関して参加者に生じた損害又は不利益に対し、一切の責任を負わないものとします。</p>
                                <p style={ teamsOfUse_body }>（１）本会への参加、商談、取引等により参加者に生じた損害又は不利益</p>
                                <p style={ teamsOfUse_body }>（２）参加者が、日本公庫又は第三者に与えた損害又は不利益</p>
                                <p style={ teamsOfUse_body }>（３）本会の延期又は中止により参加者に生じた損害又は不利益</p>
                                <p style={ teamsOfUse_body }>（４）本規約の変更により参加者に生じた損害又は不利益</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（規約の変更）</p>
                                <p style={ teamsOfUse_body }>第10条　日本公庫は、本会を円滑に行うため、本規約の変更を行うことがあります。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（権利の帰属）</p>
                                <p style={ teamsOfUse_body }>第11条　本会に関し日本公庫が有する権利については、日本公庫がこれを行使しない場合が生じたときにおいても、それによって日本公庫が当該権利を放棄するものではなく、当該権利は、なお日本公庫に帰属するものとします。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>（準拠法・合意管轄）</p>
                                <p style={ teamsOfUse_body }>第12条　本規約の成立、効力、履行及び解釈に関しては、日本国法が適用されるものとします。本会に関する訴訟の第一審の専属的合意管轄裁判所は、東京地方裁判所とします。</p>
                                <p style={ teamsOfUse_body }>以上</p>
                            </div>
                            <Grid item xs={12} sx={{mt:5}}>
                                <Button
                                    type="button"
                                    variant="contained" sx={{mr:1}} size= "large"
                                    style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                                    onClick={() => handleClick_entry()}
                                    >
                                    規約に同意する
                                </Button>
                            </Grid>
                        </div>
                    </div>
                </Frame>
            </div>
        </div>
        <Footer />
    </>
    )
}
export default TeamsOfUse