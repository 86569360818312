import Pagination from '@mui/material/Pagination';
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Stack, TextField, FormHelperText, Select } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useAuth } from '../../contexts/AuthContext'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import TitleBar from '../../components/uiParts/TitleBar'
import { useFetch } from '../../api/ApiRequests';
import { cityList } from '../../Store'
import { addDays, format } from 'date-fns'

const BuyerSellerListPage = ({ setFormData, formData }) => {

    const { authInfo, setAuthInfo } = useAuth()
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    const effectRan = useRef(false)

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId
    }

    //-----------------------------------------------
    // API - セラー一覧
    //-----------------------------------------------
    const { data: buyerSellerListData, loading: buyer_seller_list_Loading, fetchData: fetchBuyerSellerListData } = useFetch(getData)
    const params = new URLSearchParams({
        page: currentPage,
        per_page: perPage,
    });

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - 起動時の処理
    //-----------------------------------------------
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchBuyerSellerListData(`/mst/buyer/seller/?${params.toString()}`);
    }, [currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const item = cityList.find(item => item.code == buyerSellerListData?.city);
    const cityName = item?.label || ""

    //===============================================
    // eventHandler
    //===============================================
    const onClickSeller = (id) => {
        navigate(`/buyer/sellers/${id}`)
    }

    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F0F1',
    }

    const homeInnerContainer = {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "780px",
        marginTop: "30px",
        gap: "20px",
        borderRadius: "10px",
        alignItems: "center",
        alignSelf: "center",
    }

    const tableStyle = {
        width: '380px',
        borderCollapse: 'collapse',
    };

    const cellStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
    };

    const buttonContainerStyle = {
        textAlign: 'center',
        padding: '10px',
    };

    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} backPage="/buyer" />
            <div style={{ backgroundColor: '#F0F0F1', }}>
                <TitleBar title='出展者リスト' />
            </div >
            <div style={homeContainer}>
                <div style={homeInnerContainer}>
                    <div style={{ width: "100%", backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start" }}>
                        現在出展されているセラー様一覧です。
                    </div>
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%" }}>
                        <div>
                            {currentPage}/{totalPages == 0 ? 1 : totalPages}
                        </div>
                        {/* <Pagination count={3} variant="outlined" shape="rounded" showFirstButton showLastButton /> */}
                        <Pagination count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </div>
                    <Grid container gap="20px" sx={{ justifyContent: "center" }}>
                        {buyer_seller_list_Loading ? (
                            <p>ロード中</p>
                        ) : buyerSellerListData && buyerSellerListData.seller?.length > 0 ? (
                            buyerSellerListData.seller?.map((seller, index) => (
                                <table style={tableStyle} key={index}>
                                    <tbody>
                                        <tr>
                                            <th style={{ ...cellStyle, textAlign: "center", backgroundColor: "#007D50", color: "white" }}>会社名</th>
                                            <td style={cellStyle}>{seller?.name}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ ...cellStyle, textAlign: "center", backgroundColor: "#D5EDC7" }}>部署</th>
                                            <td style={{ ...cellStyle, backgroundColor: "#F2F2F2" }}>{seller?.busho}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ ...cellStyle, textAlign: "center", backgroundColor: "#D5EDC7" }}>担当者</th>
                                            <td style={cellStyle}>{seller?.tantosha}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ ...cellStyle, textAlign: "center", backgroundColor: "#D5EDC7" }}>住所</th>
                                            <td style={{ ...cellStyle, backgroundColor: "#F2F2F2" }}>{cityName + seller?.address}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" style={{ ...cellStyle, ...buttonContainerStyle }}>
                                                <Button onClick={() => onClickSeller(seller?.id)} variant='contained' sx={{ borderRadius: "99px", padding: "10px 80px" }} >出展者の概要</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table >
                            ))
                        ) : (
                            <p>セラー情報が見つかりませんでした。</p>
                        )}
                    </Grid>
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%" }}>
                        <div>
                            {currentPage}/{totalPages == 0 ? 1 : totalPages}
                        </div>
                        {/* <Pagination count={3} variant="outlined" shape="rounded" showFirstButton showLastButton /> */}
                        <Pagination count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
                <Button
                    onClick={() => navigate(-1)}
                    variant="outlined" sx={{ mr: 1 }} size="large"
                    style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '2rem 0', }}
                >
                    戻&emsp;る
                </Button>
            </div>
            <Footer />
        </>
    )
}
export default BuyerSellerListPage