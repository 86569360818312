
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'
import { Grid, useMediaQuery, useTheme, Button} from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from './../../contexts/AuthContext'
import TitleBar from '../../components/uiParts/TitleBar';

const Policy = () => {

    const { authInfo } = useAuth()
    
    const navigate = useNavigate()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    
	//===============================================
	// initialize
	//===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
    // initialize - セッション情報を取得
	//-----------------------------------------------
    const userId    = authInfo.userId
    const userName  = authInfo.userName
    const authority = authInfo.authority
    
    const effectRan = useRef(false)

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    //===============================================
    // API
    //===============================================
    
  
    //===============================================
    // useEffect
    //===============================================
    

    //===============================================
    // eventHandler
    //===============================================
    const handleClick_entry = () => {
        navigate('/authen')
    }
    
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'center',
    }
    const teamsOfUse_main = {
        border: '1px solid #436629',
        height: 'calc(100vh - 320px)',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding:'2px'
    }
    const teamsOfUse_title = {
        textAlign: 'left',
        color:'black',
        fontWeight:'bold',
    }
    const teamsOfUse_body = {
        textAlign: 'left',
        marginLeft: '1em',
        color:'black',
      whiteSpace: 'pre-line'
    }
	//===============================================
	// JSXのレンダー
	//===============================================
    return (
    <>
        <Header title={ systemName }  loginInfo="off" backButton="off"  />
        <TitleBar title='個人情報の取り扱いについて' />
        <div style={homeContainer}>
            <div style={{ width: '100%', color: 'white', textAlign: 'center', }}>
                <Frame title="個人情報の取り扱いについて">
                    <div className="teamsOfUse">
                        <div style={ teamsOfUse_main }>
                            <div>
                                <p style={ teamsOfUse_body }>エグジビション テクノロジーズ 株式会社は、株式会社 日本政策金融公庫より本展示会運営全般を委託されています。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>1.事業者の氏名又は名称</p>
                                <p style={ teamsOfUse_body }>株式会社 日本政策金融公庫</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_body }>2.個人情報保護管理者の氏名又は職名、所属及び連絡先</p>
                                <p style={ teamsOfUse_body }>管理者職名：個人情報保護管理者</p>
                                <p style={ teamsOfUse_body }>所属部署：株式会社 日本政策金融公庫 農林水産事業本部 情報企画部 顧客サービスグループ 上席 グループリーダー代理</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>3.個人情報の利用目的</p>
                                <p style={ teamsOfUse_body }>お問い合わせ対応（本人への連絡を含む）のため</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>4.個人情報の第三者への提供</p>
                                <p style={ teamsOfUse_body }>取得した個人情報は第三者に提供いたしません。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>5.個人情報取扱いの委託</p>
                                <p style={ teamsOfUse_body }>当社は事業運営上、前項利用目的の範囲に限って個人情報を外部に委託することがあります。この場合、個人情報保護水準の高い委託先を選定し、個人情報の適正管理・機密保持についての契約を交わし、適切な管理を実施させます。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>6.個人情報の開示等の請求</p>
                                <p style={ teamsOfUse_body }>ご本人様は、当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）に関して、下記の当社問合わせ窓口に申し出ることができます。その際、当社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>【お問合せ窓口】</p>
                                <p style={ teamsOfUse_body }>〒107-0062　東京都港区南青山1-1-1　新青山ビル 西館８階</p>
                                <p style={ teamsOfUse_body }>エグジビション テクノロジーズ 株式会社　総務部　個人情報問合せ窓口</p>
                                <p style={ teamsOfUse_body }>TEL：03‐5775‐2855（受付時間　9:30～18:00※）</p>
                                <p style={ teamsOfUse_body }>※土・日曜日、祝日、年末年始、ゴールデンウィーク期間は翌営業日以降の対応とさせていただきます。</p>
                            </div>
                            <div>
                                <p style={ teamsOfUse_title }>7.個人情報を提供されることの任意性について</p>
                                <p style={ teamsOfUse_body }>第８条　天災・人災等の災害や、不可抗力その他やむを得ない事由により本会の開催が困難と判断された場合、日本公庫は本会の延期又は中止を決定します。</p>
                            </div>
                        </div>
                    </div>
                </Frame>
            </div>
        </div>
        <Footer />
    </>
    )
}
export default Policy