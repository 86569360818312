import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import {
    Button,
    FormControl,
    Grid,
    OutlinedInput,
    Stack,
    Select,
    MenuItem,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import CustomOutlinedInput from "../../components/input/CustomOutlinedInput";
import { authData, apiURL } from "../../api/ApiConfig";
import { useFetch, usePost } from "../../api/ApiRequests";
import { useMsal } from "@azure/msal-react";
import Footer from "../../components/Footer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomFormOutlinedInput from "../../components/input/CustomFormOutlinedInput";

const ResetPassword = () => {
    const navigate = useNavigate();
    axios.defaults.baseURL = apiURL;

    const passwordRegExp = /^[a-zA-Z0-9]*$/;

    const schema = yup.object().shape({
        password: yup.string()
            .matches(passwordRegExp, "パスワードは半角英数字のみ使用できます")
            .max(20, "パスワードは20文字以内で入力してください")
            .required("新しいパスワードを入力してください"),
        confirm_password: yup.string()
            .oneOf([yup.ref('password'), null], "パスワードが一致しません")
            .required("確認用のパスワードを入力してください"),
    });

    const useFormMethods = useForm({
        defaultValues: { password: "", confirm_password: "" },
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(schema),
    });
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useFormMethods;

    //-----------------------------------------------
    // Storeデータの取得
    //-----------------------------------------------
    const systemName = process.env.REACT_APP_SYSTEM_NAME;

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost()
    //-----------------------------------------------
    // API - ユーザマスタ
    //-----------------------------------------------
    const { data: changeRequired, fetchData: fetchChangeRequired } = useFetch();

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - ロード時の設定
    //-----------------------------------------------
    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token) {
            fetchChangeRequired(`/mst/buyer/confirm_change_password`);
        }
    }, []);

    useEffect(() => {
        // 変更が必要なければホーム画面へ
        if (changeRequired && !changeRequired?.required_password_change) {
            navigate("/buyer")
        }
    }, [changeRequired]);

    //===============================================
    // eventHandler
    //===============================================
    const onSubmit = async (data) => {
        try {
            await postData("/mst/buyer/change_password/", {
                'password': data.password,
                'confirm_password': data.confirm_password
            })
        } catch (e) {
            alert("パスワードの再設定に失敗しました")
        }
        alert("パスワードを再設定しました")
        navigate("/buyer");
    };
    //-----------------------------------------------
    // ユーザマスタを取得
    //-----------------------------------------------

    const loginFormWrapper = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "10px",
        borderRadius: "6px",
        padding: "20px 10px",
    };

    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo="off" backButton="off" />
            <div style={{ overflow: "hidden", backgroundColor: "#F0F0F1", }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "85vh" }}
                >
                    <Grid item style={{ width: "100%", maxWidth: "500px", backgroundColor: "white", borderRadius: "10px", padding: "10px", margin: "0 10px" }}>
                        <div className="login_div_2">北陸4県応援企画</div>
                        <div className="login_div_2">食のオンライン商談会 2024</div>
                        <div className="login_div_2" style={{ color: "inherit", }}>パスワードの再設定</div>
                        <div style={loginFormWrapper}>
                            <FormProvider {...useFormMethods}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div style={{ display: "inline-block", textAlign: "start" }}>
                                        <div style={{ paddingLeft: "18px", fontWeight: "bold" }}>
                                            新しいパスワードを入力してください <br />
                                            <span style={{ fontSize: "14px" }}>
                                                (※半角英数字 20 文字以内)</span>
                                        </div>
                                        <div>
                                            <CustomFormOutlinedInput
                                                type="password"
                                                id="password"
                                                name="password"
                                                placeHolder="新しいパスワード"
                                                style={{ margin: "0.3rem 1rem", width: "20rem", fontSize: "22px" }}
                                            />
                                        </div>
                                        <div>
                                            <div style={{ paddingLeft: "18px", fontWeight: "bold", marginTop: "16px" }}>
                                                新しいパスワードの確認
                                            </div>
                                            <CustomFormOutlinedInput
                                                type="password"
                                                id="confirm_password"
                                                name="confirm_password"
                                                placeHolder="新しいパスワード"
                                                style={{ margin: "0.3rem 1rem", width: "20rem", fontSize: "22px" }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <FormControl className="formcontrol" variant="outlined">
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                size="large"
                                                style={{
                                                    fontSize: "20px",
                                                    width: "20rem",
                                                    margin: "2rem 0 1rem 0",
                                                    color: "#fff",
                                                    backgroundColor: "#4BBC06",
                                                }}
                                                variant="contained"
                                            >
                                                再設定する
                                            </Button>
                                        </FormControl>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
};
export default ResetPassword;
