import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, useMediaQuery, useTheme, Button, FormLabel } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useAuth } from "../../contexts/AuthContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TitleBar from "../../components/uiParts/TitleBar";
import EntryCustomField from "../../components/entryForm/EntryCustomField";
import { usePost, useFetch } from "../../api/ApiRequests";
import { addDays, format } from "date-fns";
import { itemCategoryList } from "../../Store";
import { apiURL } from "../../api/ApiConfig";
import { cityList } from "../../Store";

const SellerNegotiationPage = ({ setFormData, formData }) => {
  const { authInfo } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const buyerIdMatch = location.pathname.match(/\/seller\/(\d+)\/contact/);
  const buyerId = buyerIdMatch ? buyerIdMatch[1] : null;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!buyerId) {
      navigate("/404", { replace: true });
    }
  }, [buyerId, navigate]);

  const sellerId = authInfo.sellerId;

  const today = format(addDays(new Date(), 0), "yyyy-MM-dd");
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  const userId = authInfo.userId;
  const userName = authInfo.userName;

  const effectRan = useRef(false);

  const schema = yup.object().shape({
    content: yup.string().required("依頼内容を入力してください"),
  });

  const useFormMethods = useForm({
    defaultValues: { content: "" },
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = useFormMethods;

  const { postData } = usePost({
    userId: userId,
    userName: userName,
  });

  const getData = {
    userId: userId,
    userName: userName,
  };

  const { data: sellerData, loading, error, fetchData } = useFetch(getData);

  useEffect(() => {
    if (sellerId && !effectRan.current) {
      const requestURL = `/mst/seller/${sellerId}`;
      fetchData(requestURL);
      effectRan.current = true;
    }
  }, [sellerId, fetchData]);

  const getCityName = (cityCode) => {
    const city = cityList.find((item) => item.code === cityCode);
    return city ? city.label : "未設定";
  };

  const renderCheckFields = (fields, labels) => {
    return fields.map((field, index) => (
      <FormLabel key={index} sx={{ whiteSpace: "nowrap", fontSize: "1.2em" }}>
        {field ? <span style={{ marginLeft: "1em" }}>{labels[index]}</span> : ""}
      </FormLabel>
    ));
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("buyer_id", buyerId);
    formData.append("seller_id", sellerId);
    formData.append("content", data.content);

    console.log("Sending payload:", Object.fromEntries(formData));

    const onSuccess = (response) => {
      console.log("Request successful, response:", response);
      navigate(`/seller/${buyerId}/contact/done`);
    };

    try {
      await postData("/mst/buyer/contact/", formData, onSuccess);
    } catch (error) {
      console.error("Error in submission:", error);
      setErrorMessage(`送信に失敗しました: ${error.message || "不明なエラー"}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const homeContainer = {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "50px",
    backgroundColor: "#F0F0F1",
  };

  const homeInnerContainer = {
    display: "flex",
    flexDirection: "column",
    padding: "5px 32px 30px 32px",
    width: "100%",
    maxWidth: "800px",
    marginTop: "30px",
    borderRadius: "10px",
    backgroundColor: "white",
  };

  const formContainer = {
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 10px",
  };

  const responsiveImage = {
    width: "380px",
  };

  return (
    <>
      <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} />
      <div style={{ backgroundColor: "#F0F0F1" }}>
        <TitleBar title="商談申し込み" />
      </div>
      <div style={homeContainer}>
        <FormProvider {...useFormMethods}>
          <div className="form-container" style={formContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start", width: "100%" }}>
                商談依頼内容を記入して商談申し込むボタンをクリックしてください。 <br />
                バイヤーより連絡をお待ちください。
              </div>
              <div style={homeInnerContainer}>
                <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center" required="on">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <EntryCustomField id="content" name="content" label="商談依頼内容" labelWidth="250px" required="on" placeHolder="弊社は○○○○を出品しています。ぜひ御社とオンライン商談をお願いしたく、○月○日また○月○日に商談をお願いできませんでしょうか。" hideHelperText />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Button onClick={() => navigate(-1)} variant="outlined" sx={{ mr: 1 }} size="large" style={{ color: "#4BBC06", fontSize: "20px", width: "300px", margin: "0.5rem" }}>
                    戻　る
                  </Button>
                  <Button type="submit" variant="contained" sx={{ mr: 1 }} size="large" style={{ color: "#fff", backgroundColor: "#4BBC06", fontSize: "20px", width: "300px", margin: "0.5rem" }} disabled={isSubmitting}>
                    {isSubmitting ? "送信中" : "商談を申し込む"}
                  </Button>
                </Grid>
                {errorMessage && <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>}
              </div>
              <div style={{ backgroundColor: "#D5EDC7", marginTop: "30px", padding: "15px", borderRadius: "10px", textAlign: "start", width: "100%" }}>商談依頼内容とともに、出展者の下記の情報もバイヤーへ送信されます。</div>
              <div style={{ backgroundColor: "white", borderRadius: "10px", paddingBottom: "20px", marginTop: "40px" }}>
                <Grid container direction="column" justifyContent="center" alignItems="center" required="on">
                  <Grid item xs={12}>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>出展希望回</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.exhibit || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>メールアドレス</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.eMail || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>出展者名</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.name || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>出展者名（フリガナ）</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.nameKana || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>部署</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.busho || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>担当者</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.tantosha || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>都道府県</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{getCityName(sellerData?.city) || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>所在地</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.address || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>出展者業種</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        {renderCheckFields([sellerData?.checkField01, sellerData?.checkField02, sellerData?.checkField03, sellerData?.checkField04, sellerData?.checkField05, sellerData?.checkField06, sellerData?.checkField07, sellerData?.checkField08], ["農業", "水産業", "小売業", "食品加工", "製造業", "卸売業", "飲食業", "その他"])}
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>その他（自由記入）</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.textField01 || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>取得認証</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        {renderCheckFields(
                          [sellerData?.checkField09, sellerData?.checkField10, sellerData?.checkField11, sellerData?.checkField12, sellerData?.checkField13, sellerData?.checkField14, sellerData?.checkField15, sellerData?.checkField16, sellerData?.checkField17, sellerData?.checkField18, sellerData?.checkField19, sellerData?.checkField20],
                          ["有機JAS", "JGAP", "ASIAGAP", "Global GAP", "ISO 9001", "ISO 14001", "ISO 22000", "FSSC 22000", "US・HACCP", "EU・HACCP", "取得認証なし", "その他"]
                        )}
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>その他（取得記述）</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.textField02 || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>認定・受賞歴など</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.textField03 || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>ホームページ</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.textField04 || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>X(Twitter)</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.textField05 || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>Instagram</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>{sellerData?.textField06 || "未設定"}</FormLabel>
                      </Grid>
                    </Grid>
                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                      <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                        <FormLabel sx={{ whiteSpace: "nowrap", ml: 1, fontWeight: "bold", fontSize: "1.2em" }}>メイン画像</FormLabel>
                      </Grid>
                      <Grid item xs={isSmallScreen ? 12 : "auto"} sx={{ mt: 1, textAlign: "left" }}>
                        {sellerData?.imageField01 ? <img src={`${apiURL}media/uploads/${sellerData.imageField01}`} alt="メイン画像" style={responsiveImage} /> : <FormLabel sx={{ whiteSpace: "nowrap", fontSize: "1.2em", marginLeft: "1em" }}>未設定</FormLabel>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button onClick={() => navigate(-1)} variant="outlined" sx={{ mr: 1 }} size="large" style={{ color: "#4BBC06", fontSize: "20px", width: "300px", margin: "0.5rem" }}>
                  戻　る
                </Button>
                <Button type="submit" variant="contained" sx={{ mr: 1 }} size="large" style={{ color: "#fff", backgroundColor: "#4BBC06", fontSize: "20px", width: "300px", margin: "0.5rem" }} disabled={isSubmitting}>
                  {isSubmitting ? "送信中" : "商談を申し込む"}
                </Button>
              </Grid>
            </form>
          </div>
        </FormProvider>
      </div>
      <Footer />
    </>
  );
};

export default SellerNegotiationPage;
