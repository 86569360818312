import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, useMediaQuery, useTheme, Button, FormLabel } from '@mui/material'
import { apiURL } from '../../api/ApiConfig';
import { itemCategoryList } from '../../Store'
import { usePost, useFetch } from './../../api/ApiRequests';
import { useAuth } from './../../contexts/AuthContext'
import { cityList } from '../../Store'
import { addDays, format } from 'date-fns'
import PostButton from '../../components/uiParts/PostButton';

const ItemConfirm = ({ formData, setFormData }) => {

    const { authInfo } = useAuth()
    const [mailto, setMailTo] = useState('')
    const navigate = useNavigate()

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const sellerId = query.get('sellerId')

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    const effectRan = useRef(false)

    const item = itemCategoryList.find(item => item.code == formData.itemCategory)
    const itemCategoryName = item.label

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId,
        userName: userName
    }
    //-----------------------------------------------
    // API - セラーマスタ
    //-----------------------------------------------
    const { data: sellerData, loading: seller_Loading, fetchData: fetchSellerData } = useFetch(getData)

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - 起動時の処理
    //-----------------------------------------------
    useEffect(() => {
        // 初回マウント時のみ実行
        if (effectRan.current === false) {
            fetchSellerData(`/mst/seller/${formData.seller}`)
            window.scrollTo(0, 0);
        }
        return () => {
            effectRan.current = true;
        }
    }, [])
    //-----------------------------------------------
    // useEffect - セラーマスタ
    //-----------------------------------------------
    useEffect(() => {
        if (sellerData) {
            setMailTo(sellerData.eMail)
        }
    }, [sellerData])

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName
    })
    //===============================================
    // eventHandler
    //===============================================
    //-----------------------------------------------
    // eventHandler - 登録処理の実行
    //-----------------------------------------------
    const handleConfirm = async () => {
        try {
            const onSuccess = (data) => {
                console.log('Extracted data:', data); // ここで確認のためにログ出力
                navigate('/itemForm?sellerId=' + sellerId)
                //send_mail('/itemForm?sellerId='+sellerId)
            }
            await postData('/mst/item/', formData, onSuccess)
        } catch (error) {
            console.error(error);
        }
    }
    //-----------------------------------------------
    // eventHandler - 登録処理を実行して完了画面に移動する
    //-----------------------------------------------
    const handleClick_complete = async () => {
        try {
            const onSuccess = (data) => {
                console.log('Extracted data:', data); // ここで確認のためにログ出力
                //navigate('/complete')
                send_mail('/complete')
            }
            await postData('/mst/item/', formData, onSuccess);
        } catch (error) {
            console.error(error);
        }
    }
    //-----------------------------------------------
    // eventHandler - フォーム画面に戻る
    //-----------------------------------------------
    const handleClick_entry = () => {
        navigate('/itemForm?sellerId=' + sellerId)
    }
    //-----------------------------------------------
    // eventHandler - 受付メールを送信する
    //-----------------------------------------------
    const send_mail = (nextPage) => {

        const title = '【北陸4県応援企画・食のオンライン商談会2024】出展のお申込ありがとうございます'
        let mailText = `ご担当者様\n\n`
        mailText = mailText + `北陸4県応援企画・食のオンライン商談会2024へのお申込ありがとうございました。\n`
        mailText = mailText + `事務局の審査が完了しましたら、事務局からご連絡いたします。\n\n`
        mailText = mailText + `※送信元のメールアドレスは送信専用となっております。\n`
        mailText = mailText + `返信いただいても、ご回答できませんのであらかじめご了承願います。\n\n`

        const sendData = {
            subject: title,
            message: mailText,
            recipient_list: [mailto],
            cc_list: []
        }
        const submitDataToPost = async () => {
            try {
                await postData('/mst/send_email/', sendData)
            } catch (error) {
                console.error('Error in POST request:', error)
            }
        }
        submitDataToPost()
        // 後処理
        try {
            navigate(nextPage)
        } catch (error) {
            console.error('Error in update function:', error);
        }
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        justifyContent: 'center',
        alignItems: 'left',
        paddingBottom: '250px' // フッターの高さ分だけ余白を追加
    }
    const responsiveImage = {
        width: '380px',
    }
    const formContainer = {
        display: 'flex',
        justifyContent: 'left',
    }
    return (
        <>
            <Header title={systemName} loginInfo="off" backButton="off" />
            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <TitleBar title='商品情報登録フォーム 入力情報の確認' />
            </div>
            <div style={homeContainer}>
                <div style={{ fontSize: '1.5em', margin: '0.5em', fontWeight: 'bold', borderBottom: '1px solid' }}>商品情報</div>
                <div className="form-container" style={formContainer}>
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        required='on'
                    >
                        <Grid item xs={12}>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品名</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{formData.name}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品名（フリガナ）</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{formData.nameKana}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品カテゴリ</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em', marginLeft: '1em' }}>{itemCategoryName}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>キャッチフレーズ</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{formData.textField01}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品説明</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize: '1.2em' }}>{formData.textField02}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品画像</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 1, textAlign: 'left' }}>
                                    <img src={`${apiURL}media/uploads/${formData.imageField01}`} alt="Example" style={responsiveImage} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
                <Grid item xs={12} sx={{ mt: 5, mb: 10 }}>
                    <Button
                        type="button"
                        variant="contained" sx={{ mr: 1 }}
                        size="large"
                        style={{ color: '#fff', backgroundColor: '#ff6347', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                        onClick={handleClick_entry}
                    >
                        入力をやり直す
                    </Button>
                    <Button
                        type="submit"
                        variant="contained" sx={{ mr: 1 }}
                        size="large"
                        style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                        onClick={handleConfirm}
                    >
                        決定して商品を追加する
                    </Button>
                    <PostButton
                        type="submit"
                        variant="contained" sx={{ mr: 1 }}
                        size="large"
                        style={{ color: '#fff', backgroundColor: '#4682b4', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                        onClick={handleClick_complete}
                    >
                        決定して申請を終了する
                    </PostButton>
                </Grid>
            </div>
            <Footer />
        </>
    );
};

export default ItemConfirm;