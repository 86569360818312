import React, { createContext, useState, useContext } from "react";

// コンテキストの作成
const AuthContext = createContext();

const storedIsAuthenticated =
  sessionStorage.getItem("isAuthenticated") || false;
const storedToken = sessionStorage.getItem("token") || null;
const storedUserId = sessionStorage.getItem("userId") || null;
const storedUserName = sessionStorage.getItem("userName") || null;
const storedAuthority = sessionStorage.getItem("authority") || null;

// プロバイダーコンポーネントの作成
export const AuthProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState({
    isAuthenticated: storedIsAuthenticated,
    token: storedToken,
    userId: parseInt(storedUserId),
    sellerId: parseInt(sessionStorage.getItem("sellerId") || null) || undefined,
    buyerId: parseInt(sessionStorage.getItem("buyerId") || null) || undefined,
    userName: storedUserName,
    authority: storedAuthority,
  });

  return (
    <AuthContext.Provider value={{ authInfo, setAuthInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

// カスタムフックの作成
export const useAuth = () => {
  return useContext(AuthContext);
};
