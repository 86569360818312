import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, useMediaQuery, useTheme, Button } from "@mui/material";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Frame from "../components/uiParts/Frame";
import { useAuth } from "./../contexts/AuthContext";
import { useFetch } from "./../api/ApiRequests";
import MenuIcon from "../components/uiParts/MenuIcon";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DirectionsIcon from "@mui/icons-material/Directions";
import TitleBar from "../components/uiParts/TitleBar";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

const Home = () => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();

  const effectRan = useRef(false);

  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const title = `ログインユーザ：${userName}`;

  // console.log(authInfo)

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userId: userId,
    userName: userName,
  };
  //-----------------------------------------------
  // API - 発注・納品テーブル
  //-----------------------------------------------

  //===============================================
  // useEffect
  //===============================================
  //-----------------------------------------------
  // useEffect - ロード時の設定
  //-----------------------------------------------

  //-----------------------------------------------
  // useEffect - 部署マスタ
  //-----------------------------------------------

  //===============================================
  // eventHandler
  //===============================================
  const handleClick_sellerList = () => {
    navigate("/sellerList");
  };
  const handleClick_buyerList = () => {
    navigate("/buyerList");
  };
  const handleClick_login = () => {
    navigate("/login");
  };
	const handleClick_userList = () => {
		navigate("/userList");
	};
  const handleClick_contact = () => {
    window.location.href = "mailto:m-kayama@tsproducts.jp";
  };
  const handleClickMeetings = () => {
    navigate("/meetings");
  };

  //===============================================
  // CSS
  //===============================================
  const homeContainer = {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingBottom: "150px", // フッターの高さ分だけ余白を追加
  };
  //===============================================
  // JSXのレンダー
  //===============================================
  return (
    <>
      <Header title={systemName} loginInfo="on" backButton="off" />
      <div style={homeContainer}>
        <div>
          <TitleBar title={title} />
        </div>
        <div style={{ width: "100%", color: "white", textAlign: "center" }}>
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <Grid
              container
              direction={isSmallScreen ? "column" : "row"}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <MenuIcon
                  header="Entry Menu"
                  title="セラーリスト"
                  onClick={() => handleClick_sellerList()}
                >
                  <PersonIcon style={{ fontSize: "4rem", color: "#007D50" }} />
                </MenuIcon>
                <MenuIcon
                  header="Entry Menu"
                  title="バイヤーリスト"
                  onClick={() => handleClick_buyerList()}
                >
                  <PersonOutlineIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
                <MenuIcon
                  header="Management Menu"
                  title="ユーザリスト"
                  onClick={() => handleClick_userList()}
                >
                  <AssignmentIndIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
                <MenuIcon
                  header="Management Menu"
                  title="商談状況"
                  onClick={() => handleClickMeetings()}
                >
                  <LocalMallIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
                <MenuIcon
                  header="Management Menu"
                  title="レビュー状況"
                >
                  <QuestionAnswerIcon
                    style={{ fontSize: "4rem", color: "#007D50" }}
                  />
                </MenuIcon>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};
export default Home;
