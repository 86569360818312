import { useState, useEffect } from 'react'
import { useNavigate, useLocation  } from 'react-router-dom'
import axios from "axios"
import { useAuth } from './../contexts/AuthContext'
import { authData, apiURL } from '../api/ApiConfig'

const Authen = () => {
    
    const { authInfo,       setAuthInfo       } = useAuth()
    const [ isAuthen,       setIsAuthen       ] = useState(false)
    const [ isStartSession, setIsStartSession ] = useState(false)
    const navigate = useNavigate()
    axios.defaults.baseURL = apiURL

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const target = query.get('target')
  
	//-----------------------------------------------
	// 認証情報を取得
	//-----------------------------------------------
    useEffect(() => {
        axios.post('/api/token/', {"email":authData.userEmail,"password":authData.password}).then(function (response) {
            
            sessionStorage.setItem('isAuthenticated', true)
            sessionStorage.setItem('token', response.data.access)
            sessionStorage.setItem('loginDateTime', new Date())
            sessionStorage.setItem('userId', authData.username)
            setIsAuthen(true)
        
        }).catch((error) => {console.log(error.response.data)})
    },[])
      
	//-----------------------------------------------
	// 認証情報をコンテキストに書き込み
	//-----------------------------------------------
    useEffect(() => {
        const token = sessionStorage.getItem('token')
        if (token) {
            setAuthInfo({
                token  : sessionStorage.getItem('token'),
                userId : sessionStorage.getItem('userId'),
            })
            setIsStartSession(true)
        }
    },[isAuthen])

	//-----------------------------------------------
	// ログイン画面にリダイレクト
	//-----------------------------------------------
    useEffect(() => {
        const token = sessionStorage.getItem('token')
        if (token) {
            if (target === 'seller') {
                navigate(`/mainForm`)
            } else {
                navigate(`/buyerForm`)
            }
        }
    },[isStartSession])
    
    return (<></>)
}
export default Authen