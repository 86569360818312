
/** API接続のベースURL **/
export const apiURL = process.env.REACT_APP_API_URL

export const authData = {
  userId:1,
  userEmail:"m-kayama@tsproducts.jp",
  username:"ts",
  password:"oshienai"
}
