import * as React from 'react'
import { Box, Stack, Link } from '@mui/material'

const Footer = () => {
    return (
      <Box component="footer" sx={{
				width: '100%',
				backgroundColor: '#fff',
				color: '#000',
				p: 4}}>
				<Stack direction={{sx: "column", sm:"row"}} spacing={{sx: 4, sm: 4}} justifyContent="center" alignItems="center">
					<Box>
						<Link href="https://www.jfc.go.jp/" sx={{display: "block"}}>
              <img src={`${process.env.PUBLIC_URL}/images/siteHeader_logo_L_pc.png`} style={{height:'40px'}} alt="日本政策金融公庫" />
						</Link>
					</Box>
					<Box>
						<a href="https://www.jfc.go.jp/n/privacy/index.html">プライバシーポリシー</a>
					</Box>
				</Stack>
      </Box>
    );
}

export default Footer;