import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  FormLabel,
} from "@mui/material";
import { useFetch } from "../../api/ApiRequests";
import { apiURL } from "../../api/ApiConfig";
import { cityList } from "../../Store";
import { usePost } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";

const BuyerProfilePage = ({ formData, setformData }) => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;

  const effectRan = useRef(false);

  const title = `バイヤーユーザ：${userName}`;

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userId: userId,
  };

  //-----------------------------------------------
  // API - バイヤー詳細
  //-----------------------------------------------
  const {
    data: BuyerProfile,
    loading: buyer_Loading,
    fetchData: fetchBuyerData,
  } = useFetch(getData);

  //===============================================
  // useEffect
  //===============================================
  //-----------------------------------------------
  // useEffect - 起動時の処理
  //-----------------------------------------------
  useEffect(() => {
    // 初回マウント時のみ実行
    window.scrollTo(0, 0);

    if (authInfo.buyerId) fetchBuyerData(`/mst/buyer/${authInfo.buyerId}`);
    return () => {
      effectRan.current = true;
    };
  }, []);

  const item = cityList.find((item) => item.code == BuyerProfile?.city);
  const cityName = item?.label || "";

  //===============================================
  // CSS
  //===============================================
  const container = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };
  const profileContainer = {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F0F0F1",
    padding: "0 10px",
  };
  const profileInnerContainer = {
    flexDirection: "column",
    width: "100%",
    maxWidth: "800px",
  };
  return (
    <div style={container}>
      <Header
        title={systemName}
        loginInfo={isSmallScreen ? "off" : "on"}
        backButton={isSmallScreen ? "off" : "on"}
        backPage="/buyer"
      />
      <div style={{}}>
        <TitleBar title={title} />
      </div>
      <div style={profileContainer}>
        <div style={profileInnerContainer}>
          <div
            style={{
              fontSize: "1.5em",
              margin: "0.5em",
              fontWeight: "bold",
              borderBottom: "1px solid",
            }}
          >
            基本情報
          </div>
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            required="on"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    メールアドレス
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.eMail}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    会社名
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.name}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    出展者名（フリガナ）
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.nameKana}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    部署
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.busho}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    担当者
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.tantosha}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    住所
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {cityName + BuyerProfile?.address}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    電話番号
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.tel}
                  </FormLabel>
                </Grid>
              </Grid>
              <Grid
                container
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                mt={1}
              >
                <Grid item sx={{ width: "200px", textAlign: "left", mt: 1 }}>
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: "1.2em",
                    }}
                  >
                    携帯番号
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={isSmallScreen ? 12 : "auto"}
                  sx={{ mt: 1, textAlign: "left" }}
                >
                  <FormLabel
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: "1.2em",
                      marginLeft: "1em",
                    }}
                  >
                    {BuyerProfile?.mobile}
                  </FormLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Button
          onClick={() => navigate(-1)}
          variant="outlined" sx={{ mr: 1 }} size="large"
          style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '1rem', }}
        >
          戻&emsp;る
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default BuyerProfilePage;