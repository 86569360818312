import React from 'react'

const MenuIcon = ({ header, title, onClick, children }) => {
    return (
        <div
            style={{
                    float           : 'left', 
                    width           : '350px', 
                    cursor          : 'pointer', 
                    border          : '1px solid #007D50', 
                    backgroundColor : '#fff', 
                    margin          : '0.5em', 
                    padding         : '15px', 
                    borderRadius    : '10px'
                }}
            onClick={onClick}>
            {
                header ?
                <div style={{ color:'#007D50', fontSize:'14px' }}>{ header }</div>
                :
                <></>
            }
                {children}
            <div style={{ fontWeight:'bold', color:'#007D50', fontSize:'18px' }}>{ title }</div>
        </div>
    )
  }
  
  export default MenuIcon
