import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, Box, useMediaQuery, useTheme, Button,} from '@mui/material'
import { useAuth } from './../../contexts/AuthContext'

const Complete = () => {
    const { authInfo,    setAuthInfo    } = useAuth()
    const navigate = useNavigate()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //===============================================
    // useEffect
    //===============================================
	//-----------------------------------------------
    // useEffect - 起動時の処理
	//-----------------------------------------------
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    //===============================================
    // eventHandler
    //===============================================
	//-----------------------------------------------
    // eventHandler - ホーム画面に戻る
	//-----------------------------------------------
    const handleClick_home = () => {
        navigate('/')
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'left',
    }
    return (
        <>
            <Header title={ systemName }  loginInfo="off" backButton="off"  />
            <div style={{paddingLeft:'10px', paddingRight:'10px'}}>
                <TitleBar title='申込完了' />
            </div>
            <div style={homeContainer}>
                <div style={{fontSize:'1.5em', margin:'0.5em', fontWeight:'bold'}}>
                    <div>お申込みありがとうございました。</div>
                    <div>事務局の審査が完了しましたら、事務局からご連絡いたします。</div>
                    <div>審査完了までの登録情報の修正等は事務局へお問い合わせください。</div>
                </div>
                <div style={{fontWeight:'bold', marginTop: '2em'}}>
                    <div>【お問合せ窓口】</div>
                    <div>「北陸4県応援企画 食のオンライン商談会」事務局／エグジビション テクノロジーズ 株式会社</div>
                    <div>〒107-0062　東京都港区南青山1-1-1　新青山ビル 西館８階</div>
                    <div>TEL：<a href="tel:0357752855">03-5775-2855</a>　MAIL:<a href="mailto:support.hokuriku@exhibitiontech.com">support.hokuriku@exhibitiontech.com</a></div>
                </div>
            </div>
            <Grid item xs={12} sx={{mt:5, mb: 10}}>
                <Button
                type="submit"
                    variant="contained" sx={{mr:1}}
                                size      = "large"
                    style={{color:'#fff', backgroundColor:'#4682b4', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                    onClick={handleClick_home}
                    >
                    ホームへ戻る
                </Button>
            </Grid>
            <Footer />
        </>
  );
};

export default Complete;