import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import PostButton from '../../components/uiParts/PostButton';
import { Grid, Box, useMediaQuery, useTheme, Button, FormLabel, Stack, TextField, FormHelperText, FormControl, FormControlLabel, Checkbox } from '@mui/material'
import { useFetch } from './../../api/ApiRequests';
import { apiURL } from '../../api/ApiConfig';
import { cityList } from '../../Store'
import { usePost } from './../../api/ApiRequests';
import { useAuth } from './../../contexts/AuthContext'

const MainConfirm = ({ formData, setFormData }) => {
    const { authInfo,    setAuthInfo    } = useAuth()
    const navigate = useNavigate()

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
    // initialize - セッション情報を取得
	//-----------------------------------------------
    const userId    = authInfo.userId
    const userName  = authInfo.userName
    const authority = authInfo.authority
    
    const effectRan = useRef(false)
    
    const item = cityList.find(item => item.code == formData.city);
    const cityName = item?.label|| ""

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    //===============================================
    // API
    //===============================================
	//-----------------------------------------------
    // API - POSTメソッドのテンプレート
	//-----------------------------------------------
    const { postData } = usePost({
        userId   : userId,
        userName : userName
    })
    //===============================================
    // eventHandler
    //===============================================
    const handleConfirm = async () => {
        try {
            const onSuccess = (data) => {
                console.log('Extracted data:', data); // ここで確認のためにログ出力
                setFormData({sellerId: data.id})
                //navigate('/itemForm')
                //send_mail(data.id)
                navigate('/itemForm?sellerId=' + data.id)
            }
            await postData('/mst/seller/', formData, onSuccess);
        } catch (error) {
            console.error(error);
        }
    };
    const handleClick_entry = () => {
        navigate('/mainForm')
    }
	//-----------------------------------------------
    // eventHandler - 受付メールを送信する
	//-----------------------------------------------
    const send_mail = (sellerId) => {

        const title = '【北陸4県応援企画・食のオンライン商談会2024】出展のお申込ありがとうございます'
        let mailText = `ご担当者様\n\n`
        mailText = mailText + `北陸4県応援企画・食のオンライン商談会2024へのお申込ありがとうございました。\n`
        mailText = mailText + `事務局の審査が完了しましたら、事務局からご連絡いたします。\n\n`
        mailText = mailText + `※送信元のメールアドレスは送信専用となっております。\n`
        mailText = mailText + `返信いただいても、ご回答できませんのであらかじめご了承願います。\n\n`
        
        const sendData = {
            subject        : title,
            message        : mailText,
            recipient_list : [formData.eMail],
            cc_list        : []
        }
        const submitDataToPost = async () => {
            try {
                await postData('/mst/send_email/', sendData)
            } catch (error) {
                console.error('Error in POST request:', error)
            }
        }
        submitDataToPost()
        // 後処理
        try {
            navigate('/itemForm?sellerId=' + sellerId)
        } catch (error) {
            console.error('Error in update function:', error);
        }
    }
    
    //===============================================
    // CSS
    //===============================================
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'left',
        paddingBottom: '250px' // フッターの高さ分だけ余白を追加
    }
    const responsiveImage = {
        width: '380px',
    }
    const formContainer = {
        display: 'flex',
        justifyContent: 'left',
    }
    return (
        <>
            <Header title={ systemName }  loginInfo="off" backButton="off"  />
            <TitleBar title='出展者登録フォーム 入力情報の確認' backgroundColor='white' />
            <div style={homeContainer}>
                <div style={{fontSize:'1.5em', margin:'0.5em', fontWeight:'bold', borderBottom:'1px solid'}}>基本情報</div>
                <div className="form-container" style={formContainer}>
                    <Grid 
                        container 
                        direction="column" 
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        required='on'
                    >
                        <Grid item xs={12}>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>出展希望回</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.exhibit}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>メールアドレス</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.eMail}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>出展者名</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.name}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>出展者名（フリガナ）</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.nameKana}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>電話番号</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.tel}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>部署</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.busho}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>担当者</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.tantosha}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>都道府県</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{cityName}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>所在地</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.address}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>出展者業種</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                    {formData.checkField01 ? <span style={{marginLeft:'1em'}}>農業</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField02 ? <span style={{marginLeft:'1em'}}>水産業</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField03 ? <span style={{marginLeft:'1em'}}>小売業</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField04 ? <span style={{marginLeft:'1em'}}>食品加工</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField05 ? <span style={{marginLeft:'1em'}}>製造業</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField06 ? <span style={{marginLeft:'1em'}}>卸売業</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField07 ? <span style={{marginLeft:'1em'}}>飲食業</span> : ''}
                                    </FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>
                                        {formData.checkField08 ? <span style={{marginLeft:'1em'}}>その他</span> : ''}
                                    </FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>その他（自由記入）</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.textField01}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>取得認証</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField09 ? <span style={{marginLeft:'1em'}}>有機JAS</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField10 ? <span style={{marginLeft:'1em'}}>JGAP</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField11 ? <span style={{marginLeft:'1em'}}>ASIAGAP</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField12 ? <span style={{marginLeft:'1em'}}>lobal GAP</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField13 ? <span style={{marginLeft:'1em'}}>ISO 9001</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField14 ? <span style={{marginLeft:'1em'}}>ISO 14001</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField15 ? <span style={{marginLeft:'1em'}}>ISO 22000</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField16 ? <span style={{marginLeft:'1em'}}>FSSC 22000</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField17 ? <span style={{marginLeft:'1em'}}>US・HACCP</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField18 ? <span style={{marginLeft:'1em'}}>EU・HACCP</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField19 ? <span style={{marginLeft:'1em'}}>取得認証なし</span> : ''}</FormLabel>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em' }}>{formData.checkField20 ? <span style={{marginLeft:'1em'}}>その他</span> : ''}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>その他（取得記述）</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.textField02}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>認定・受賞歴など</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.textField03}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>ホームページ</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.textField04}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>X(Twitter)</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.textField05}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>Instagram</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', fontSize:'1.2em', marginLeft:'1em' }}>{formData.textField06}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                <Grid item sx={{width:'200px', textAlign:'left', mt:1}}>
                                    <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold', fontSize:'1.2em' }}>メイン画像</FormLabel>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12 : 'auto'}sx={{mt:1, textAlign:'left'}}>
                                <img src={`${apiURL}media/uploads/${formData.imageField01}`} alt="Example" style={responsiveImage} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>
                    <Grid item xs={12} sx={{mt:5}}>
                        <Button
                            type="button"
                            variant="contained" sx={{mr:1}}
                                        size      = "large"
                            style={{color:'#fff', backgroundColor:'#ff6347', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                            onClick={handleClick_entry}
                            >
                            入力をやり直す
                        </Button>
                        <PostButton
                        type="submit"
                            variant="contained" sx={{mr:1}}
                                        size      = "large"
                            style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                            onClick={handleConfirm}
                            >
                            決定して商品登録へ進む
                        </PostButton>
                    </Grid>
            </div>
            <Footer />
        </>
  );
};

export default MainConfirm;