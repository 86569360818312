
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid, Box, useMediaQuery, useTheme, Button } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Frame from '../../components/uiParts/Frame'
import MenuIcon from '../../components/uiParts/MenuIcon'
import InfoIcon from '@mui/icons-material/Info'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import DirectionsIcon from '@mui/icons-material/Directions'
import HelpIcon from '@mui/icons-material/Help'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'


const SiteTop = () => {

    const navigate = useNavigate()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    console.log(isSmallScreen)
    
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const information = [
        {id:'2', title:'申し込みについて', entryDate:'2024-08-01', text:'セラーの出展申込は「出展申し込み」ボタンから、バイヤーの参加申込は「バイヤー参加申し込み」ボタンからお手続きをお願いします。\n事務局の審査完了後にあらためてご連絡させていただきます。'},
        {id:'1', title:'イベント開催', entryDate:'2024-08-01', text:'「北陸4県応援企画・食のオンライン商談会2024」のサイトを開設しました。\n詳細は開催概要をご確認ください。'},
    ]
  
    //===============================================
    // initialize
    //===============================================
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //===============================================
    // eventHandler
    //===============================================
	//-----------------------------------------------
    // eventHandler - 参加規約に遷移
	//-----------------------------------------------
    const handleClick_entry = () => {
        navigate('/teamsOfUse?target=seller')
    }
	//-----------------------------------------------
    // eventHandler - 参加規約に遷移(バイヤー)
	//-----------------------------------------------
    const handleClick_entry_buyer = () => {
        navigate('/teamsOfUse?target=buyer')
    }
	//-----------------------------------------------
    // eventHandler - ログイン画面に繊維
	//-----------------------------------------------
    const handleClick_login = () => {
        navigate('/login')
    }
	//-----------------------------------------------
    // eventHandler - お問い合わせメーラーを起動
	//-----------------------------------------------
    const handleClick_contact = () => {
        window.location.href = 'mailto:web-support@jfc-hokuriku4-online.com';
    }
	//-----------------------------------------------
    // eventHandler - 概要PDFを表示
	//-----------------------------------------------
    const handleClick_gaiyo = () => {
        window.open(`${process.env.PUBLIC_URL}/images/gaiyo.pdf`, '_blank')
    }
	//-----------------------------------------------
    // eventHandler - ガイドPDFを表示
	//-----------------------------------------------
    const handleClick_guide = () => {
        window.open(`${process.env.PUBLIC_URL}/images/guide.pdf`, '_blank')
    }
	//-----------------------------------------------
    // eventHandler - 写真の撮り方ガイドPDFを表示
	//-----------------------------------------------
    const handleClick_photo = () => {
        window.open(`${process.env.PUBLIC_URL}/images/photo.pdf`, '_blank')
    }
    //===============================================
    // CSS
    //===============================================
    const notification_main = {
        border: '0px solid #436629',
        height: '150px',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding:'2px'
    }
    const notification_title = {
        textAlign: 'left',
        color:'black',
        fontWeight:'bold',
        textDecoration: 'underline'
    }
    const notification_body = {
        textAlign: 'left',
        marginLeft: '1em',
        color:'black',
      whiteSpace: 'pre-line'
    }
    const homeContainer =  {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        paddingBottom: '150px' // フッターの高さ分だけ余白を追加
    }
    const imageContainer = {
        position: 'relative',
        width: '100%',
        overflow: 'hidden' // 画像がコンテナからはみ出さないようにする
    };
    const responsiveImage = {
        width: '100%',
        objectFit: 'cover', // アスペクト比を保持しつつ、コンテナを埋める
        objectPosition: 'center' // 中央部分を表示
    }
	//===============================================
	// JSXのレンダー
	//===============================================
    return (
    <>
      <Header title={ systemName }  loginInfo="off" backButton="off"  />
            <div style={homeContainer}>
                <div style={imageContainer}>
                    {
                        isSmallScreen ?
                        <img src={`${process.env.PUBLIC_URL}/images/main_mb.png`} alt="Example" style={responsiveImage} />
                        :
                        <img src={`${process.env.PUBLIC_URL}/images/main.png`} alt="Example" style={responsiveImage} />
                    }
                </div>
                <div style={{paddingTop:'1em', paddingBottom:'1em'}}>
                    <div style={{color:'black', textAlign:'center', paddingLeft:'1rem', color:'#007D50', fontWeight:'bold', fontSize:'1.3em'}}>
                    北陸の魅力ある飲食料品の販路拡大を
                    </div>
                    <div style={{color:'black', textAlign:'center', paddingLeft:'1rem', color:'#007D50', fontWeight:'bold', fontSize:'1.3em'}}>
                    オンラインでの展示商談会によって応援します。
                    </div>
                </div>
                <div style={{ width: '100%', color: 'white', textAlign: 'center', }}>
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                    >
                        <Grid 
                            container 
                            direction={isSmallScreen ? "column" : "row"} 
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button
                                    variant="contained" sx={{mr:1}}
                                                size      = "large"
                                    style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                                    onClick={() => handleClick_entry()}
                                    >
                                    出展申し込み
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained" sx={{mr:1}}
                                                size      = "large"
                                    style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                                    onClick={() => handleClick_entry_buyer()}
                                    >
                                    バイヤー参加申し込み
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained" sx={{mr:1}}
                                                size      = "large"
                                    style={{color:'#fff', backgroundColor:'#4BBC06', fontSize: '20px', width: '300px',margin: '0.5rem'}}
                                    onClick={() => handleClick_login()}
                                    >
                                    ログイン
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <div style={{color:'black', paddingBottom: '1em'}}>当サイトは、暗号化通信により保護されています。</div>
                    <Frame title="お知らせ">
                        <div className="notification">
                            <div style={ notification_main }>
                                <div>
                                    {information.map((item) => {
                                        return (
                                            <div key={item.id}>
                                                <p style={ notification_title }>{item.entryDate}    【{item.title}】</p>
                                                <p style={ notification_body }>{item.text}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Frame>
                    <Grid 
                        container 
                        direction={isSmallScreen ? "column" : "row"} 
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                    >
                        <MenuIcon title="開催概要"  onClick={() => handleClick_gaiyo()}>
                            <InfoIcon style={{fontSize: '4rem', color: '#007D50'}} />
                        </MenuIcon>
                        <MenuIcon title="お問い合わせ"  onClick={() => handleClick_contact()}>
                            <HelpIcon style={{fontSize: '4rem', color: '#007D50'}} />
                        </MenuIcon>
                        <MenuIcon title="出展までの流れ"  onClick={() => handleClick_guide()}>
                            <DirectionsIcon style={{fontSize: '4rem', color: '#007D50'}} />
                        </MenuIcon>
                        <MenuIcon title="商品写真の撮り方"  onClick={() => handleClick_photo()}>
                            <PhotoCameraIcon style={{fontSize: '4rem', color: '#007D50'}} />
                        </MenuIcon>
                    </Grid>
                </div>
            </div>
      <Footer />
      
    </>
    )
}
export default SiteTop