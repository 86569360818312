import Pagination from '@mui/material/Pagination';


import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Stack, TextField, FormHelperText, Select, Checkbox, Link } from '@mui/material'
import { Link as RouterLink } from "react-router-dom";
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useAuth } from '../../contexts/AuthContext'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import TitleBar from '../../components/uiParts/TitleBar'
import { usePost } from '../../api/ApiRequests'
import { addDays, format } from 'date-fns'
import { itemCategoryList, cityList } from '../../Store'
import { apiURL } from "../../api/ApiConfig";
import { useFetch } from '../../api/ApiRequests';

import KebabDiningIcon from '@mui/icons-material/KebabDining';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SpaIcon from '@mui/icons-material/Spa';
import LiquorIcon from '@mui/icons-material/Liquor';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import CakeIcon from '@mui/icons-material/Cake';
import RestaurantIcon from '@mui/icons-material/Restaurant';

const BuyerItemListPage = ({ setFormData, formData }) => {

    const { authInfo, setAuthInfo } = useAuth()

    const navigate = useNavigate()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const sellerId = query.get('sellerId')
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsData, setItemsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);

    const effectRan = useRef(false);

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId
    }

    //-----------------------------------------------
    // API - 商品一覧
    //-----------------------------------------------
    const { data: itemsListData, loading: items_list_Loading, fetchData: fetchItemsListData } = useFetch(getData);

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        const params = new URLSearchParams({
            category: selectedCategory,
            city: selectedCity,
            page: currentPage,
            per_page: perPage
        });
        fetchItemsListData(`/mst/item/?${params.toString()}`)
            .then(response => {
                if (response) {
                    setItemsData(response.item || []);
                    setTotalPages(Math.ceil(response.max_count / perPage));
                } else {
                    setItemsData([]);
                }
            })
            .catch(error => {
                console.error('データの取得に失敗しました:', error);
                setItemsData([]);
            });
    }, [currentPage]);

    //===============================================
    // eventHandler
    //===============================================
    //-----------------------------------------------
    // 検索ボタンを押したときの処理
    //-----------------------------------------------
    const handleSearch = () => {
        // フィルタ条件に基づいてデータをフィルタリング
        const filtered = itemsListData.filter(item => {
            const categoryMatch = selectedCategory.length === 0 || selectedCategory.includes(item.itemCategory);
            const cityMatch = selectedCity.length === 0 || selectedCity.includes(item.seller);
            const keywordMatch = !searchKeyword || item.name.includes(searchKeyword) || item.textField02.includes(searchKeyword);
            return categoryMatch && cityMatch && keywordMatch;
        });

        setFilteredData(filtered);
        setTotalPages(Math.ceil(filtered.length / perPage));
        setIsSearchTriggered(true);
    };

    //-----------------------------------------------
    // ページ変更時の処理
    //-----------------------------------------------
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    //-----------------------------------------------
    // カテゴリ選択の処理
    //-----------------------------------------------
    const handleCategoryChange = (category) => {
        setSelectedCategory(prevSelected => {
            if (prevSelected.includes(category)) {
                // 既に選択されている場合は、配列から削除
                return prevSelected.filter(c => c !== category);
            } else {
                // 選択されていない場合は、配列に追加
                return [...prevSelected, category];
            }
        });
    };

    //-----------------------------------------------
    // 都道府県選択の処理
    //-----------------------------------------------
    const handleCityChange = (city) => {
        setSelectedCity(prevSelected => {
            if (prevSelected.includes(city)) {
                // 既に選択されている場合は、配列から削除
                return prevSelected.filter(c => c !== city);
            } else {
                // 選択されていない場合は、配列に追加
                return [...prevSelected, city];
            }
        });
    };

    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F0F1',
    }

    const homeInnerContainer = {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "780px",
        marginTop: "30px",
        gap: "8px",
        borderRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 10px",
    }

    const tableStyle = {
        width: '380px',
        borderCollapse: 'collapse',
    };

    const cellStyle = {
        border: '1px solid #909090',
        padding: '8px',
        textAlign: "start",
        fontWeight: "normal",
    };

    const buttonContainerStyle = {
        textAlign: 'center',
        padding: '10px',
    };

    const categorySearch = {
        width: "100%",
        fontSize: '1.5em',
        fontWeight: 'bold',
        color: "#007D50",
        borderBottom: '1px solid',
    }

    const checkboxCard = {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
        borderRadius: "4px",
        padding: "4px",
        fontWeight: "bold",
        backgroundColor: "#D5EDC7"
    }

    console.log(filteredData)

    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} backPage="/buyer" />
            <div style={{ backgroundColor: '#F0F0F1' }}>
                <TitleBar title='検索' />
            </div >
            <div style={homeContainer}>
                <div style={homeInnerContainer}>
                    <div style={categorySearch}>商品で検索</div>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[0].code)} />
                                肉
                                <KebabDiningIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[1].code)} />
                                魚介
                                <SetMealIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[2].code)} />
                                青果
                                <SpaIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[3].code)} />
                                酒類
                                <LiquorIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[4].code)} />
                                飲料
                                <EmojiFoodBeverageIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[5].code)} />
                                菓子
                                <CakeIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[6].code)} />
                                加工品
                                <CakeIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCategoryChange(itemCategoryList[7].code)} />
                                その他
                                <RestaurantIcon fontSize="large" sx={{ color: '#007D50', marginLeft: "6px", }} />
                            </div>
                        </Grid>
                    </Grid>
                    <div style={categorySearch}>北陸4県で検索</div>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCityChange(cityList[0].code)} />
                                新潟県
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCityChange(cityList[1].code)} />
                                富山県
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCityChange(cityList[2].code)} />
                                石川県
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div style={checkboxCard}>
                                <Checkbox onChange={() => handleCityChange(cityList[3].code)} />
                                福井県
                            </div>
                        </Grid>
                    </Grid>
                    <div style={categorySearch}>フリーワード検索</div>
                    <TextField
                        size='small'
                        placeholder='お探しのキーワードを入力してください。'
                        sx={{ width: "100%" }}
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                    ></TextField>
                    <Button variant='contained' sx={{
                        fontSize: "24px",
                        borderRadius: "5px",
                        padding: "5px 40px",
                        marginTop: "20px",
                        marginBottom: "30px",
                        backgroundColor: "#4BBC06"
                    }} onClick={handleSearch}>検索</Button>

                    {isSearchTriggered && (
                        <>
                            <div style={{ fontSize: "28px", color: '#007D50', fontWeight: "bold", lineHeight: "1em" }}>
                                検索結果
                            </div>
                            <div style={{ borderBottom: "1px solid #007D50", width: "100%", paddingBottom: "8px" }}>
                                検索ヒット {filteredData ? filteredData.length : 0} 件
                            </div>
                            <div style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "10px",
                                width: "100%"
                            }}>

                            </div>
                            <Grid container sx={{ justifyContent: "center" }}>
                                {items_list_Loading ? (
                                    <p>ロード中</p>
                                ) : itemsListData && filteredData.length > 0 ? (
                                    filteredData.map((item, index) => (
                                        <Grid item xs={12} sm={2.85} style={{
                                            border: "1px solid #868686",
                                            backgroundColor: "white",
                                            margin: "4px",
                                            boxSizing: "border-box",
                                        }}>
                                            <Link component={RouterLink} to={`/buyer/items/${item?.id}`} style={{ color: "black", textDecoration: 'none' }}>
                                                <img src={`${apiURL}media/uploads/${item.imageField01}`} width="100%" alt="Item Image" />
                                                <div style={{ textAlign: "left", padding: "0 8px 8px 8px" }}>{item?.name}</div>
                                            </Link>
                                        </Grid>
                                    ))
                                ) : (
                                    <p>検索結果がありません。</p>
                                )}
                            </Grid>
                            <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "100%" }}>
                                <div>
                                    {currentPage}/{totalPages == 0 ? 1 : totalPages}
                                </div>
                                {/* <Pagination count={3} variant="outlined" shape="rounded" showFirstButton showLastButton /> */}
                                <Pagination count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    page={currentPage}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </>
                    )}
                </div>
                <Button
                    onClick={() => navigate("/buyer")}
                    variant="outlined" sx={{ mr: 1 }} size="large"
                    style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '2rem 0', }}
                >
                    戻&emsp;る
                </Button>
            </div >
            <Footer />
        </>
    )
}
export default BuyerItemListPage