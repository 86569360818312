import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import TitleBar from '../../components/uiParts/TitleBar';
import { Grid, useMediaQuery, useTheme, Button, FormLabel } from '@mui/material'
import { apiURL } from '../../api/ApiConfig';
import { itemCategoryList } from '../../Store'
import { usePost, useFetch } from '../../api/ApiRequests';
import { useAuth } from '../../contexts/AuthContext'
import { cityList } from '../../Store'
import { addDays, format } from 'date-fns'

const SellerDoneNewItemPage = ({ formData, setFormData }) => {

    const { authInfo } = useAuth()
    const [mailto, setMailTo] = useState('')
    const navigate = useNavigate()

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const sellerId = query.get('sellerId')

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    //===============================================
    // API
    //===============================================
    //===============================================
    // useEffect
    //===============================================
    //===============================================
    // API
    //===============================================
    //===============================================
    // eventHandler
    //===============================================
    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        paddingBottom: '360px', // フッターの高さ分だけ余白を追加
        backgroundColor: '#F0F0F1',
        padding: "0 10px"
    }
    const homeInnerContainer = {
        width: "100%",
        maxWidth: "800px",
        marginTop: "10px",
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} />
            <div >
                <TitleBar title="商品ページ" />
            </div>
            <div style={homeContainer}>
                <div style={homeInnerContainer}>
                    <div style={{ textAlign: "start", fontWeight: "bold", marginBottom: "10px" }}>商品ページ状態：承認待ち</div>
                    <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start" }}>
                        商品情報の変更が事務局へ送信されました。 <br />
                        事務局より承認されるとサイトに反映されます。
                    </div>
                    <Grid item xs={12} sx={{ my: 3 }}>
                        <Button
                            onClick={() => navigate("/seller")}
                            variant="outlined" sx={{ mr: 1 }} size="large"
                            style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                        >
                            ホームへ戻る
                        </Button>
                    </Grid>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SellerDoneNewItemPage;