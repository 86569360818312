
import { useState, useEffect, useRef } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { jaJP } from '@mui/x-data-grid'
import Header from '../../components/Header'
import Frame from '../../components/uiParts/Frame'
import { useAuth } from './../../contexts/AuthContext'
import { useFetch } from './../../api/ApiRequests'
import TitleBar from '../../components/uiParts/TitleBar'
import { CustomDataGrid } from '../../components/dataGrid/CustomDataGrid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { darken } from '@mui/material/styles';


const MeetingList = () => {

	const { authInfo, setAuthInfo } = useAuth()
	const [searchCount, setSearchCount] = useState(0)
	const [targetList, setTargetList] = useState({})
	const [meetingList, setMeetingList] = useState([])
	const [dataGridHeight, setDataGridHeight] = useState(window.innerHeight - 180)
	const [openDialog, setOpenDialog] = useState(false)

	const effectRan = useRef(false)

	//===============================================
	// initialize
	//===============================================
	const systemName = process.env.REACT_APP_SYSTEM_NAME

	//-----------------------------------------------
	// initialize - セッション情報を取得
	//-----------------------------------------------
	const userId = authInfo.userId
	const userName = authInfo.userName

	//===============================================
	// API
	//===============================================
	//-----------------------------------------------
	// API - GETメソッドのテンプレート
	//-----------------------------------------------
	const getData = {
		userId: userId,
		userName: userName
	}
	//-----------------------------------------------
	// API - 商談一覧
	//-----------------------------------------------
	const { data: meetingData, loading: meetingLoading, fetchData: fetchMeetingData } = useFetch(getData)

	//===============================================
	// useEffect
	//===============================================
	//-----------------------------------------------
	// useEffect - ロード時の設定
	//-----------------------------------------------
	useEffect(() => {
		if (effectRan.current === false) {
			effectRan.current = true

			setSearchCount(searchCount + 1)

			const handleResize = () => {
				setDataGridHeight(window.innerHeight - 180)
			}
			window.addEventListener('resize', handleResize)

			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}
	}, [])

	useEffect(() => {
		fetchMeetingData(`/mst/meeting/`)
	}, [searchCount])


	useEffect(() => {
		if (meetingData) {
			setMeetingList(meetingData["meeting"])
		}
	}, [meetingData])

	//-----------------------------------------------
	// eventHandler - ダイアログを非表示
	//-----------------------------------------------
	const handleClick_dialog_close = () => {
		setOpenDialog(false)
	}
	//===============================================
	// dataGird
	//===============================================
	//-----------------------------------------------
	// dataGird - テーブルの見出し
	//-----------------------------------------------

	const handleClick_meetingJoin = (e) => {
		e.stopPropagation()
		window.open(targetList.meeting_url, "_blank")
	}

	const columns = [
		{ field: 'exhibit', headerName: '開催回', width: 100, columnSeparatorWidth: 1 },
		{ field: 'meeting_date', headerName: '商談日時', width: 250, columnSeparatorWidth: 1 },
		{ field: 'seller', headerName: '出展者名', width: 200, columnSeparatorWidth: 1, valueGetter: (params) => params.row?.seller.name },
		{ field: 'buyer', headerName: 'バイヤー名', width: 200, columnSeparatorWidth: 1, valueGetter: (params) => params.row?.buyer.name },
		{ field: 'item', headerName: '商品名', width: 350, columnSeparatorWidth: 1 },
		{
			field: 'meeting_url',
			headerName: '商談に参加',
			width: 100,
			columnSeparatorWidth: 1,
			renderCell: (params) => (
				<Button onClick={handleClick_meetingJoin} rel="noopener noreferrer" sx={{
					backgroundColor: "#4BBC01",
					borderRadius: "99px",
					height: "27px",
					color: "#fff",
					mx: "auto",
					"&:hover": {
						backgroundColor: `${darken('#4BBC01', 0.1)}`
					}
				}}>
					商談に参加
				</Button>
			),
		},
	];
	//-----------------------------------------------
	// dataGrid - 行の選択
	//-----------------------------------------------
	/**選択された行と、現在のlistTypeから表示するオブジェクトの情報を修正 */
	const handleClick_dataGrid = (event) => {
		setTargetList(event.row)
		setOpenDialog(true)
	}


	//===============================================
	// CSS
	//===============================================
	const homeContainer = {
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#fff',
		padding: '1em',
		display: 'flex',
		flexDirection: 'column',
	}
	//===============================================
	// JSXのレンダー
	//===============================================
	return (
		<>
			<Header title={systemName} loginInfo="on" backButton="on" backPage="/home" />
			<div style={homeContainer}>
				<div style={{ width: "100%", maxWidth: "782px", backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start", marginBottom: "20px" }}>
					現在予定されているオンライン商談の状況です。
				</div>

				<Frame className="frame" title="商談一覧">
					<CustomDataGrid
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						editMode="row"
						rows={meetingList}
						columns={columns}
						sx={{
							height: dataGridHeight,
							fontSize: 14,
							borderColor: 'primary.light'
						}}
						density="compact"
						localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
						showColumnRightBorder
						showCellRightBorder
						initialState={{ pagination: { paginationModel: { pageSize: 100 } }, }}
						pageSizeOptions={[50, 100]}
						loading={meetingLoading}
						onCellClick={(event) => handleClick_dataGrid(event)}
					/>
				</Frame>
			</div>
			{Object.keys(targetList).length > 0 &&
				<Dialog open={openDialog} onClose={handleClick_dialog_close} maxWidth='lg' >
					<DialogTitle >
						<TitleBar title="商談詳細" />
					</DialogTitle>
					<DialogContent style={{ border: 'none' }} sx={{ width: "100%", maxWidth: '750px' }}>
						<div style={{ backgroundColor: '#efefef', padding: '10px' }}>
							<Stack spacing={2} sx={{ my: 4 }}>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>No.</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.display_number}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>開催回</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.exhibit}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>日程調整日</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.adjustment_date}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.seller.name}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>バイヤー名</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.buyer.name}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商品名</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.item}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談日時</Typography>
									<Typography sx={{ mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{targetList.meeting_date}</Typography>
								</Stack>
								<Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start'>
									<Typography sx={{ minWidth: '250px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>商談URL</Typography>
									<Button href={targetList.meeting_url} target="_blank" rel="noopener noreferrer"
										sx={{
											width: "250px",
											height: "40px",
											backgroundColor: "#000080",
											size: "large",
											color: "#fff",
											"&:hover": {
												backgroundColor: "#431b9d"
											}
										}}>
										商談に参加
									</Button>
								</Stack>
							</Stack>
						</div>
						<div style={{ margin: "20px auto 0" }}>
							<Button
								color="tertiary"
								size="large"
								variant="contained"
								sx={{
									fontSize: '16px',
									height: '40px',
									width: '320px',
									color: '#fff',
									backgroundColor: '#808080',
									mx: "auto",
									display: "block"
								}}
								onClick={() => setOpenDialog(false)}
							>{'閉じる'}
							</Button>
						</div>
					</DialogContent>
				</Dialog>
			}
		</>
	)
}
export default MeetingList
