import { useState } from 'react'
import axios from 'axios'
import { authData, apiURL } from './ApiConfig'
import { useAuth } from '../contexts/AuthContext'

async function refreshToken() {
    try {
        const response = await axios.post('/api/token/', {"username":authData.username,"password":authData.password});
        sessionStorage.setItem('token', response.data.access);
        // Now update the axios header with the new token
        //console.log(`JWT ${response.data.access}`)
        axios.defaults.headers.common['Authorization'] = `JWT ${response.data.access}`;
        return response.data.access;
    } catch (error) {
        //console.error('Failed to refresh token:', error);
        throw error;  // Re-throw the error after logging it
    }
}
//-----------------------------------------------
// ログの登録
//-----------------------------------------------
const saveLog = async (logData) => {
    try {
        //await axios.post(`/log/apilog/`, logData);
    } catch (err) {
        //console.error('Failed to save log:', err);
    }
}
//-----------------------------------------------
// GETメソッドの実行
//-----------------------------------------------
export function useFetch(logInfo = {}) {
    const [ data,    setData    ] = useState(null);
    const [ loading, setLoading ] = useState(false); 
    const [ error,   setError   ] = useState(null);
    const { authInfo,setAuthInfo           } = useAuth()

    axios.defaults.baseURL = apiURL
    //axios.defaults.headers.common['Authorization'] = `JWT ${ authInfo.token }`
    axios.defaults.headers.common['Authorization'] = `JWT ${ sessionStorage.getItem('token') }`
    
    const fetchData = async (requestURL) => {
        setLoading(true);
        try {
            const response = await axios.get(requestURL)
            setData(response.data);

            // Save success log
            saveLog({
                ...logInfo,
                url        : requestURL,
                method     : 'GET',
                statusCode : response.status,
                logText    : `${JSON.stringify(response.data).substring(0, 1000)}`
            });
        } catch (err) {
            if (err.response && err.response.status === 401) {
                // 401 error detected, try to refresh the token
                try {
                    await refreshToken();  // Refresh the token
                    const response = await axios.get(requestURL); // Retry the request with the new token
                    setData(response.data);  // Set data from the retried request
                } catch (refreshErr) {
                    //console.error('Token refresh failed', refreshErr);
                    setError(refreshErr);
                }
            } else {
                setError(err);
                //console.log(err.response.status)
    
                // Save error log
                saveLog({
                    ...logInfo,
                    url        : requestURL,
                    method     : 'GET',
                    statusCode : err.response ? err.response.status : 'Unknown',
                    logText    : err.message
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, fetchData };
}
//-----------------------------------------------
// POSTメソッドの実行
//-----------------------------------------------
export function usePost(logInfo = {}) {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    axios.defaults.baseURL = apiURL
    //axios.defaults.headers.common['Authorization'] = `JWT ${ authInfo.token }`
    axios.defaults.headers.common['Authorization'] = `JWT ${ sessionStorage.getItem('token') }`

    const postData = async (requestURL, data, onSuccess) => {
        setLoading(true);
        try {
            const resp = await axios.post(requestURL, data);
            setResponse(resp.data);
            
            // ここで後続の処理を行う
            if (onSuccess) {
                onSuccess(resp.data);
            }

            // Save success log
            saveLog({
                ...logInfo,
                url        : requestURL,
                method     : 'POST',
                statusCode : resp.status,
                logText    : `${JSON.stringify(resp.data).substring(0, 1000)}`
            });
            return resp.data;  // ← ここでレスポンスデータを返します
        } catch (err) {
            if (err.response && err.response.status === 401) {
                // 401 error detected, try to refresh the token
                try {
                    await refreshToken();  // Refresh the token
                    const response = await axios.post(requestURL, data); // Retry the request with the new token
                    setResponse(response.data);  // Set data from the retried request
                } catch (refreshErr) {
                    //console.error('Token refresh failed', refreshErr);
                    setError(refreshErr);
                }
            } else {
                setError(err);
                // Save error log
                saveLog({
                    ...logInfo,
                    url        : requestURL,
                    method     : 'POST',
                    statusCode : err.response ? err.response.status : 'Unknown',
                    logText    : err.message
                });
                throw err;  // ← ここでエラーを再スローします
            }
        } finally {
            setLoading(false);
        }
    };

    return { response, loading, error, postData };
}
//-----------------------------------------------
// PUTメソッドの実行
//-----------------------------------------------
export function usePut(logInfo = {}) {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    axios.defaults.baseURL = apiURL
    //axios.defaults.headers.common['Authorization'] = `JWT ${ authInfo.token }`
    axios.defaults.headers.common['Authorization'] = `JWT ${ sessionStorage.getItem('token') }`

    const putData = async (requestURL, data, onSuccess) => {
        setLoading(true);
        try {
            const resp = await axios.put(requestURL, data);
            setResponse(resp.data);
            
            // ここで後続の処理を行う
            if (onSuccess) {
                onSuccess(resp.data);
            }

            // Save success log
            saveLog({
                ...logInfo,
                url        : requestURL,
                method     : 'PUT',
                statusCode : resp.status,
                logText    : `${JSON.stringify(resp.data).substring(0, 1000)}`
            });
            return resp.data;  // ← ここでレスポンスデータを返します
        } catch (err) {
            if (err.response && err.response.status === 401) {
                // 401 error detected, try to refresh the token
                try {
                    await refreshToken();  // Refresh the token
                    const response = await axios.put(requestURL, data); // Retry the request with the new token
                    setResponse(response.data);  // Set data from the retried request
                } catch (refreshErr) {
                    //console.error('Token refresh failed', refreshErr);
                    setError(refreshErr);
                }
            } else {
                setError(err);
                // Save error log
                saveLog({
                    ...logInfo,
                    url        : requestURL,
                    method     : 'PUT',
                    statusCode : err.response ? err.response.status : 'Unknown',
                    logText    : err.message
                });
                throw err; // ここでエラーをスロー
            }
        } finally {
            setLoading(false);
        }
    };

    return { response, loading, error, putData };
}

//-----------------------------------------------
// DELETEメソッドの実行
//-----------------------------------------------
export function useDelete(logInfo = {}) {
		const [response, setResponse] = useState(null);
		const [loading, setLoading] = useState(false);
		const [error, setError] = useState(null);

		axios.defaults.baseURL = apiURL
		//axios.defaults.headers.common['Authorization'] = `JWT ${ authInfo.token }`
		axios.defaults.headers.common['Authorization'] = `JWT ${ sessionStorage.getItem('token') }`

		const deleteData = async (requestURL, onSuccess) => {
				setLoading(true);
				try {
						const resp = await axios.delete(requestURL);
						setResponse(resp.data);
						
						// ここで後続の処理を行う
						if (onSuccess) {
								onSuccess(resp.data);
						}

						// Save success log
						saveLog({
								...logInfo,
								url        : requestURL,
								method     : 'DELETE',
								statusCode : resp.status,
								logText    : `${JSON.stringify(resp.data).substring(0, 1000)}`
						});
						return resp.data;  // ← ここでレスポンスデータを返します
				} catch (err) {
						if (err.response && err.response.status === 401) {
								// 401 error detected, try to refresh the token
								try {
										await refreshToken();  // Refresh the token
										const response = await axios.delete(requestURL); // Retry the request with the new token
										setResponse(response.data);  // Set data from the retried request
								} catch (refreshErr) {
										//console.error('Token refresh failed', refreshErr);
										setError(refreshErr);
								}
						} else {
								setError(err);
								// Save error log
								saveLog({
										...logInfo,
										url        : requestURL,
										method     : 'DELETE',
										statusCode : err.response ? err.response.status : 'Unknown',
										logText    : err.message
								});
								throw err; // ここでエラーをスロー
						}
				} finally {
						setLoading(false);
				}
		};

		return { response, loading, error, deleteData };
}