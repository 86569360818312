import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Stack, TextField, FormHelperText, Box } from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useAuth } from "../../contexts/AuthContext";
import TitleBar from "../../components/uiParts/TitleBar";
import { useFetch, usePost } from "../../api/ApiRequests";
import { addDays, format } from "date-fns";
import { cityList } from "../../Store";
import { apiURL } from "../../api/ApiConfig";
import { itemCategoryList } from "../../Store";

const BuyerItemDetailPage = ({ formData, setFormData }) => {
  const { authInfo, setAuthInfo } = useAuth();
  const [cityName, setCityName] = useState(0);
  const [gyoShu, setGyoShu] = useState(0);
  const [shutokuNinsho, setShutokuNinsho] = useState(0);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const pathname = useLocation().pathname;
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const sellerId = query.get("sellerId");

  const { id: itemId } = useParams();

  //===============================================
  // initialize
  //===============================================
  const today = format(addDays(new Date(), 0), "yyyy-MM-dd");
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;
  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - POSTメソッドのテンプレート
  //-----------------------------------------------
  const { postData } = usePost({
    userId: userId,
    userName: userName,
  });
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userId: userId,
    userName: userName,
  };

  /*商品詳細取得用*/
  const { data: itemData, loading: itemLoading, fetchData: fetchItemData } = useFetch(getData);

  /*商品一覧取得用*/
  const { data: itemsData, loading: itemsLoading, fetchData: fetchItemsData } = useFetch(getData);

  /*セラー情報の取得*/
  const { data: sellerData, loading: sellerLoading, fetchData: fetchSellerData } = useFetch(getData);
  //===============================================
  // useEffect
  //===============================================

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //===============================================
  // useEffect
  //===============================================
  //-----------------------------------------------
  // useEffect - セラーリスト
  //-----------------------------------------------
  useEffect(() => {
    if (itemData) {
      const city = cityList.find((item) => item.code == itemData.seller.city);
      const cityLabel = city?.label || "";
      setCityName(cityLabel);
      //
      var gyoShuTxt = "";
      var shutokuNinshoTxt = "";
      if (itemData.seller.checkField01) {
        gyoShuTxt = `${gyoShuTxt}農業`;
      }
      if (itemData.seller.checkField02) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}水産業`;
      }
      if (itemData.seller.checkField03) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}小売業`;
      }
      if (itemData.seller.checkField04) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}食品加工`;
      }
      if (itemData.seller.checkField05) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}製造業`;
      }
      if (itemData.seller.checkField06) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}卸売業`;
      }
      if (itemData.seller.checkField07) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}飲食`;
      }
      if (itemData.seller.checkField08) {
        if (gyoShuTxt != "") {
          gyoShuTxt = `${gyoShuTxt}、`;
        }
        gyoShuTxt = `${gyoShuTxt}その他`;
      }
      setGyoShu(gyoShuTxt);

      if (itemData.seller.checkField09) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}有機JAS`;
      }
      if (itemData.seller.checkField10) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}JGAP`;
      }
      if (itemData.seller.checkField11) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}ASIAGAP`;
      }
      if (itemData.seller.checkField12) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}GlobalGAP`;
      }
      if (itemData.seller.checkField13) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}ISO9001`;
      }
      if (itemData.seller.checkField14) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}ISO14001`;
      }
      if (itemData.seller.checkField15) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}ISO22000`;
      }
      if (itemData.seller.checkField16) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}FSSC22000`;
      }
      if (itemData.seller.checkField17) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}US HACCAP`;
      }
      if (itemData.seller.checkField18) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}EU HACCAP`;
      }
      if (itemData.seller.checkField19) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}なし`;
      }
      if (itemData.seller.checkField20) {
        if (shutokuNinshoTxt != "") {
          shutokuNinshoTxt = `${shutokuNinshoTxt}、`;
        }
        shutokuNinshoTxt = `${shutokuNinshoTxt}その他`;
      }
      setShutokuNinsho(shutokuNinshoTxt);
    }
  }, [itemData]);

  useEffect(() => {
    const requestURL = `mst/buyer/item_detail/?id=${itemId}`;
    fetchItemData(requestURL);
  }, [itemId]);

  //===============================================
  // eventHandler
  //===============================================
  const onClickNegotiation = () => {
    navigate(`/buyer/sellers/${itemData?.seller?.id}/contact?item_id=${itemId}`);
  };
  const onClickToDetail = (id) => {
    fetchItemData(`/mst/buyer/item_detail/?id=${id}`);
    navigate(`/buyer/items/${id}/`);
  };

  //===============================================
  // CSS
  //===============================================
  const homeContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: "#F0F0F1",
  };
  const formContainer = {
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 10px",
    width: "100%",
    maxWidth: "800px",
  };

  const hashTag = {
    border: "2px solid #275f5a",
    borderRadius: "20px",
    padding: "5px 10px",
  };

  //===============================================
  // JSXのレンダー
  //===============================================
  return (
    <>
      <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} backPage="/buyer/items" />
      <TitleBar title="出展者紹介" />
      <div style={homeContainer}>
        <div className="form-container" style={formContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {!itemLoading && itemData && (
              <div style={{ display: "flex", width: "100%", marginBottom: "-15px" }}>
                <div style={hashTag}>{cityName}</div>
                <div style={{ ...hashTag, marginLeft: "10px" }}>{gyoShu}</div>
              </div>
            )}

            <div style={{
              width: "100%",
            }}>
              <h4
                style={{
                  backgroundColor: "#4BBC06",
                  fontWeight: "normal",
                  color: "white",
                  marginBottom: 0,
                }}
              >
                {/* {itemData ? itemData.mainItem.name : ''} */}
                出展者商品
              </h4>
              <div style={{ backgroundColor: "white", padding: "8px 0" }}>
                {itemData?.mainItem.name}
              </div>
            </div>
            {/*  商品画像  */}
            {itemData && itemData.mainItem.imageField01 && <img src={`${apiURL}media/uploads/${itemData.mainItem.imageField01}`} alt={`${itemData.mainItem.name}`} style={{ width: "80%" }} />}
            {/*  商品のアピールポイント  */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <h4
                style={{
                  backgroundColor: "#4BBC06",
                  fontWeight: "normal",
                  color: "white",
                  margin: 0,
                }}
              >
                商品のアピールポイント
              </h4>
              <div style={{ color: "#0B7D50", backgroundColor: "white", textAlign: "start", padding: "5px 20px", fontSize: "18px" }}>
                {itemData?.mainItem?.textField01}
              </div>
              <div style={{ backgroundColor: "white", textAlign: "start", padding: "0 10px 10px 20px" }}>
                {itemData?.mainItem?.textField02}
              </div>
            </div>
            <Button
              variant="contained"
              onClick={() => onClickNegotiation()}
              sx={{
                width: "90%",
                borderRadius: "99px",
                padding: "10px 0",
                margin: "10px 0",
                fontSize: "18px",
              }}
            >
              この出展者へオンライン商談を申し込む
            </Button>
            {/*  出展者紹介  */}
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <h4
                style={{
                  backgroundColor: "#4BBC06",
                  fontWeight: "normal",
                  color: "white",
                  margin: 0,
                }}
              >
                出展者紹介
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column-reverse" : "row",
                  backgroundColor: "white",
                  textAlign: "center",
                  padding: "10px 20px",
                  gap: "10px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", flex: "6", }}>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      出展者名:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.name : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      出展者名(フリガナ):
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.nameKana : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      メールアドレス:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.eMail : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      部署:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.busho : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      担当者:
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.tantosha : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      都道府県:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {cityName}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      所在地:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.address : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      業種:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {gyoShu}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      その他(自由記入):
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.textField01 : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      取得認証:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {shutokuNinsho}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      その他(取得記述):
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.textField02 : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                      認定・受賞歴など:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.textField03 : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={4} sx={{ textAlign: "left", color: "#007D50" }}>
                      ホームページ:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.textField05 : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={4} sx={{ textAlign: "left", color: "#007D50" }}>
                      X(Twitter):
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                      {itemData ? itemData.seller.textField06 : ""}
                    </Grid>
                  </Grid>
                  <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                    <Grid item xs={4} sx={{ textAlign: "left", color: "#007D50" }}>
                      Instagram:
                    </Grid>
                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left", wordBreak: "break-all" }}>
                      {itemData ? itemData.seller.textField07 : ""}
                    </Grid>
                  </Grid>
                </div>
                <div style={{ flex: "2" }}>{<img width="100%" src={`${apiURL}media/uploads/${itemData?.seller?.imageField01}`} alt={`${itemData?.seller?.name}`} />}</div>
              </div>
            </div>
            <Button
              variant="contained"
              onClick={() => onClickNegotiation()}
              sx={{
                width: "90%",
                borderRadius: "99px",
                padding: "10px 0",
                margin: "10px 0",
                fontSize: "18px",
              }}
            >
              この出展者へオンライン商談を申し込む
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <h4
                style={{
                  backgroundColor: "#4BBC06",
                  fontWeight: "normal",
                  color: "white",
                  margin: 0,
                }}
              >
                この出展者の商品紹介
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {itemData &&
                  Array.from(itemData.extraItems).map((extraItem, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "column" : "row",
                        backgroundColor: "white",
                        textAlign: "center",
                        padding: "10px 20px",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          flex: 3,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box sx={{ width: "300px", height: "300px", margin: "0 auto", flex: 2 }}>
                          {extraItem.imageField01 && (
                            <Box sx={{ minWidth: "300px", maxWidth: "300px", minHeight: "300px", maxHeight: "300px", flex: 3, display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                              <img width="100%" src={`${apiURL}media/uploads/${extraItem.imageField01}`} style={{ margin: "auto", objectFit: "cover", objectPosition: "center" }} alt={`${extraItem.name}`} />
                            </Box>
                          )}
                        </Box>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 6,
                        }}
                      >
                        <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                          <Grid
                            item
                            xs={12}
                            sx={{
                              textAlign: "center",
                              color: "#007D50",
                              fontSize: "20px",
                            }}
                          >
                            商品
                          </Grid>
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                          <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                            商品カテゴリー:
                          </Grid>
                          <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                            {itemCategoryList.find((i) => i.code === extraItem.itemCategory)?.label || ""}
                          </Grid>
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                          <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                            キャッチコピー:
                          </Grid>
                          <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                            {extraItem.textField01}
                          </Grid>
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                          <Grid item xs={isSmallScreen ? 1 : 4} sx={{ textAlign: "left", color: "#007D50" }}>
                            商品ポイント:
                          </Grid>
                          <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: "left" }}>
                            {extraItem.textField02}
                          </Grid>
                        </Grid>
                        <Grid container direction={isSmallScreen ? "column" : "row"}>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              onClick={() => onClickToDetail(extraItem.id)}
                              sx={{
                                backgroundColor: "#4BBC06",
                                width: "200px",
                                borderRadius: "99px",
                                margin: "10px 0",
                                fontSize: "18px",
                              }}
                            >
                              商品の詳細
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <Button
              variant="contained"
              onClick={() => onClickNegotiation()}
              sx={{
                width: "90%",
                borderRadius: "99px",
                padding: "10px 0",
                marginTop: "10px",
                fontSize: "18px",
              }}
            >
              この出展者へオンライン商談を申し込む
            </Button>
          </div>
        </div>
        <Button
          onClick={() => navigate("/buyer/items")}
          variant="outlined" sx={{ mr: 1 }} size="large"
          style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '2rem 0', }}
        >
          戻&emsp;る
        </Button>
      </div>
      <Footer />
    </>
  );
};
export default BuyerItemDetailPage;
