import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  FormLabel,
  Stack,
  TextField,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box
} from "@mui/material";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useAuth } from "../../contexts/AuthContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TitleBar from "../../components/uiParts/TitleBar";
import EntryCustomInput from "../../components/entryForm/EntryCustomInput";
import EntryCustomField from "../../components/entryForm/EntryCustomField";
import EntryCustomSelect from "../../components/entryForm/EntryCustomSelect";
import { usePost } from "../../api/ApiRequests";
import { addDays, format } from "date-fns";
import { certificationList, cityList, exhibitList } from "../../Store";
import { apiURL } from "../../api/ApiConfig";
import { usePut } from "../../api/ApiRequests";

const SellerEditMyPage = ({ setFormData, formData }) => {
  const { authInfo, setAuthInfo } = useAuth();
  const [file, setFile] = useState(null);
  const [uploads, setUploads] = useState([]);
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirm, setIsConfirm] = useState(false)

  //===============================================
  // initialize
  //===============================================
  const today = format(addDays(new Date(), 0), "yyyy-MM-dd");
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  const certificationCheckFields = (data) => {
    const checkFields = {};
    for (let i = 9; i <= 20; i++) {
      const key = `checkField${String(i).padStart(2, '0')}`;
      if (key in data) {
        checkFields[key] = data[key];
      }
    }
    return checkFields;
  }

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;

  const effectRan = useRef(false);

  const title = `出展者ユーザ：${userName}`;

  const item = cityList.find((item) => item.code == formData.city);
  const cityName = item?.label || "";

  //===============================================
  // フォーム
  //===============================================
  const telRegExp = /^((\+81|81|0)(\d{1,4})(\-)?(\d{1,4})(\-)?(\d{3,4}))$/; // 電話番号のvalidation用
  //-----------------------------------------------
  // フォーム - スキーマ定義
  //-----------------------------------------------
  const schema = yup.object().shape({
    checkField01: yup.boolean(),
    checkField02: yup.boolean(),
    checkField03: yup.boolean(),
    checkField04: yup.boolean(),
    checkField05: yup.boolean(),
    checkField06: yup.boolean(),
    checkField07: yup.boolean(),
    checkField08: yup.boolean(),
    checkField09: yup.boolean(),
    checkField10: yup.boolean(),
    checkField11: yup.boolean(),
    checkField12: yup.boolean(),
    checkField13: yup.boolean(),
    checkField14: yup.boolean(),
    checkField15: yup.boolean(),
    checkField16: yup.boolean(),
    checkField17: yup.boolean(),
    checkField18: yup.boolean(),
    checkField19: yup.boolean(),
    checkField20: yup.boolean(),

    // 出展者
    checkTarget: yup
      .boolean()
      .test(
        "searchTest",
        "出展者の業種を１つ以上選択してください",
        function (value) {
          return (
            this.parent.checkField01 ||
            this.parent.checkField02 ||
            this.parent.checkField03 ||
            this.parent.checkField04 ||
            this.parent.checkField05 ||
            this.parent.checkField06 ||
            this.parent.checkField07 ||
            this.parent.checkField08
          );
        }
      ),
    // 取得認証
    checkCert: yup
      .boolean()
      .test(
        "searchTest",
        "取得認証を1つ以上選択してください",
        function (value) {
          return (
            this.parent.checkField09 ||
            this.parent.checkField10 ||
            this.parent.checkField11 ||
            this.parent.checkField12 ||
            this.parent.checkField13 ||
            this.parent.checkField14 ||
            this.parent.checkField15 ||
            this.parent.checkField16 ||
            this.parent.checkField17 ||
            this.parent.checkField18 ||
            this.parent.checkField19 ||
            this.parent.checkField20
          );
        }
      ),
    exhibit: yup.string().required("開催回を指定してください"),
    eMail: yup
      .string()
      .email("メールアドレスの形式が不正です")
      .required("メールアドレスを入力してください"),
    name: yup.string().required("出展者名を入力してください"),
    nameKana: yup.string().required("出展者名（フリガナ）を入力してください"),
    city: yup.string().required("都道府県を選択してください"),
    address: yup
      .string()
      .required("市区町村・丁目・番地・部屋番号までを入力してください"),
    imageField01: yup.string().required("メイン画像を指定してください"),
    tel: yup.string().matches(telRegExp, "正しい電話番号を入力してください").required('電話番号を入力してください'),
  });
  //-----------------------------------------------
  // フォーム - 登録データのテンプレート
  //-----------------------------------------------
  const registerData = {
    entryDate: today,
    deleteFlag: 0,
    statusCode: 0,
    exhibit: formData?.exhibit ? formData.exhibit : 2,
    eMail: formData?.eMail ? formData.eMail : "",
    name: formData?.name ? formData.name : "",
    nameKana: formData?.nameKana ? formData.nameKana : "",
    tel: formData?.tel ? formData.tel : "",
    busho: formData?.busho ? formData.busho : "",
    tantosha: formData?.tantosha ? formData.tantosha : "",
    city: formData?.city ? formData.city : "",
    address: formData?.address ? formData.address : "",
    textField01: formData?.textField01 ? formData.textField01 : "",
    textField02: formData?.textField02 ? formData.textField02 : "",
    textField03: formData?.textField03 ? formData.textField03 : "",
    textField04: formData?.textField04 ? formData.textField04 : "",
    textField05: formData?.textField05 ? formData.textField05 : "",
    textField06: formData?.textField06 ? formData.textField06 : "",
    textField07: formData?.textField07 ? formData.textField07 : "",
    checkField01: formData?.checkField01 ? formData.checkField01 : false,
    checkField02: formData?.checkField02 ? formData.checkField02 : false,
    checkField03: formData?.checkField03 ? formData.checkField03 : false,
    checkField04: formData?.checkField04 ? formData.checkField04 : false,
    checkField05: formData?.checkField05 ? formData.checkField05 : false,
    checkField06: formData?.checkField06 ? formData.checkField06 : false,
    checkField07: formData?.checkField07 ? formData.checkField07 : false,
    checkField08: formData?.checkField08 ? formData.checkField08 : false,
    checkField09: formData?.checkField09 ? formData.checkField09 : false,
    checkField10: formData?.checkField10 ? formData.checkField10 : false,
    checkField11: formData?.checkField11 ? formData.checkField11 : false,
    checkField12: formData?.checkField12 ? formData.checkField12 : false,
    checkField13: formData?.checkField13 ? formData.checkField13 : false,
    checkField14: formData?.checkField14 ? formData.checkField14 : false,
    checkField15: formData?.checkField15 ? formData.checkField15 : false,
    checkField16: formData?.checkField16 ? formData.checkField16 : false,
    checkField17: formData?.checkField17 ? formData.checkField17 : false,
    checkField18: formData?.checkField18 ? formData.checkField18 : false,
    checkField19: formData?.checkField19 ? formData.checkField19 : false,
    checkField20: formData?.checkField20 ? formData.checkField20 : false,
    imageField01: formData?.imageField01 ? formData.imageField01 : "",
    imageField02: "",
    cityList: cityList,
    exhibitList: exhibitList,
  };
  const [defaultValues, setDefaultValues] = useState(registerData);
  const useFormMethods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useFormMethods;

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - POSTメソッドのテンプレート
  //-----------------------------------------------
  const { postData } = usePost({
    userId: userId,
    userName: userName,
  });

  const { putData } = usePut({
    userId: userId,
    userName: userName,
  });

  //===============================================
  // useEffect
  //===============================================
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //===============================================
  // eventHandler
  //===============================================
  //-----------------------------------------------
  // eventHandler - 画像ファイルのアップロード
  //-----------------------------------------------
  const handleSubmit_upload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const onSuccess = (data) => {
        const url = data.file;
        const extractedPart = url.split("media/uploads/")[1];
        console.log("Extracted Part:", extractedPart); // ここで確認のためにログ出力
        // 必要に応じて状態を更新
        setValue("imageField01", extractedPart); // 例: React Hook Formなどを使用している場合
      };
      await postData("/mst/uploads/", formData, onSuccess);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    try {
      console.log(formData);
      const res = await putData(`/mst/seller/${authInfo.sellerId}/`, formData);
      if (res) {
        navigate("/seller/profile/done");
      }
    } catch (error) {
      console.error(error);
    }
  };
  //===============================================
  // CSS
  //===============================================
  //-----------------------------------------------
  // CSS - Edit
  //-----------------------------------------------
  const homeContainerEdit = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F1",
    padding: "0 10px",
  };
  const homeInnerContainerEdit = {
    flexDirection: "column",
    maxWidth: "800px",
    marginTop: "40px",
  };
  const onSubmitEdit = (data) => {
    setFormData(data);
    setIsConfirm(true)
    // navigate("/seller/profile/confirm");
  };
  const formContainerEdit = {
    display: "flex",
    flexDirection: "column",
    padding: "5px 20px 20px 20px",
    marginTop: "30px",
    borderRadius: "10px",
    justifyContent: "center",
    backgroundColor: "white",
  };
  //-----------------------------------------------
  // CSS - Confirm
  //-----------------------------------------------
  const homeContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F1",
    padding: "0 10px",
  };
  const homeInnerContainer = {
    flexDirection: "column",
    // maxWidth: "800px",
    marginTop: "40px",
  };
  const responsiveImage = {
    width: "380px",
  };
  const formContainer = {
    marginTop: "30px",
    paddingBottom: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    backgroundColor: "white",
  };

  //===============================================
  // JSXのレンダー
  //===============================================
  return (
    <>
      {
        isConfirm ?
          <>
            <Header
              title={systemName}
              loginInfo={isSmallScreen ? "off" : "on"}
              backButton={isSmallScreen ? "off" : "on"}

            />
            <TitleBar title={title} />
            <div style={homeContainer}>
              <div style={homeInnerContainer}>
                <div
                  style={{
                    backgroundColor: "#D5EDC7",
                    padding: "15px",
                    borderRadius: "10px",
                    textAlign: "start",
                  }}
                >
                  編集する場合は「編集」ボタンをクリックして任意の項目を編集し、「承認申請」ボタンをクリックしてください。
                  事務局により承認されるとサイトに反映されます。
                </div>
                <div className="form-container" style={formContainer}>
                  <div
                    style={{
                      fontSize: "1.5em",
                      margin: "0.5em",
                      fontWeight: "bold",
                      borderBottom: "1px solid",
                    }}
                  >
                    基本情報
                  </div>

                  <Stack spacing={2} sx={{ my: 4 }}>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展希望回</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.exhibit}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メールアドレス</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.eMail}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.name}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者名(フリガナ)</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.nameKana}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>電話番号</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.tel}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>部署</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.busho}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>担当者</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.tantosha}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>都道府県</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{cityName}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>所在地</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.address}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>出展者業種</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>
                        {formData.checkField01 ? <span style={{ marginright: "1em" }}>農業</span> : ""}
                        {formData.checkField02 ? <span style={{ marginRight: "1em" }}>水産業</span> : ""}
                        {formData.checkField03 ? <span style={{ marginRight: "1em" }}>小売業</span> : ""}
                        {formData.checkField04 ? <span style={{ marginRight: "1em" }}>食品加工</span> : ""}
                        {formData.checkField05 ? <span style={{ marginRight: "1em" }}>製造業</span> : ""}
                        {formData.checkField06 ? <span style={{ marginRight: "1em" }}>卸売業</span> : ""}
                        {formData.checkField07 ? <span style={{ marginRight: "1em" }}>飲食業</span> : ""}
                        {formData.checkField08 ? <span style={{ marginRight: "1em" }}>その他</span> : ""}
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他(自由記入)</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField01}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>取得認証</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>
                        {(() => {
                          const checkFields = certificationCheckFields(formData);
                          return certificationList.filter(industry => {
                            const key = Object.keys(industry)[0];
                            return checkFields[key];
                          }).map(industry => Object.values(industry)[0]).join(',\n');
                        })()}
                      </Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>その他(取得記述):</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField02}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>認定・受賞歴など</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField03}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>ホームページ</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField05}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>X（Twitter）</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField06}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>Instagram</Typography>
                      <Typography sx={{ width: "100%", maxWidth: '380px', mt: 1, whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word", textAlign: 'left', fontSize: '1.2em' }}>{formData.textField07}</Typography>
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems='flex-start' justifyContent="center">
                      <Typography sx={{ minWidth: '220px', textAlign: 'left', mt: 1, fontWeight: 'bold', fontSize: '1.2em' }}>メイン画像</Typography>
                      <Box>
                        <img src={`${apiURL}media/uploads/${formData.imageField01}`}
                          alt="Example"
                          style={responsiveImage} />
                      </Box>
                    </Stack>
                  </Stack>
                </div>
                <Grid item xs={12} sx={{ my: 3 }}>
                  <Button
                    onClick={() => setIsConfirm(false)}
                    variant="outlined"
                    sx={{ mr: 1 }}
                    size="large"
                    style={{
                      color: "#4BBC06",
                      fontSize: "20px",
                      width: "300px",
                      margin: "0.5rem",
                    }}
                  >
                    戻　る
                  </Button>
                  <Button
                    onClick={() => handleConfirm()}
                    variant="contained"
                    sx={{ mr: 1 }}
                    size="large"
                    style={{
                      color: "#fff",
                      backgroundColor: "#4BBC06",
                      fontSize: "20px",
                      width: "300px",
                      margin: "0.5rem",
                    }}
                  >
                    承認申請
                  </Button>
                </Grid>
              </div>
            </div>
            <Footer />
          </> : <>
            <Header
              title={systemName}
              loginInfo={isSmallScreen ? "off" : "on"}
              backButton={isSmallScreen ? "off" : "on"}
            />
            <div style={{ backgroundColor: "#F0F0F1" }}>
              <TitleBar title={title} />
            </div>
            <div style={homeContainerEdit}>
              <div style={homeInnerContainerEdit}>
                <div
                  style={{
                    backgroundColor: "#D5EDC7",
                    padding: "15px",
                    borderRadius: "10px",
                    textAlign: "start",
                  }}
                >
                  編集する場合は「編集」ボタンをクリックして任意の項目を編集し、「承認申請」ボタンをクリックしてください。
                  事務局により承認されるとサイトに反映されます。
                </div>
                <FormProvider {...useFormMethods}>
                  <form onSubmit={handleSubmit(onSubmitEdit)}>
                    <div className="form-container" style={formContainerEdit}>
                      <div
                        style={{
                          fontSize: "1.5em",
                          margin: "0 0.5em",
                          fontWeight: "bold",
                          borderBottom: "1px solid",
                        }}
                      >
                        基本情報
                      </div>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        required="on"
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomSelect
                              id="exhibit"
                              name="exhibit"
                              label="出展希望回"
                              labelWidth="250px"
                              required="on"
                              placeHolder="選択してください"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="eMail"
                              name="eMail"
                              label="メールアドレス"
                              labelWidth="250px"
                              required="on"
                              placeHolder="例：abc@jfc-hokuriku4-online.com"
                              gideFlag={true}
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="name"
                              name="name"
                              label="出展者名"
                              labelWidth="250px"
                              required="on"
                              placeHolder="例：株式会社日本政策金融公庫"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="nameKana"
                              name="nameKana"
                              label="出展者名（フリガナ）"
                              labelWidth="250px"
                              required="on"
                              placeHolder="例：カ）ニッポンセイサクキンユウコウコ"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              gideFlag
                              id="tel"
                              name="tel"
                              label="電話番号"
                              labelWidth="250px"
                              required="on"
                              placeHolder="例：0312345678"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="busho"
                              name="busho"
                              label="部署"
                              labelWidth="250px"
                              required="off"
                              placeHolder="例：営業部"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="tantosha"
                              name="tantosha"
                              label="担当者"
                              labelWidth="250px"
                              required="off"
                              placeHolder="例：山田太郎"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomSelect
                              id="city"
                              name="city"
                              label="都道府県"
                              labelWidth="250px"
                              required="on"
                              placeHolder="選択してください"
                            />
                          </Grid>
                          {errors.city && (
                            <>
                              <Grid
                                container
                                direction={isSmallScreen ? "column" : "row"}
                                spacing={2}
                              >
                                <Grid
                                  item
                                  sx={{ width: "250px", textAlign: "left", mt: 1 }}
                                ></Grid>
                                <Grid item xs={isSmallScreen ? 12 : "auto"}>
                                  <div
                                    style={{ color: "#d32f2f", fontSize: "0.8em" }}
                                  >
                                    {errors.city.message}
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="address"
                              name="address"
                              label="所在地"
                              labelWidth="250px"
                              required="on"
                              placeHolder="例：市区町村・丁目・番地・部屋番号まで"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <Grid
                              item
                              sx={{ width: "250px", textAlign: "left", mt: 1 }}
                            >
                              <FormLabel
                                sx={{
                                  whiteSpace: "nowrap",
                                  border: "1px solid red",
                                  color: "red",
                                  padding: "5px",
                                }}
                              >
                                必須
                              </FormLabel>
                              <FormLabel
                                sx={{
                                  whiteSpace: "nowrap",
                                  ml: 1,
                                  fontWeight: "bold",
                                }}
                              >
                                出展者業種
                              </FormLabel>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : "auto"}>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField01"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField01"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="農業"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField02"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField02"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="水産業"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField03"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField03"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="小売業"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField04"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField04"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="食品加工"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField05"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField05"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="製造業"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField06"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField06"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="卸売業"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField07"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField07"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="飲食業"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField08"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField08"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="その他"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              {errors.checkTarget && (
                                <div style={{ color: "#d32f2f", fontSize: "0.8em" }}>
                                  {errors.checkTarget.message}
                                </div>
                              )}
                              <FormHelperText>
                                サイト上・ガイドブックに掲載されます。
                              </FormHelperText>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomField
                              id="textField01"
                              name="textField01"
                              label="その他（自由記入）"
                              labelWidth="250px"
                              required="off"
                              placeHolder="上記でその他の業種をご選択された際に、業種を記入してください。"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <Grid
                              item
                              sx={{ width: "250px", textAlign: "left", mt: 1 }}
                            >
                              <FormLabel
                                sx={{
                                  whiteSpace: "nowrap",
                                  border: "1px solid red",
                                  color: "red",
                                  padding: "5px",
                                }}
                              >
                                必須
                              </FormLabel>
                              <FormLabel
                                sx={{
                                  whiteSpace: "nowrap",
                                  ml: 1,
                                  fontWeight: "bold",
                                }}
                              >
                                取得認証
                              </FormLabel>
                            </Grid>
                            <Grid item xs={isSmallScreen ? 12 : "auto"}>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField09"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField09"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="有機JAS"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField10"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField10"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="JGAP"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField11"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField11"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="ASIAGAP"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField12"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField12"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="Global GAP"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField13"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField13"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="ISO 9001"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField14"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField14"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="ISO 14001"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField15"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField15"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="ISO 22000"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField16"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField16"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="FSSC 22000"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField17"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField17"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="US・HACCP"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField18"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField18"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="EU・HACCP"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField19"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField10"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="取得認証なし"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                              width: "163px",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ mr: 1 }}>
                                    <FormControl fullWidth>
                                      <Controller
                                        name="checkField20"
                                        control={control}
                                        render={({ field }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                {...field}
                                                checked={field.value}
                                                name="checkField20"
                                                onChange={(e) => {
                                                  field.onChange(e);
                                                }}
                                              />
                                            }
                                            label="その他"
                                            sx={{
                                              color: "#666666",
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </Grid>
                                </Stack>
                              </Stack>
                              {errors.checkCert && (
                                <div style={{ color: "#d32f2f", fontSize: "0.8em" }}>
                                  {errors.checkCert.message}
                                </div>
                              )}
                              <FormHelperText>
                                サイト上・ガイドブックに掲載されます。
                              </FormHelperText>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomField
                              id="textField02"
                              name="textField02"
                              label="その他（取得記述）"
                              labelWidth="250px"
                              required="off"
                              placeHolder="上記以外の各種認証を取得されている場合はこちらに記載してください。"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomField
                              id="textField03"
                              name="textField03"
                              label="認定・受賞歴など"
                              labelWidth="250px"
                              required="off"
                              placeHolder="例：2023年モンドセレクション金賞受賞"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="textField05"
                              name="textField05"
                              label="ホームページ"
                              labelWidth="250px"
                              required="off"
                              placeHolder="例：https://www.⚪︎⚪︎⚪︎⚪︎⚪︎⚪︎.com/"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="textField06"
                              name="textField06"
                              label="X(Twitter)"
                              labelWidth="250px"
                              required="off"
                              placeHolder="例：https://x.com/⚪︎⚪︎⚪︎⚪︎⚪︎⚪︎"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                          >
                            <EntryCustomInput
                              id="textField07"
                              name="textField07"
                              label="Instagram"
                              labelWidth="250px"
                              required="off"
                              placeHolder="例：https://www.instagram.com/⚪︎⚪︎⚪︎⚪︎⚪︎⚪︎"
                            />
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                            sx={{ mt: 1 }}
                          >
                            <Grid
                              item
                              sx={{ width: "250px", textAlign: "left", mt: 1 }}
                            >
                              <FormLabel
                                sx={{
                                  whiteSpace: "nowrap",
                                  border: "1px solid red",
                                  color: "red",
                                  padding: "5px",
                                }}
                              >
                                必須
                              </FormLabel>
                              <FormLabel
                                sx={{
                                  whiteSpace: "nowrap",
                                  ml: 1,
                                  fontWeight: "bold",
                                }}
                              >
                                メイン画像
                              </FormLabel>
                            </Grid>
                            <Grid
                              item
                              xs={isSmallScreen ? 12 : "auto"}
                              sx={{ mt: 2 }}
                            >
                              <Controller
                                name="imageField01"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="imageField01"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: 35,
                                        width: "300px",
                                      },
                                    }}
                                    placeholder="ファイル名"
                                    helperText={errors?.imageField01?.message}
                                    FormHelperTextProps={{ sx: { width: 200 } }}
                                    error={
                                      errors?.imageField01?.message ? true : false
                                    }
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                              <FormHelperText>
                                サイト上・ガイドブックに掲載されます。
                              </FormHelperText>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={isSmallScreen ? "column" : "row"}
                            spacing={2}
                            sx={{ mt: 0 }}
                          >
                            <Grid
                              item
                              sx={{ width: "250px", textAlign: "left", mt: 1 }}
                            ></Grid>
                            <Grid item xs={isSmallScreen ? 12 : "auto"}>
                              <Stack>
                                <Stack direction="row" alignItems="flex-start">
                                  <Grid sx={{ mr: 1 }}>
                                    <input
                                      type="file"
                                      accept="image/jpeg, image/png, image/gif"
                                      onChange={(e) => handleSubmit_upload(e)}
                                    />
                                  </Grid>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <Grid item xs={12} sx={{ my: 3 }}>
                      <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        sx={{ mr: 1 }}
                        size="large"
                        style={{
                          color: "#4BBC06",
                          fontSize: "20px",
                          width: "300px",
                          margin: "0.5rem",
                        }}
                      >
                        戻　る
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mr: 1 }}
                        size="large"
                        style={{
                          color: "#fff",
                          backgroundColor: "#4BBC06",
                          fontSize: "20px",
                          width: "300px",
                          margin: "0.5rem",
                        }}
                      >
                        入力情報の確認
                      </Button>
                    </Grid>
                  </form>
                </FormProvider>
              </div>
            </div>
            <Footer />
          </>
      }
    </>
  );
};
export default SellerEditMyPage;