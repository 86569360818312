import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import { useFetch } from "../../api/ApiRequests";
import MenuIcon from "../../components/uiParts/MenuIcon";
import TitleBar from "../../components/uiParts/TitleBar";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { InstructionModal } from "../../components/sellar/modal/InstructionModal/InstructionModal";

const SellerHome = () => {
  const { authInfo } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();

  const effectRan = useRef(false);

  const modalData = [{
    title: "出展者用(PC版)",
    path: ``
  },
  {
    title: "出展者用(スマホ版)",
    path: ``
  }]

  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const title = `出展者ユーザ：${userName}`;

  // console.log(authInfo)

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userId: userId,
    userName: userName,
  };
  //-----------------------------------------------
  // API - 発注・納品テーブル
  //-----------------------------------------------
  const {
    data: userData,
    loading: user_Loading,
    fetchData: fetchUserData,
  } = useFetch(getData);

  //===============================================
  // useEffect
  //===============================================
  //-----------------------------------------------
  // useEffect - ロード時の設定
  //-----------------------------------------------
  useEffect(() => {
    if (effectRan.current === false) {
      effectRan.current = true;
      fetchUserData(`/mst/user/?username=${userId}&deleteFlag=0`);
    }
  }, []);
  //-----------------------------------------------
  // useEffect - 部署マスタ
  //-----------------------------------------------
  useEffect(() => {
    if (userData) {
      console.log(userData);
    }
  }, [userData]);

  //===============================================
  // eventHandler
  //===============================================
  const handleClickProfile = () => {
    navigate("profile");
  };
  const handleClickItems = () => {
    navigate("items");
  };
  const handleClickBuyerList = () => {
    window.open(`${process.env.PUBLIC_URL}/images/buyers.pdf`, '_blank')
  };
  const handleClickMeetings = () => {
    navigate("meetings");
  };
  const handleClickInstructions = () => {
    setModalVisible(true);
  };
  //===============================================
  // CSS
  //===============================================
  const homeContainer = {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingBottom: "150px", // フッターの高さ分だけ余白を追加
  };
  //===============================================
  // JSXのレンダー
  //===============================================
  return (
    <>
      {authInfo.userName &&
        <>
          <Header title={systemName} loginInfo="on" backButton="off" />
          <div style={homeContainer}>
            {isModalVisible && (
              <InstructionModal data={modalData} onClickClose={() => setModalVisible(false)} />
            )}
            <TitleBar title={title} backgroundColor="white" />
            <div style={{ width: "100%", color: "white", textAlign: "center" }}>
              <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                <Grid
                  container
                  direction={isSmallScreen ? "column" : "row"}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <MenuIcon
                      header="My Page"
                      title="マイページ"
                      onClick={() => handleClickProfile()}
                    >
                      <AccountCircleIcon
                        style={{ fontSize: "4rem", color: "#007D50" }}
                      />
                    </MenuIcon>
                    <MenuIcon
                      header="Products Page"
                      title="商品ページ"
                      onClick={() => handleClickItems()}
                    >
                      <StorefrontIcon
                        style={{ fontSize: "4rem", color: "#007D50" }}
                      />
                    </MenuIcon>
                    <MenuIcon
                      header="Entry Menu"
                      title="バイヤーリスト"
                      onClick={() => handleClickBuyerList()}
                    >
                      <PersonOutlineIcon
                        style={{ fontSize: "4rem", color: "#007D50" }}
                      />
                    </MenuIcon>
                    <MenuIcon
                      header="Management Menu"
                      title="商談状況"
                      onClick={() => handleClickMeetings()}
                    >
                      <LocalMallIcon
                        style={{ fontSize: "4rem", color: "#007D50" }}
                      />
                    </MenuIcon>
                    <MenuIcon
                      header="Management Menu"
                      title="操作説明書"
                      onClick={() => handleClickInstructions()}
                    >
                      <LocalMallIcon
                        style={{ fontSize: "4rem", color: "#007D50" }}
                      />
                    </MenuIcon>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div></>

      }

    </>
  );
};
export default SellerHome;
