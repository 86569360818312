import * as React from "react";
import Button from "@mui/material/Button";
import { Grid, Box, useMediaQuery, useTheme, IconButton, Drawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import "./../styles/components/Header.css";
import { useAuth } from "../contexts/AuthContext";
import { facilityData } from "../Store";
import { useState } from "react";
import { InstructionModal } from "../components/sellar/modal/InstructionModal/InstructionModal";


const Header = (props) => {
  const { title, loginInfo, backButton, backPage } = props;
  const { authInfo } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  // セッション情報を取得
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;

  // sessionStorageに保存したデータを削除する
  const CookieDataDelete = () => {
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("facilityId");
    sessionStorage.removeItem("facilityCode");
    sessionStorage.removeItem("facilityName");
    sessionStorage.removeItem("loginDateTime");
    sessionStorage.removeItem("departId");
    sessionStorage.removeItem("departCode");
    sessionStorage.removeItem("departName");
    sessionStorage.removeItem("authority");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("sellerId");
    sessionStorage.removeItem("buyerId");
  };

  const handleClick_home = () => {
    if (authInfo.userId) {
      navigate("/home");
      return;
    }
    if (authInfo.buyerId) {
      navigate("/buyer");
      return;
    }
    if (authInfo.sellerId) {
      navigate("/seller");
      return;
    }
    navigate("/");
  };

  const getManual = () => {
    if (authInfo.authority === "2") {
      return [{
        title: "出展者用(PC版)",
        path: ``
      },
      {
        title: "出展者用(スマホ版)",
        path: ``
      }]
    } else {
      return [{
        title: "バイヤー用(PC版)",
        path: ``
      },
      {
        title: "バイヤー用(スマホ版)",
        path: ``
      }]
    }
  }

  const getMenuItems = () => {
    if (authInfo.userId) {
      return [
        { text: "ホーム", onClick: () => navigate("/home") },
        { text: "セラーリスト", onClick: () => navigate("/sellerList") },
        { text: "バイヤーリスト", onClick: () => navigate("/buyerList") },
        { text: "ユーザーリスト", onClick: () => navigate("/userList") },
        { text: "商談状況", onClick: () => navigate("/meetings") },
        { text: "レビュー状況", onClick: () => navigate("/manual") },
      ];
    } else if (authInfo.buyerId) {
      return [
        { text: "ホーム", onClick: () => navigate("/buyer") },
        { text: "マイページ", onClick: () => navigate("/buyer/profile") },
        { text: "商品ページ", onClick: () => navigate("/buyer/sellers") },
        { text: "商談状況", onClick: () => navigate("/buyer/meetings") },
        { text: "操作説明書", onClick: () => setModalVisible(true) },
      ];
    } else if (authInfo.sellerId) {
      return [
        { text: "ホーム", onClick: () => navigate("/seller") },
        { text: "マイページ", onClick: () => navigate("/seller/profile") },
        { text: "商品ページ", onClick: () => navigate("/seller/items") },
        { text: "バイヤーリスト", onClick: () => window.open(`${process.env.PUBLIC_URL}/images/buyers.pdf`, '_blank') },
        { text: "商談状況", onClick: () => navigate("/seller/meetings") },
        { text: "操作説明書", onClick: () => setModalVisible(true) },
      ];
    } else {
      return [
        { text: "出展申し込み", onClick: () => navigate("/teamsOfUse?target=seller") },
        { text: "バイヤー参加申し込み", onClick: () => navigate("/teamsOfUse?target=buyer") },
        { text: "ログイン", onClick: () => navigate("/login") },
      ];
    }
  };

  const menuItems = getMenuItems();

  // CSS
  const header = {
    width: "100%",
    height: "70px",
    maxHeight: "70px",
    backgroundColor: "#4BBC06",
    padding: "0.3em",
    zIndex: 1200,
    position: "sticky",
    top: 0,
    left: 0,
  };
  const header_flex = {
    display: "flex",
    marginLeft: "20px",
  };
  const header_pageTitle = {
    color: "#fff",
    fontWeight: "normal",
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 0 }} style={header}>
        {isModalVisible && (
          <InstructionModal data={getManual()} onClickClose={() => setModalVisible(false)} />
        )}
        <Box>
          <div style={header_flex} onClick={handleClick_home}>
            <img src={`${process.env.PUBLIC_URL}${facilityData.logoUrl}`} style={{ height: "60px", width: "60px" }} alt="Logo" />
            <div href="#" className="pagetitle" style={header_pageTitle} sx={{ fontSize: { xs: "1.1rem", md: "1.2rem" } }}>
              <div>北陸4県応援企画</div>
              <div>食のオンライン商談会2024</div>
            </div>
          </div>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isMobile && (location.pathname !== "login" || location.pathname !== "") ? (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMenuOpen(!menuOpen)} sx={{ pt: 0, pr: 2, pb: 0, pl: 0 }}>
              {menuOpen ? <CloseIcon sx={{ color: "white" }} /> : <MenuIcon sx={{ color: "white" }} />}
            </IconButton>
          ) : (
            <>
              {backButton == "on" && (
                <Button
                  variant="contained"
                  style={{
                    color: "#007D50",
                    marginLeft: "10px",
                    backgroundColor: "#fff",
                    marginTop: "10px",
                    height: "45px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    backPage ? navigate(backPage) : navigate(-1);
                  }}
                >
                  戻る
                </Button>
              )}
              {loginInfo == "on" && (
                <Button
                  variant="contained"
                  style={{
                    color: "#007D50",
                    marginLeft: "10px",
                    backgroundColor: "#fff",
                    marginTop: "10px",
                    height: "45px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    CookieDataDelete();
                    navigate("/");
                  }}
                >
                  ログアウト
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <Drawer
        anchor="top"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
            maxHeight: "calc(100% - 70px)",
            top: 70,
            backgroundColor: "white",
            padding: "20px",
          },
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                color: "#4BBC06",
                borderColor: "#4BBC06",
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px",
              }}
              onClick={() => {
                handleClick_home();
                setMenuOpen(false);
              }}
            >
              ホーム
            </Button>
          </Grid>
          {menuItems.map((item, index) => (
            <Grid item xs={6} key={index}>
              <Button
                fullWidth
                sx={{
                  backgroundColor: "#f0f0f0",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
                onClick={() => {
                  item.onClick();
                  setMenuOpen(false);
                }}
              >
                {item.text}
              </Button>
            </Grid>
          ))}
          {(authInfo.userId || authInfo.buyerId || authInfo.sellerId) && (
            <>
              <Grid item xs={12}>
                <Box sx={{ borderTop: "1px solid black", my: 2 }} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    color: "#4BBC06",
                    borderColor: "#4BBC06",
                    backgroundColor: "white",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => {
                    CookieDataDelete();
                    navigate("/");
                    setMenuOpen(false);
                  }}
                >
                  ログアウト
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Box sx={{ width: "100%", padding: "20px", textAlign: "center", marginTop: "20px" }}>
          <img src={`${process.env.PUBLIC_URL}/images/siteHeader_logo_L_pc.png`} alt="JFC Logo" style={{ width: "100px", marginBottom: "10px" }} />
          <Typography variant="body2">
            <a href="/privacy-policy" style={{ color: "blue", textDecoration: "none" }}>
              プライバシーポリシー
            </a>
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
