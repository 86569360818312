import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Divider, Box, Modal } from "@mui/material";
import { apiURL } from "../../api/ApiConfig";
import { itemCategoryList } from "../../Store";
import { usePost, useFetch, useDelete } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";
import { addDays, format, set } from "date-fns";

const SellerItemPage = ({ formData, setFormData }) => {
    const { authInfo } = useAuth();
    const [mailto, setMailTo] = useState("");
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    //モーダルの管理用
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentId] = useState({
        id: null,
        name: null
    });
    const handleOpen = (id, name) => {
        setCurrentId({ id: id, name: name });
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId;
    const userName = authInfo.userName;
    const authority = authInfo.authority;

    const effectRan = useRef(false);

    const item = itemCategoryList.find((item) => item.code == formData?.itemCategory);
    const itemCategoryName = item?.label;

    const systemName = process.env.REACT_APP_SYSTEM_NAME;

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - GETメソッドのテンプレート
    //-----------------------------------------------
    const getData = {
        userId: userId,
        userName: userName,
    };
    //-----------------------------------------------
    // API - 商品一覧
    //-----------------------------------------------
    const { data, loading, error, fetchData } = useFetch(getData);

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - 起動時の処理
    //-----------------------------------------------
    useEffect(() => {
        const requestURL = `/mst/seller/item?seller_id=${authInfo.sellerId}`;
        fetchData(requestURL);
    }, [authInfo.sellerId]);

    //===============================================
    // useEffect
    //===============================================
    //-----------------------------------------------
    // useEffect - 起動時の処理
    //-----------------------------------------------
    useEffect(() => {
        const requestURL = `/mst/seller/item?seller_id=${authInfo.sellerId}`;
        fetchData(requestURL);
    }, [authInfo.sellerId]);

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName
    })
    //-----------------------------------------------
    // API - DELETEメソッドのテンプレート
    //-----------------------------------------------
    const { response, deleteLoading, error: deleteError, deleteData } = useDelete(getData);

    //===============================================
    // eventHandler
    //===============================================
    const onClickNew = () => {
        navigate(`/seller/items/new`)
    }
    const onClickEdit = (id) => {
        navigate(`/seller/items/${id}/edit`)
    }
    const onClickPreviw = (id) => {
        navigate(`/seller/items/${id}/preview`)
    }
    const onClickDelete = async () => {
        const requestURL = `${apiURL}mst/item/${currentItem.id}/`;
        await deleteData(requestURL);
        await fetchData(`/mst/seller/item?seller_id=${authInfo.sellerId}`);
        setOpen(false)
    }
    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F0F1',
        padding: "0 10px"
    }
    const homeInnerContainer = {
        width: "100%",
        maxWidth: "800px",
        marginTop: "10px",
    }
    const responsiveImage = {
        width: '300px',
    }
    const formContainer = {
        marginTop: "30px",
        borderRadius: "10px",
        padding: "10px",
        backgroundColor: "white",
        display: 'flex',
        flexDirection: "column",
    }

    return (
        <>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} backPage="/seller" />
            <div style={{}}>
                <TitleBar title='商品ページ' />
            </div>
            <div style={homeContainer}>

                <div style={homeInnerContainer}>
                    <div style={{ textAlign: "start", fontWeight: "bold", marginBottom: "10px" }}>商品ページ状態：サイト登録中</div>
                    <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start" }}>
                        編集する場合は 「編集」 ボタンをクリックして任意の項目を編集し、「承認申請」 ボタンをクリックしてください。
                        「プレビュー」 ボタンはバイヤーから見た商品情報を見る際にクリックします。<br />
                        追加したい商品がある場合は「追加」ボタンクリックしてください。<br />
                        削除したい商品がある場合は「削除」ボタンクリックしてください。<br />
                        事務局より承認されるとサイトに反映されます。
                    </div>

                    <div className="form-container" style={formContainer}>
                        <Box sx={{ height: { xs: "auto", sm: "43px" }, fontSize: '1.5em', my: '0.5em', fontWeight: isSmallScreen ? 'normal' : 'bold', position: "relative", display: "flex", justifyContent: "center", flexDirection: { xs: "column", sm: "row" } }}>
                            <Box sx={{ alignSelf: { xs: "center", sm: "flex-end" } }}>商品情報</Box>
                            {!loading && data &&
                                <Button variant="contained" disabled={data["item"].length >= 5} size="small" sx={{ color: '#fff', backgroundColor: data["item"].length >= 5 ? "#757575" : '#4BBC06', fontSize: '20px', width: '120px', position: { xs: "unset", sm: "absolute" }, right: 0, top: 0, alignSelf: { xs: "flex-end", sm: "none" } }} onClick={() => onClickNew()}>新規追加</Button>
                            }
                        </Box>
                        {!loading && data &&
                            data["item"].map((item, index) => (<Box sx={{ display: "flex", flexDirection: "column" }} key={`i-${index}`}>
                                <Divider sx={{ mb: 2, borderColor: "#30303099" }} />
                                <Box sx={{ width: "100%", display: "flex", justifyContent: { xs: "center", sm: "flex-end" }, gap: "1rem" }}>
                                    <Button
                                        onClick={() => handleOpen(item.id, item.name)}
                                        variant="contained" size="small"
                                        style={{ color: '#1d1a1a', backgroundColor: '#757575', fontSize: '20px', width: '120px' }}
                                    >
                                        削&emsp;除
                                    </Button>
                                    <Button
                                        onClick={() => onClickEdit(item.id)}
                                        variant="contained" size="small"
                                        style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '120px' }}
                                    >
                                        編&emsp;集
                                    </Button>
                                    <Button
                                        onClick={() => onClickPreviw(item.id)}
                                        variant="contained" size="small"
                                        style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '120px', }}
                                    >
                                        プレビュー
                                    </Button>
                                </Box>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems={isSmallScreen ? "start" : "center"}
                                    required="on"
                                    marginBottom={"20px"}
                                >
                                    <Grid item xs={12}>
                                        <Grid container direction={isSmallScreen ? "column" : "row"} mt={6}>
                                            <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                <FormLabel sx={{ ml: 1, fontWeight: 'bold', fontSize: { xs: '1em', sm: '1.2em' } }}>商品名</FormLabel>
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 12 : 8} sx={{ mt: 1, textAlign: 'left' }}>
                                                <FormLabel sx={{ fontSize: { xs: '1em', sm: '1.2em' }, marginLeft: '1em' }}>{item.name}</FormLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                            <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                <FormLabel sx={{ ml: 1, fontWeight: 'bold', fontSize: { xs: '1em', sm: '1.2em' } }}>商品名（フリガナ）</FormLabel>
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 12 : 8} sx={{ mt: 1, textAlign: 'left' }}>
                                                <FormLabel sx={{ fontSize: { xs: '1em', sm: '1.2em' }, marginLeft: '1em' }}>{item.nameKana}</FormLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                            <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                <FormLabel sx={{ ml: 1, fontWeight: 'bold', fontSize: { xs: '1em', sm: '1.2em' } }}>商品カテゴリ</FormLabel>
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 12 : 8} sx={{ mt: 1, textAlign: 'left' }}>
                                                <FormLabel sx={{ fontSize: { xs: '1em', sm: '1.2em' }, marginLeft: '1em' }}>{itemCategoryList.find(i => i.code === item.itemCategory).label}</FormLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                            <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                <FormLabel sx={{ ml: 1, fontWeight: 'bold', fontSize: { xs: '1em', sm: '1.2em' } }}>キャッチフレーズ</FormLabel>
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 12 : 8} sx={{ mt: 1, textAlign: 'left' }}>
                                                <FormLabel sx={{ fontSize: { xs: '1em', sm: '1.2em' } }}>{item.textField01}</FormLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                            <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1, }}>
                                                <FormLabel sx={{ ml: 1, fontWeight: 'bold', fontSize: { xs: '1em', sm: '1.2em' } }}>商品説明</FormLabel>
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 12 : 8} sx={{ mt: 1, textAlign: 'left' }}>
                                                <FormLabel sx={{ fontSize: { xs: '1em', sm: '1.2em' } }}>{item.textField02}</FormLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} mt={1}>
                                            <Grid item sx={{ width: '200px', textAlign: 'left', mt: 1 }}>
                                                <FormLabel sx={{ ml: 1, fontWeight: 'bold', fontSize: { xs: '1em', sm: '1.2em' } }}>商品画像</FormLabel>
                                            </Grid>
                                            <Grid item xs={isSmallScreen ? 12 : 8} sx={{ mt: 1, textAlign: 'left' }}>
                                                {!loading && item.imageField01 &&
                                                    <img src={`${apiURL}media/uploads/${item.imageField01}`} alt="Example" style={responsiveImage} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            ))
                        }
                    </div>
                </div>
                <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
                    <Button
                        onClick={() => navigate("/seller")}
                        variant="outlined" sx={{ mr: 1 }} size="large"
                        style={{ backgroundColor: "white", color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                    >
                        戻　る
                    </Button>
                </Grid>
            </div>
            {/**
						 * 削除確認モーダル
						 * 画面の中央値に表示
						 *  */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ backgroundColor: 'white', width: '100%', maxWidth: "480px", margin: 'auto', padding: '20px' }}>
                    <h2 id="modal-modal-title">商品名：{currentItem.name}</h2>
                    <p>商品情報を削除申請してもよろしいですか？</p>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            size="small"
                            style={{ color: '#fff', backgroundColor: '#757575', fontSize: '20px', width: '120px' }}
                        >
                            キャンセル
                        </Button>
                        <Button
                            onClick={onClickDelete}
                            variant="contained"
                            size="small"
                            style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '120px' }}
                        >
                            削&emsp;除
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Footer />
        </>
    );
};

export default SellerItemPage;
