import React from 'react'

const TitleBar = ({ title, backgroundColor = "#F0F0F1" }) => {
    return (
        <>
            <div style={{ clear: 'both' }}></div>
            <div style={{ padding: "5px 10px", backgroundColor: backgroundColor }}>
                <div
                    style={{
                        fontSize: '18px',
                        textAlign: 'left',
                        backgroundColor: '#007D50',
                        color: '#fff',
                        padding: '5px',
                        borderRadius: '5px'
                    }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{title}
                </div>
            </div>

        </>
    )
}

export default TitleBar