import React, { useRef } from 'react';
import Button from '@mui/material/Button';

const PostButton = ({ onClick, ...props }) => {
    const isPostProcessing = useRef(false);
    // 多重クリックの防止
    const handleClick = async (event) => {
        if (isPostProcessing.current) return;

        isPostProcessing.current = true;

        try {
            if (onClick) {
                await onClick(event);
            }
        } finally {
            isPostProcessing.current = false;
        }
    };

    return (
        <Button {...props} onClick={handleClick} />
    );
};

export default PostButton;