import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Grid, useMediaQuery, useTheme, Button } from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useAuth } from './../../contexts/AuthContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TitleBar from '../../components/uiParts/TitleBar';
import EntryCustomInput from '../../components/entryForm/EntryCustomInput';
import { usePost } from './../../api/ApiRequests';
import { addDays, format } from 'date-fns';
import { cityList, exhibitList } from '../../Store';

const BuyerForm = ({ setFormData, formData }) => {
    const { authInfo, setAuthInfo } = useAuth();
    const [file, setFile] = useState(null);
    const [uploads, setUploads] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd');
    const systemName = process.env.REACT_APP_SYSTEM_NAME;

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const facility = authInfo.facilityId;
    const facilityCode = authInfo.facilityCode;
    const facilityName = authInfo.facilityName;
    const loginDepartId = authInfo.departId;
    const loginUserName = authInfo.userName;
    const authority = authInfo.authority;

    const effectRan = useRef(false);

    //===============================================
    // フォーム
    //===============================================
    //-----------------------------------------------
    // フォーム - スキーマ定義
    //-----------------------------------------------
    const schema = yup.object().shape({
        eMail: yup.string().email('メールアドレスの形式が不正です').required('メールアドレスを入力してください'),
        name: yup.string().required('会社名を入力してください'),
        busho: yup.string().required('部署を入力してください'),
        tantosha: yup.string().required('担当者を入力してください'),
        address: yup.string().required('市区町村・丁目・番地・部屋番号までを入力してください'),
        tel: yup.string().required('電話番号を入力してください'),
        mobile: yup.string().required('携帯番号を入力してください'),
    });

    //-----------------------------------------------
    // フォーム - 登録データのテンプレート
    //-----------------------------------------------
    const registerData = {
        entryDate: today,
        deleteFlag: 0,
        statusCode: 0,
        eMail: formData?.eMail ? formData.eMail : '',
        name: formData?.name ? formData.name : '',
        nameKana: formData?.nameKana ? formData.nameKana : '',
        busho: formData?.busho ? formData.busho : '',
        tantosha: formData?.tantosha ? formData.tantosha : '',
        address: formData?.address ? formData.address : '',
        tel: formData?.tel ? formData.tel : '',
        mobile: formData?.mobile ? formData.mobile : '',
        textField01: '',
        textField02: '',
        textField03: '',
        textField04: '',
        checkField01: false,
        checkField02: false,
        checkField03: false,
        checkField04: false,
        checkField05: false,
        checkField06: false,
        checkField07: false,
        checkField08: false,
        checkField09: false,
        checkField10: false,
        checkField11: false,
        checkField12: false,
        checkField13: false,
        checkField14: false,
        checkField15: false,
        checkField16: false,
        checkField17: false,
        checkField18: false,
        checkField19: false,
        checkField20: false,
        imageField01: '',
        imageField02: '',
        cityList: cityList,
        exhibitList: exhibitList,
    };

    const [defaultValues, setDefaultValues] = useState(registerData);

    const useFormMethods = useForm({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { control, handleSubmit, formState: { errors }, setValue } = useFormMethods;

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        facilityCode,
        facilityName,
        userId: loginDepartId,
        userName: loginUserName,
    });

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //===============================================
    // eventHandler
    //===============================================
    const onSubmit = (data) => {
        console.log(data);
        setFormData(data);
        navigate('/buyerConfirm');
    };

    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '250px', // フッターの高さ分だけ余白を追加
    };

    const formContainer = {
        display: 'flex',
        justifyContent: 'center',
    };

    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName}  loginInfo="off" backButton="off"  />
            <TitleBar title="バイヤー参加者登録フォーム" backgroundColor='white' />
            <div style={homeContainer}>
                <FormProvider {...useFormMethods}>
                    <div className="form-container" style={formContainer}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div style={{ fontSize: '1.5em', margin: '0.5em', fontWeight: 'bold', borderBottom: '1px solid' }}>基本情報</div>
                            <Grid
                                container
                                direction="column"
                                spacing={2}
                                justifyContent="center"
                                alignItems="center"
                                required="on"
                            >
                                <Grid item xs={12}>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="eMail" name="eMail"
                                            label="メールアドレス" labelWidth="250px" required="on" placeHolder="例：abc@jfc-hokuriku4-online.com" gideFlag={true}
                                        />
                                    </Grid>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="name" name="name"
                                            label="会社名" labelWidth="250px" required="on" placeHolder="例：株式会社日本政策金融公庫" gideFlag={true}
                                        />
                                    </Grid>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="busho" name="busho"
                                            label="部署" labelWidth="250px" required="on" placeHolder="例：営業部" gideFlag={true}
                                        />
                                    </Grid>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="tantosha" name="tantosha"
                                            label="担当者" labelWidth="250px" required="on" placeHolder="例：山田太郎" gideFlag={true}
                                        />
                                    </Grid>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="address" name="address"
                                            label="住所" labelWidth="250px" required="on" placeHolder="例：市区町村・丁目・番地・部屋番号まで" gideFlag={true}
                                        />
                                    </Grid>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="tel" name="tel"
                                            label="電話番号" labelWidth="250px" required="on" placeHolder="例：012-3456-7890" gideFlag={true}
                                        />
                                    </Grid>
                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                        <EntryCustomInput
                                            id="mobile" name="mobile"
                                            label="携帯番号" labelWidth="250px" required="on" placeHolder="例：012-3456-7890" gideFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 5 }}>
                                <Button
                                    type="submit" variant="contained" sx={{ mr: 1 }} size="large"
                                    style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                >
                                    入力情報の確認
                                </Button>
                            </Grid>
                        </form>
                    </div>
                </FormProvider>
            </div>
            <Footer />
        </>
    );
};

export default BuyerForm;