/* eslint-disable*/
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Grid, Stack, FormLabel, Select, FormHelperText, FormControl, MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export function EntryCustomSelect({id, name, label, labelWidth, required, placeHolder, onHandleBlur, onHandleChange}) {
    
    const { control, formState: { errors }, getValues } = useFormContext();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    // データ取得
    const dataList = getValues(`${name}List`);

    const handleChange = (name, value) => {
        onHandleChange(name);
    }
    const handleBlur = (name, value) => {
        onHandleBlur(name);
    }
    const fieldName = 'pmdActApprovalNo2'; 
    return (
        <>
        <Grid item sx={{width:labelWidth, textAlign:'left', mt:3}}>
            {
                required === 'on'
                ?
                <FormLabel sx={{ whiteSpace: 'nowrap', border:'1px solid red', color:'red', padding:'5px' }}>必須</FormLabel>
                :
                <FormLabel sx={{ whiteSpace: 'nowrap', border:'1px solid grey', color:'grey', padding:'5px' }}>任意</FormLabel>
            }
            <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold' }}>{label}</FormLabel>
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{mt: 2}}>
            <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <FormControl size='small'>
                            <Select
                                {...field}
                                name={name}
                                id={id}
                                sx={{ width: 300, height: 35, mr: 3,}}
                                onChange={(e) => {
                                    field.onChange(e);
                                }}
                                MenuProps={{
                                    PaperProps: {
                                      style: {
                                        textAlign: 'left',
                                      },
                                    },
                                }}
                                style={{ textAlign: 'left' }}
                                value={field.value}
                                helperText={errors?.[name]?.message}
                                FormHelperTextProps={{ sx: { width: 200 } }}
                                error={errors?.[name]?.message ? true : false}
                            >
                                {dataList?.map((data) => (
                                    <MenuItem key={data.code} value={data.code} sx={{ height: '35px' }}>{data.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
            />
            <FormHelperText>サイト上・ガイドブックに掲載されます。</FormHelperText>
        </Grid>
        </>
    )
}
  
EntryCustomSelect.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    labelWidth: PropTypes.string,
    required: PropTypes.string,
    placeHolder: PropTypes.string,
    inputWidth: PropTypes.string,
    onHandleBlur: PropTypes.func,
    onHandleChange: PropTypes.func,
};
export default EntryCustomSelect;
/* eslint-disable*/