import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TitleBar from "../../components/uiParts/TitleBar";
import { useMediaQuery, useTheme, Button } from "@mui/material";
import { useFetch } from "../../api/ApiRequests";
// import { apiURL } from '../../api/ApiConfig';
import { cityList } from "../../Store";
import { usePost } from "../../api/ApiRequests";
import { useAuth } from "../../contexts/AuthContext";
import { format } from "date-fns";

const BuyerMeetingsPage = ({ formData, setformData }) => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const [searchCount, setSearchCount] = useState(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //===============================================
  // initialize
  //===============================================
  const systemName = process.env.REACT_APP_SYSTEM_NAME;

  //-----------------------------------------------
  // initialize - セッション情報を取得
  //-----------------------------------------------
  const userId = authInfo.userId;
  const userName = authInfo.userName;
  const authority = authInfo.authority;

  const effectRan = useRef(false);

  const title = `商談状況`;

  const item = cityList.find((item) => item.code == formData?.city);
  const cityName = item?.label || "";

  //===============================================
  // API
  //===============================================
  //-----------------------------------------------
  // API - POSTメソッドのテンプレート
  //-----------------------------------------------
  const { postData } = usePost({
    userId: userId,
    userName: userName,
  });
  //-----------------------------------------------
  // API - GETメソッドのテンプレート
  //-----------------------------------------------
  const getData = {
    userId: userId,
    userName: userName,
  };
  //-----------------------------------------------
  // API - 商品マスタ
  //-----------------------------------------------
  const {
    data: meetingData,
    loading: meetting_loading,
    fetchData: fetchMettingData,
  } = useFetch(getData);

  const {
    data: sellerProfile,
    loading: seller_Loading,
    fetchData: fetchSellerData,
  } = useFetch(getData);

  const { data, loading, error, fetchData } = useFetch(getData);

  //===============================================
  // useEffect
  //===============================================
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //-----------------------------------------------
  // useEffect - 検索イベント
  //-----------------------------------------------
  useEffect(() => {
    fetchMettingData(`/mst/buyer/meeting`);
  }, [searchCount]);
  //===============================================
  // eventHandler
  //===============================================

  const onClickNegotiation = (url) => {
    window.location.href = url;
  };
  //===============================================
  // CSS
  //===============================================
  const pageContainer = {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  };

  const bodyContainer = {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 10px",
    backgroundColor: "#F0F0F1",
  };

  const contentContainer = {
    flexDirection: "column",
    // maxWidth: "800px",
    width: "100%",
    overflow: "auto",
  };

  const tableStyle = {
    borderCollapse: "collapse",
    margin: "auto",
    // width: "800px"
  };

  const tableThStyle = {
    border: "1px solid #868686",
    backgroundColor: "#F1F1F1",
    lineHeight: "1.6em",
  };

  const tableTdStyle = {
    border: "1px solid #868686",
    backgroundColor: "white",
    lineHeight: "1.6em",
    textAlign: "left",
    padding: "8px",
  };

  return (
    <div style={pageContainer}>
      <Header
        title={systemName}
        loginInfo={isSmallScreen ? "off" : "on"}
        backButton={isSmallScreen ? "off" : "on"}
        backPage="/buyer"
      />
      <div style={{}}>
        <TitleBar title={title} />
      </div>
      <div style={bodyContainer}>
        <div style={contentContainer}>
          <div
            style={{
              width: "50%",
              backgroundColor: "#D5EDC7",
              padding: "15px",
              margin: "30px auto",
              borderRadius: "10px",
              textAlign: "start",
            }}
          >
            商談日時になったらメールで配信された日程調整完了のメールのリンク、
            または下記の
            「商談に参加」ボタンからオンライン商談を開始してください。
          </div>
          <div style={{ overflow: "auto" }}>
            <table style={tableStyle}>
              <thead>
                <th style={tableThStyle}>番号</th>
                <th style={tableThStyle}>会社名</th>
                <th style={tableThStyle}>名前</th>
                <th style={tableThStyle}>商談日時</th>
                <th style={tableThStyle}>商談開始 URL</th>
                <th style={tableThStyle}>レビュー</th>
              </thead>
              <tbody>
                {meetingData &&
                  meetingData.meeting &&
                  meetingData.meeting.map((data, index) => (
                    <tr>
                      <td style={{ ...tableTdStyle, textAlign: "center" }}>
                        {String(data.serial_no).padStart(3, "0")}
                      </td>
                      <td style={tableTdStyle}>{data.company_name}</td>

                      <td style={tableTdStyle}>{data.item_name}</td>

                      <td style={tableTdStyle}>{data.meeting_time}</td>

                      <td style={tableTdStyle}>
                        <Button
                          onClick={() => onClickNegotiation(data.meeting_url)}
                          color="primary"
                          size="large"
                          variant="contained"
                          sx={{ width: "140px", marginLeft: "10px" }}
                          style={{
                            color: "#fff",
                            backgroundColor: "#4BBC06",
                            fontSize: "18px",
                            width: "140px",
                            margin: "0.5rem",
                          }}
                        >
                          {"商談に参加"}
                        </Button>
                      </td>

                      <td style={tableTdStyle}>
                        <Button
                          color="primary"
                          size="large"
                          variant="contained"
                          sx={{ width: "140px", marginLeft: "10px" }}
                          style={{
                            color: "#fff",
                            backgroundColor: "#4BBC06",
                            fontSize: "18px",
                            width: "140px",
                            margin: "0.5rem",
                          }}
                        >
                          {"投稿する"}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <Button
          onClick={() => navigate("/buyer")}
          variant="outlined" sx={{ mr: 1 }} size="large"
          style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '2rem', }}
        >
          戻&emsp;る
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default BuyerMeetingsPage;
