/* eslint-disable*/
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme, Grid, Stack, FormLabel, TextField, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export function EntryCustomInput({id, name, label, labelWidth, required, placeHolder, onHandleBlur, onHandleChange, gideFlag}) {
    
    const { control, formState: { errors } } = useFormContext();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (name, value) => {
        onHandleChange(name);
    }
    const handleBlur = (name, value) => {
        onHandleBlur(name);
    }
    const fieldName = 'pmdActApprovalNo2'; 
    return (
        <>
        <Grid item sx={{width:labelWidth, textAlign:'left', mt:3}}>
            {
                required === 'on'
                ?
                <FormLabel sx={{ whiteSpace: 'nowrap', border:'1px solid red', color:'red', padding:'5px' }}>必須</FormLabel>
                :
                <FormLabel sx={{ whiteSpace: 'nowrap', border:'1px solid grey', color:'grey', padding:'5px' }}>任意</FormLabel>
            }
            <FormLabel sx={{ whiteSpace: 'nowrap', ml:1, fontWeight:'bold' }}>{label}</FormLabel>
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{mt: 2}}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={id}
                        variant='outlined'
                        size='small'
                        sx={{
                            "& .MuiInputBase-root": {
                            height: 35,
                            width:'300px'
                            },
                        }}
                        placeholder={placeHolder}
                        helperText={errors?.[name]?.message}
                        FormHelperTextProps={{ sx: { width: 250 } }}
                        error={errors?.[name]?.message ? true : false}
                    />
                )}
            />
            {
                gideFlag
                ?
                <>
                </>
                :
                <>
            <FormHelperText>サイト上・ガイドブックに掲載されます。</FormHelperText>
                </>
            }
        </Grid>
        </>
    )
}
  
EntryCustomInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    labelWidth: PropTypes.string,
    required: PropTypes.string,
    placeHolder: PropTypes.string,
    inputWidth: PropTypes.string,
    onHandleBlur: PropTypes.func,
    onHandleChange: PropTypes.func,
    gideFlag: PropTypes.bool,
};
export default EntryCustomInput;
/* eslint-disable*/