import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';

/* eslint-disable */

const ODD_OPACITY = 0.1;

export const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  backgroundColor: '#d3d3d3', // 背景色を設定
  // ヘッダー
  [`& .${gridClasses.columnHeaders}`]: {
    backgroundColor: alpha(theme.palette.fourthly.main, 0.9),
    '& .header_color_attention': {
      backgroundColor: theme.palette.fourthly.main,
    },
    color: alpha('#fff', 1.0),
    borderRight: '1px solid #fff',
  },
  // 交互色
  [`& .${gridClasses.row}:nth-child(odd)`]: {
    backgroundColor: '#fff', // 奇数行の背景色を設定
  },
  [`& .${gridClasses.row}:nth-child(even)`]: {
    backgroundColor: '#eaedf7', // 偶数行の背景色を設定
  },
  [`& .${gridClasses.row}:hover`]: {
    backgroundColor: '#fff0f5', // ホバー時の背景色
  },
  // 行選択
  [`& .${gridClasses.row}.selected`]: {
    backgroundColor: alpha(theme.palette.fifthly.main, 0.5),
  },
  [`& .${gridClasses.row}.Mui-selected`]: {
    backgroundColor: '#ffb6c1',
  },
  [`& .${gridClasses.row}.Mui-selected:hover`]: {
    backgroundColor: '#ffb6c1',
  },
  // 編集セル
  [`& .${gridClasses['cell--editable']}`]: {
    //backgroundColor: alpha(theme.palette.fifthly.main, 0.5),
    backgroundColor: '#fff3b8',
  },
  [`& .${gridClasses.cell}`]: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  // ... その他のスタイル
  [`& .${gridClasses.columnHeaderCheckbox} .MuiCheckbox-colorPrimary.Mui-checked`]: {
    color: '#fff',
  },
  [`& .${gridClasses.columnHeaderCheckbox} .MuiCheckbox-colorPrimary`]: {
    color: '#fff',
  }
}));

/* eslint-enable */
