
import { useState, useEffect } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme, Button, Box } from '@mui/material'
import { apiURL } from '../../api/ApiConfig';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useAuth } from '../../contexts/AuthContext'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import TitleBar from '../../components/uiParts/TitleBar'
import { useFetch } from '../../api/ApiRequests'
import { addDays, format } from 'date-fns'
import { certificationList, cityList, industryList, itemCategoryList } from '../../Store'

const SellerItemPreviewPage = ({ setFormData, formData }) => {

    const { authInfo } = useAuth()

    const navigate = useNavigate()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const sellerId = query.get('sellerId')

    const { id } = useParams();

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    const industoryCheckFields = (data) => {
        const checkFields = {};
        for (let i = 1; i <= 8; i++) {
            const key = `checkField${String(i).padStart(2, '0')}`;
            if (key in data) {
                checkFields[key] = data[key];
            }
        }
        return checkFields;
    };

    const certificationCheckFields = (data) => {
        const checkFields = {};
        for (let i = 9; i <= 20; i++) {
            const key = `checkField${String(i).padStart(2, '0')}`;
            if (key in data) {
                checkFields[key] = data[key];
            }
        }
        return checkFields;
    };


    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName

    //===============================================
    // フォーム
    //===============================================
    //-----------------------------------------------
    // フォーム - スキーマ定義
    //-----------------------------------------------
    const schema = yup.object().shape({
        name: yup.string().required('商品名を入力してください'),
        nameKana: yup.string().required('商品名（フリガナ）を入力してください'),
        itemCategory: yup.string().required('商品カテゴリを選択してください'),
        imageField01: yup.string().required('メイン画像を指定してください'),
    })
    //-----------------------------------------------
    // フォーム - 登録データのテンプレート
    //-----------------------------------------------
    const registerData = {
        seller: sellerId,
        entryDate: today,
        deleteFlag: 0,
        name: '',
        nameKana: '',
        itemCategory: '',
        textField01: '',
        textField02: '',
        textField03: '',
        imageField01: '',
        imageField02: '',
        imageField03: '',
        itemCategoryList: itemCategoryList
    }
    const [defaultValues] = useState(registerData)
    const useFormMethods = useForm({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { handleSubmit } = useFormMethods

    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - 商品詳細の取得
    //-----------------------------------------------
    const getData = {
        userId: userId,
        userName: userName
    }

    /*商品詳細取得用*/
    const { data, loading, fetchData } = useFetch(getData);

    /*商品一覧取得用*/
    const { data: itemsData, loading: itemsLoading, fetchData: itemsFetchData } = useFetch(getData);

    /*セラー情報の取得*/
    const { data: sellerData, loading: sellerLoading, fetchData: sellerFetchData } = useFetch(getData);

    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id])

    useEffect(() => {
        const requestURL = `/mst/item/${id}/`;
        fetchData(requestURL);
    }, [id]);

    useEffect(() => {
        const requestURL = `/mst/seller/item?seller_id=${authInfo.sellerId}`;
        itemsFetchData(requestURL);
    }, [id]);

    useEffect(() => {
        const requestURL = `/mst/seller/${authInfo.sellerId}/`;
        sellerFetchData(requestURL);
    }, [id]);

    //===============================================
    // eventHandler
    //===============================================

    //===============================================
    // CSS
    //===============================================
    const homeContainer = {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '50px', // フッターの高さ分だけ余白を追加
        backgroundColor: '#F0F0F1',
    }
    const onSubmit = data => {
        navigate('/itemConfirm?sellerId=' + sellerId);
    }

    const onClickExtra = (id) => {
        // console.log(`/seller/items/${id}/preview`)
        navigate(`/seller/items/${id}/preview`)
    }


    const formContainer = {
        display: 'flex',
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",
        padding: "0 10px",
        width: "100%",
        maxWidth: "800px",
    }

    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} backPage="/seller/items" />
            <div style={{ backgroundColor: '#F0F0F1', }}>
                <TitleBar title='商品ページ' />
            </div >
            <div style={homeContainer}>
                <FormProvider {...useFormMethods}>
                    <div className="form-container" style={formContainer} >
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start", marginTop: "30px" }}>
                                この画面はプレビュー画面です。 <br />
                                バイヤーから見た出展者の商品紹介、 出展者紹介の画面になります。
                            </div>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Button
                                    onClick={() => navigate("/seller/items")}
                                    variant="outlined" sx={{ mr: 1 }} size="large"
                                    style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                                >
                                    戻&emsp;る
                                </Button>
                            </Grid>
                            {!loading && data && (
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", }}>
                                    <div style={{
                                        width: "100%",
                                    }}>
                                        <h4
                                            style={{
                                                backgroundColor: "#4BBC06",
                                                fontWeight: "normal",
                                                color: "white",
                                                marginBottom: 0,
                                            }}
                                        >
                                            出展者商品
                                        </h4>
                                        <div style={{ backgroundColor: "white", padding: "8px 0" }}>
                                            {data.name}
                                        </div>
                                    </div>
                                    {/*  商品画像  */}
                                    {data.imageField01 &&
                                        <img style={{ width: "90%" }} src={`${apiURL}media/uploads/${data.imageField01}`} alt={data.name} />
                                    }
                                    {/*  商品のアピールポイント  */}
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <h4 style={{ backgroundColor: "#4BBC06", fontWeight: "normal", color: "white", margin: 0 }}>
                                            商品のアピールポイント
                                        </h4>
                                        <div style={{ color: "#0B7D50", backgroundColor: "white", textAlign: "start", padding: "5px 20px", fontSize: "18px" }}>
                                            {data.textField01}
                                        </div>
                                        <div style={{ backgroundColor: "white", textAlign: "start", padding: "0 10px 20px 20px" }}>
                                            {data.textField02}
                                        </div>
                                    </div>
                                    <Button variant='contained' sx={{ width: "90%", borderRadius: "99px", padding: "10px 0", margin: "10px 0", fontSize: '18px' }} >この出展者へオンライン商談を申し込む</Button>
                                    {/*  出展者紹介  */}
                                    {!sellerLoading && sellerData && (
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h4 style={{ backgroundColor: "#4BBC06", fontWeight: "normal", color: "white", margin: 0 }}>
                                                出展者紹介
                                            </h4>
                                            <div style={{ display: "flex", flexDirection: isSmallScreen ? "column-reverse" : "row", backgroundColor: "white", textAlign: "center", padding: "10px 20px", gap: "10px" }}>
                                                <div style={{ display: "flex", flexDirection: "column", flex: "6", maxWidth: "800px" }}>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "break-word", textAlign: 'left', color: "#007D50" }}>
                                                            出展者名:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.name}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            出展者名(フリガナ):
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.nameKana}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            メールアドレス:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.eMail}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            部署:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.busho}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            担当者:
                                                        </Grid>
                                                        <Grid item xs={4} sx={{ textAlign: 'left' }}>
                                                            {sellerData.tantosha}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            都道府県:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {cityList.find(i => i.code === sellerData.city)["label"]}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            所在地:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.address}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            業種:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left', whiteSpace: "break-spaces" }}>
                                                            {(() => {
                                                                const checkFields = industoryCheckFields(sellerData);
                                                                return industryList
                                                                    .filter(industry => {
                                                                        const key = Object.keys(industry)[0];
                                                                        return checkFields[key];
                                                                    })
                                                                    .map(industry => Object.values(industry)[0])
                                                                    .join(',\n');
                                                            })()}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            その他(自由記入):
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: 'left' }}>
                                                            {sellerData.textField01}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            取得認証:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left', whiteSpace: "break-spaces" }}>
                                                            {(() => {
                                                                const checkFields = certificationCheckFields(sellerData);
                                                                return certificationList
                                                                    .filter(industry => {
                                                                        const key = Object.keys(industry)[0];
                                                                        return checkFields[key];
                                                                    })
                                                                    .map(industry => Object.values(industry)[0])
                                                                    .join(',\n');
                                                            })()}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            その他(取得記述):
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: 'left' }}>
                                                            {sellerData.textField02}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                            認定・受賞歴など:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: 'left' }}>
                                                            {sellerData.textField03}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={4} sx={{ textAlign: 'left', color: "#007D50" }}>
                                                            ホームページ:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.textField05}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={4} sx={{ textAlign: 'left', color: "#007D50" }}>
                                                            X(Twitter):
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.textField06}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                        <Grid item xs={4} sx={{ textAlign: 'left', color: "#007D50" }}>
                                                            Instagram:
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                            {sellerData.textField07}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div style={{ flex: "2" }}>
                                                    <img width="100%" src={`${apiURL}media/uploads/${sellerData.imageField01}`} alt={sellerData.name} />
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                    <Button variant='contained' sx={{ width: "90%", borderRadius: "99px", padding: "10px 0", margin: "10px 0", fontSize: '18px' }} >この出展者へオンライン商談を申し込む</Button>
                                    {!itemsLoading && itemsData && (
                                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                            <h4 style={{ backgroundColor: "#4BBC06", fontWeight: "normal", color: "white", margin: 0 }}>
                                                その他の取扱商品
                                            </h4>
                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                {
                                                    itemsData["item"].filter(item => item.id !== parseInt(id))?.map((item, index) => (
                                                        <div style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", backgroundColor: "white", textAlign: "center", padding: "10px 20px", gap: "10px" }} key={`i-${index}`}>
                                                            <Box sx={{ width: "300px", height: "300px", margin: "0 auto", flex: 2 }}>
                                                                {item.imageField01 &&
                                                                    <Box sx={{ minWidth: "300px", maxWidth: "300px", minHeight: "300px", maxHeight: "300px", flex: 3, display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }} >
                                                                        <img width="100%" src={`${apiURL}media/uploads/${item.imageField01}`} alt={item.name} style={{ margin: "auto", objectFit: "cover", objectPosition: "center" }} />
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <div style={{ display: "flex", flexDirection: "column", flex: 6 }}>
                                                                <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                                    <Grid item xs={12} sx={{ textAlign: 'center', color: "#007D50", fontSize: "20px" }}>
                                                                        {item.name}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                                        商品カテゴリー:
                                                                    </Grid>
                                                                    <Grid item xs={isSmallScreen ? 4 : "auto"} sx={{ textAlign: 'left' }}>
                                                                        {itemCategoryList.find(i => i.code === item.itemCategory).label}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container direction={isSmallScreen ? "column" : "row"} borderBottom="2px dashed gray">
                                                                    <Grid item xs={isSmallScreen ? 1 : 4} sx={{ wordBreak: "keep-all", textAlign: 'left', color: "#007D50" }}>
                                                                        商品ポイント:
                                                                    </Grid>
                                                                    <Grid item xs={isSmallScreen ? 4 : 8} sx={{ textAlign: 'left' }}>
                                                                        {item.textField01}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container direction={isSmallScreen ? "column" : "row"}>
                                                                    <Grid item xs={12}>
                                                                        <Button variant='contained'
                                                                            onClick={() => onClickExtra(item.id)}
                                                                            sx={{
                                                                                backgroundColor: "#4BBC06",
                                                                                width: "200px",
                                                                                borderRadius: "99px",
                                                                                margin: "10px 0",
                                                                                fontSize: '18px'
                                                                            }} >商品の詳細</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>)}
                                </div>)}
                        </form>
                    </div>
                </FormProvider>
            </div>
            <Footer />
        </>
    )
}
export default SellerItemPreviewPage