
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation, useParams, } from 'react-router-dom'
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Grid, useMediaQuery, useTheme, Button, FormLabel, Stack, TextField, FormHelperText, SliderTrack, Typography } from '@mui/material'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useAuth } from '../../contexts/AuthContext'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import TitleBar from '../../components/uiParts/TitleBar'
import EntryCustomInput from '../../components/entryForm/EntryCustomInput'
import EntryCustomField from '../../components/entryForm/EntryCustomField'
import EntryCustomSelect from '../../components/entryForm/EntryCustomSelect'
import { usePost } from '../../api/ApiRequests'
import { addDays, format } from 'date-fns'
import { itemCategoryList } from '../../Store'
import { apiURL } from '../../api/ApiConfig';

const SellerNewItemPage = ({ setFormData, formData }) => {

    const { authInfo, setAuthInfo } = useAuth()

    const navigate = useNavigate()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const search = useLocation().search

    const [isConfirm, setIsConfirm] = useState(false)

    const item = itemCategoryList.find(item => item.code == formData.itemCategory)
    const itemCategoryName = item?.label

    //===============================================
    // initialize
    //===============================================
    const today = format(addDays(new Date(), 0), 'yyyy-MM-dd')
    const systemName = process.env.REACT_APP_SYSTEM_NAME

    //-----------------------------------------------
    // initialize - セッション情報を取得
    //-----------------------------------------------
    const userId = authInfo.userId
    const userName = authInfo.userName
    const authority = authInfo.authority

    const effectRan = useRef(false)

    //===============================================
    // フォーム
    //===============================================
    //-----------------------------------------------
    // フォーム - スキーマ定義
    //-----------------------------------------------
    const schema = yup.object().shape({
        name: yup.string().required('商品名を入力してください'),
        nameKana: yup.string().required('商品名（フリガナ）を入力してください'),
        itemCategory: yup.string().required('商品カテゴリを選択してください'),
        imageField01: yup.string().required('メイン画像を指定してください'),
    })
    //-----------------------------------------------
    // フォーム - 登録データのテンプレート
    //-----------------------------------------------
    const registerData = {
        seller: 0,
        entryDate: today,
        deleteFlag: 0,
        name: '',
        nameKana: '',
        itemCategory: '',
        textField01: '',
        textField02: '',
        textField03: '',
        imageField01: '',
        imageField02: '',
        imageField03: '',
        itemCategoryList: itemCategoryList
    }
    const [defaultValues, setDefaultValues] = useState(registerData)
    const useFormMethods = useForm({
        defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { control, handleSubmit, formState: { errors }, setValue } = useFormMethods



    //===============================================
    // API
    //===============================================
    //-----------------------------------------------
    // API - POSTメソッドのテンプレート
    //-----------------------------------------------
    const { postData } = usePost({
        userId: userId,
        userName: userName
    })
    //===============================================
    // useEffect
    //===============================================
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    //===============================================
    // eventHandler
    //===============================================
    //-----------------------------------------------
    // eventHandler - 画像ファイルのアップロード
    //-----------------------------------------------
    const handleSubmit_upload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const onSuccess = (data) => {
                const url = data.file;
                const extractedPart = url.split('media/uploads/')[1];
                // 必要に応じて状態を更新
                setValue('imageField01', extractedPart); // 例: React Hook Formなどを使用している場合
            }
            await postData('/mst/uploads/', formData, onSuccess);
        } catch (error) {
            console.error(error);
        }
    }
    //-----------------------------------------------
    // eventHandler - 登録処理の実行
    //-----------------------------------------------
    const handleConfirm = async () => {
        try {
            const onSuccess = (data) => {
                //send_mail('/itemForm?sellerId='+sellerId)
                navigate('/seller/items/new/done?sellerId=' + authInfo.sellerId)
            }
            await postData('/mst/item/', formData, onSuccess)
        } catch (error) {
            console.error(error);
        }
    }
    //===============================================
    // CSS
    //===============================================
    //-----------------------------------------------
    // CSS - New
    //-----------------------------------------------
    const homeContainer = {
        // width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '360px', // フッターの高さ分だけ余白を追加
        backgroundColor: '#F0F0F1',
        padding: "0 10px"
    }
    const homeInnerContainer = {
        width: "100%",
        maxWidth: "800px",
        marginTop: "10px",
    }
    const responsiveImage = {
        width: '380px',
    }
    const formContainer = {
        marginTop: "30px",
        borderRadius: "10px",
        padding: "0 20px",
        backgroundColor: "white",
        display: 'flex',
        flexDirection: "column",
    }
    //-----------------------------------------------
    // CSS - Confirm
    //-----------------------------------------------
    const homeContainerNew = {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F0F1',
    }
    const homeInnerContainerNew = {
        display: "flex",
        flexDirection: "column",
        padding: "5px 32px 30px 32px",
        width: "100%",
        maxWidth: "800px",
        marginTop: "30px",
        borderRadius: "10px",
        backgroundColor: "white",
    }
    const onSubmit = data => {
        setFormData({ ...data, seller: authInfo.sellerId });
        setIsConfirm(true)
        // navigate(`/seller/items/new/confirm?sellerId=${authInfo.sellerId}`);
    }
    const formContainerNew = {
        display: 'flex',
        alignSelf: "center",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: "center",
        padding: "0 10px"
    }
    //===============================================
    // JSXのレンダー
    //===============================================
    return (
        <>
            <Header title={systemName} loginInfo={isSmallScreen ? "off" : "on"} backButton={isSmallScreen ? "off" : "on"} />
            {
                isConfirm ? <>            <div style={{}}>
                    <TitleBar title="商品ページ" />
                </div>
                    <div style={homeContainer}>

                        <div style={homeInnerContainer}>
                            <div style={{ textAlign: "start", fontWeight: "bold", marginBottom: "10px" }}>商品ページ状態：承認待ち</div>
                            <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start" }}>
                                編集した内容を確認して、 「承認申請」 ボタンをクリックしてください。 <br />
                                事務局より承認されるとサイトに反映されます。
                            </div>

                            <div className="form-container" style={formContainer}>
                                <div style={{ fontSize: '1.5em', marginTop: '0.5em', fontWeight: 'bold' }}>商品情報</div>
                                {/* 以下繰り返し */}
                                <div style={{ fontSize: '1.5em', marginTop: '0.5em', fontWeight: 'bold', borderBottom: "1px solid" }}></div>
																<Stack spacing={2} sx={{my: 4}}>
																	<Stack direction={{xs: "column",sm: "row"}} alignItems='flex-start'>
																		<Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1,fontWeight: 'bold', fontSize: '1.2em' }}>商品名</Typography>
																		<Typography sx={{ mt: 1,whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word",textAlign: 'left', fontSize: '1.2em' }}>{formData.name}</Typography>
																	</Stack>
																	<Stack direction={{xs: "column",sm: "row"}} alignItems='flex-start'>
																		<Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1,fontWeight: 'bold', fontSize: '1.2em' }}>商品名（フリガナ）</Typography>
																		<Typography sx={{ mt: 1,whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word",textAlign: 'left', fontSize: '1.2em' }}>{formData.nameKana}</Typography>
																	</Stack>
																	<Stack direction={{xs: "column",sm: "row"}} alignItems='flex-start'>
																		<Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1,fontWeight: 'bold', fontSize: '1.2em' }}>商品カテゴリ</Typography>
																		<Typography sx={{ mt: 1,whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word",textAlign: 'left', fontSize: '1.2em' }}>{itemCategoryName}</Typography>
																	</Stack>
																	<Stack direction={{xs: "column",sm: "row"}} alignItems='flex-start'>
																		<Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1,fontWeight: 'bold', fontSize: '1.2em' }}>キャッチフレーズ</Typography>
																		<Typography sx={{ mt: 1,whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word",textAlign: 'left', fontSize: '1.2em' }}>{formData.textField01}</Typography>
																	</Stack>
																	<Stack direction={{xs: "column",sm: "row"}} alignItems='flex-start'>
																		<Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1,fontWeight: 'bold', fontSize: '1.2em' }}>商品説明</Typography>
																		<Typography sx={{ mt: 1,whiteSpace: 'normal', wordWrap: "break-word", wordBreak: "break-word",textAlign: 'left', fontSize: '1.2em' }}>{formData.textField02}</Typography>
																	</Stack>
																	<Stack direction={{xs: "column",sm: "row"}} alignItems='flex-start'>
																		<Typography sx={{ minWidth: '200px', textAlign: 'left', mt: 1,fontWeight: 'bold', fontSize: '1.2em' }}>商品画像</Typography>
																		<img src={`${apiURL}media/uploads/${formData.imageField01}`} alt="Example" style={responsiveImage} />
																	</Stack>
																</Stack>
                            </div>
                            <Grid item xs={12} sx={{ my: 3 }}>
                                <Button
                                    onClick={() => setIsConfirm(false)}
                                    variant="outlined" sx={{ mr: 1 }} size="large"
                                    style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                                >
                                    戻　る
                                </Button>
                                <Button
                                    onClick={() => handleConfirm()} variant="contained" sx={{ mr: 1 }} size="large"
                                    style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                >
                                    承認申請
                                </Button>
                            </Grid>
                        </div>
                    </div>
                </> :
                    <>
                        <div style={{ backgroundColor: '#F0F0F1', }}>
                            <TitleBar title='商品ページ' />
                        </div >
                        <div style={homeContainerNew}>
                            <FormProvider {...useFormMethods}>
                                <div className="form-container" style={formContainerNew} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div style={{ textAlign: "start", fontWeight: "bold", margin: "10px 0" }}>商品ページ状態：承認待ち</div>
                                        <div style={{ backgroundColor: "#D5EDC7", padding: "15px", borderRadius: "10px", textAlign: "start", maxWidth: "800px" }}>
                                            商品の編集をして、 「入力情報の確認をする」 をクリックしてください。
                                        </div>
                                        <div style={homeInnerContainerNew}>
                                            <div style={{ fontSize: '1.5em', margin: '0.5em', fontWeight: 'bold', borderBottom: '1px solid' }}>商品情報</div>
                                            <Grid
                                                container
                                                direction="column"
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center"
                                                required='on'
                                            >
                                                <Grid item xs={12}>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                        <EntryCustomInput
                                                            id='name' name='name'
                                                            label='商品名' labelWidth='250px' required='on' placeHolder="例：こしひかり"
                                                        />
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                        <EntryCustomInput
                                                            id='nameKana' name='nameKana'
                                                            label='商品名（フリガナ）' labelWidth='250px' required='on' placeHolder="例：コシヒカリ"
                                                        />
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                        <EntryCustomSelect
                                                            id='itemCategory' name='itemCategory'
                                                            label='商品カテゴリ' labelWidth='250px' required='on' placeHolder="選択してください"
                                                        />
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                        <EntryCustomField
                                                            id='textField01' name='textField01'
                                                            label='キャッチフレーズ' labelWidth='250px' required='off' placeHolder="100文字以内"
                                                        />
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2}>
                                                        <EntryCustomField
                                                            id='textField02' name='textField02'
                                                            label='商品説明' labelWidth='250px' required='off' placeHolder="100文字以内"
                                                        />
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} sx={{ mt: 1 }}>
                                                        <Grid item sx={{ width: '250px', textAlign: 'left', mt: 1 }}>
                                                            <FormLabel sx={{ whiteSpace: 'nowrap', border: '1px solid red', color: 'red', padding: '5px' }}>必須</FormLabel>
                                                            <FormLabel sx={{ whiteSpace: 'nowrap', ml: 1, fontWeight: 'bold' }}>商品画像</FormLabel>
                                                        </Grid>
                                                        <Grid item xs={isSmallScreen ? 12 : 'auto'} sx={{ mt: 2 }}>
                                                            <Controller
                                                                name='imageField01'
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id='imageField01'
                                                                        variant='outlined'
                                                                        size='small'
                                                                        sx={{
                                                                            "& .MuiInputBase-root": {
                                                                                height: 35,
                                                                                width: '300px'
                                                                            },
                                                                        }}
                                                                        placeholder='ファイル名'
                                                                        helperText={errors?.imageField01?.message}
                                                                        FormHelperTextProps={{ sx: { width: 200 } }}
                                                                        error={errors?.imageField01?.message ? true : false}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <FormHelperText>サイト上・ガイドブックに掲載されます。</FormHelperText>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction={isSmallScreen ? "column" : "row"} spacing={2} sx={{ mt: 0 }}>
                                                        <Grid item sx={{ width: '250px', textAlign: 'left', mt: 1 }}></Grid>
                                                        <Grid item xs={isSmallScreen ? 12 : 'auto'}>
                                                            <Stack>
                                                                <Stack direction='row' alignItems='flex-start'>
                                                                    <Grid sx={{ mr: 1 }}>
                                                                        <input type="file" accept="image/jpeg, image/png, image/gif" onChange={(e) => handleSubmit_upload(e)} />
                                                                    </Grid>
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Grid item xs={12} sx={{ my: 3 }}>
                                            <Button
                                                onClick={() => navigate(-1)}
                                                variant="outlined" sx={{ mr: 1 }} size="large"
                                                style={{ color: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem', }}
                                            >
                                                戻　る
                                            </Button>
                                            <Button
                                                type="submit" variant="contained" sx={{ mr: 1 }} size="large"
                                                style={{ color: '#fff', backgroundColor: '#4BBC06', fontSize: '20px', width: '300px', margin: '0.5rem' }}
                                            >
                                                入力情報の確認をする
                                            </Button>
                                        </Grid>
                                    </form>
                                </div>
                            </FormProvider>
                        </div></>
            }
            <Footer />
        </>
    )
}
export default SellerNewItemPage